import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { toast } from "react-toastify";
import RequestSentModal from "./RequestSentModal";
import RequestLogo from "../../../assets/images/20.png";
import { baseURL } from "../../../utils/const";
import { getToken } from "../../../api/api";
import { useSelector, useDispatch } from "react-redux";
import { updateCollaborators } from "../../../redux/store/slice/collaborationSlice";
import logger from "../../../config/logger";

export default function SendRequestMessgeModal({
  modelopen,
  setModalOpen,
  activeChannel,
}) {
  const [feedback, setFeedback] = useState("");
  const requestedCollaborators = useSelector(
    (state) => state?.collaborations?.requestedCollaborators
  );
  const dispatch = useDispatch();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const localStorageToken = getToken();

  useEffect(() => {
    logger.log("Request collaboration modal opened");
  }, []);

  const apiSavedCollaborators = useSelector(
    (state) => state?.collaborations?.apiSavedCollaborators
  );

  const handleCollabRequest = async () => {
    const config = {
      headers: {
        Authorization: `token ${localStorageToken}`,
      },
    };
    const start = Date.now();
    try {
      setFeedback("");
      setModalOpen(false);
      await axios.post(
        baseURL + "/collaborators/request/save",
        {
          prospect_channel_id: activeChannel.channel_id,
          prospect_email: "",
          prospect_channel_title: activeChannel.channel_title,
          prospect_channel_logo: activeChannel.image_url,
          prospect_location: activeChannel.location,
          user_remark: feedback,
        },
        config
      );
      const end = Date.now();
      const time = end - start;
      logger.success(`Collaboration requested successfully in ${time}ms`);
      toast.success("Sent Request", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setConfirmationModalOpen(true);
      const updatedCollaborators = [
        ...requestedCollaborators,
        activeChannel.channel_id,
      ];

      dispatch(updateCollaborators(updatedCollaborators));

      const requested = isRequested(activeChannel.channel_id);
    } catch (error) {
      const end = Date.now();
      const time = end - start;
      logger.error(`Failed to request collaboration after ${time}ms`, error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const isRequested = (channelId) => {
    // Check if apiSavedCollaborators is not empty
    if (apiSavedCollaborators && apiSavedCollaborators.length > 0) {
      // Find the channel with the specified channelId in apiSavedCollaborators
      const requestedChannel = apiSavedCollaborators.find(
        (channel) =>
          channel.channels === channelId && channel.request_status === true
      );

      // If requestedChannel is found, return true
      if (requestedChannel) {
        return true;
      }
    }

    return false;
  };

  const CallfeedbackApi = async () => {
    // if (feedback.length > 0) {
    //   await feedbackApi(feedback)
    //     .then((res) => {
    //       setFeedback("");
    //       setModalOpen(false);
    //       toast.success("Feedback Sent");
    //     })
    //     .catch((err) => {
    //       toast.error("Something went wrong please try again!");
    //     });
    // }
    handleCollabRequest();
  };

  return (
    <>
      <Transition.Root show={modelopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={() => setModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-30 overflow-y-auto ">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                {/* sm:max-w-lg */}
                <Dialog.Panel className="w-[95%] lg:w-[33%] p-2 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8  ">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-[100%]">
                    <div className="sm:flex sm:items-start w-[100%]">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-[100%]">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {/* <div className="w-[100%] justify-between items-start gap-6 flex">
                          <div className="text-black text-2xl font-semibold">
                            Tell us how we can improve this product
                          </div>
                          <div
                            className="w-6 h-6 relative cursor-pointer"
                            onClick={() => setModalOpen(false)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                            >
                              <path
                                d="M18 6.5L6 18.5"
                                stroke="#5C566B"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M6 6.5L18 18.5"
                                stroke="#5C566B"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div> */}
                        </Dialog.Title>
                        <div className=" p-2 main w-[100%] flex flex-col gap-2">
                          <img className="w-52 h-52" src={RequestLogo} alt="" />
                          <div className="w-[100%] flex-col justify-start items-start gap-2 flex">
                            <div className=" text-zinc-800 text-2xl font-bold">
                              Add a personalised note!
                            </div>
                            <div className=" text-gray-600 text-base font-normal">
                              Personalised Notes improves the response rate by a
                              lot.
                            </div>
                          </div>
                          {/* <div className="w-[100%]  p-4 rounded border border-neutral-900 border-opacity-20 justify-start items-start inline-flex"> */}
                          <div className="text-black text-base font-medium leading-snug mt-2 mb-2">
                            Description
                          </div>
                          <textarea
                            value={feedback}
                            rows={4}
                            //   placeholder="What do you want to see in the product next? Tell us your ideas."
                            className="text-gray-600 text-base font-normal leading-snug w-[100%] border p-5 focus:outline-none"
                            onChange={(e) => setFeedback(e.target.value)}
                          />

                          {/* <div className="grow shrink basis-0 h-5 rounded-sm" /> */}
                          {/* </div> */}
                          {/*  */}
                          <div className="w-[100%] flex items-center justify-start gap-5 mt-5">
                            <button
                              className="text-violet-600 rounded-3xl border border-violet-600 h-10   w-28 text-xs lg:text-base font-bold leading-normal "
                              onClick={() => setModalOpen(false)}
                            >
                              Cancel
                            </button>

                            <button
                              className={`w-40 lg:w-60  lg:px-6 lg:py-4 bg-violet-600 h-10 lg:h-auto rounded-full justify-center items-center gap-2.5 flex text-white font-semibold leading-normal cursor-pointer text-xs lg:text-base `}
                              onClick={() => CallfeedbackApi()}
                            >
                              Send Request
                            </button>
                          </div>
                          {/*  */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <RequestSentModal
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        channelTitle={activeChannel.channel_title}
        requestAlreadySent={false}
      />
    </>
  );
}
