import React, { useState } from "react";
import TextBox from "./TextBox";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { baseURL } from "../../utils/const";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";

const ForgotPasswordComponent = () => {
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required"),
  });

  const onSubmit = async (values) => {
    setLoader(true);
    try {
      const response = await axios.post(baseURL + "/password/reset/", {
        email: values?.email,
      });

      toast.success(response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        setLoader(false);
        navigate("/reset-password", { state: values?.email });
      }, 2000);
    } catch (error) {
      console.error(error);
      toast.error("Please try again..!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="flex items-center justify-center flex-col min-h-screen">
        <div className="w-[75vw] max-w-[440px] lg:w-[440px] text-zinc-800 text-[28px] font-bold">
          Forgot Password
        </div>
        <div className="w-[75vw] max-w-[440px] lg:w-[440px] text-gray-600 text-base font-normal">
          No worries, we will send you the instructions to reset password
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="">
            <TextBox
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              email
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500"
            />
            <button
              type="submit"
              disabled={loader}
              className="mt-[46px] w-[75vw] max-w-[440px] lg:w-[440px] h-[54px] px-[25px] py-4 bg-violet-600 rounded-[53px] justify-center items-center gap-2.5 inline-flex"
            >
              {loader ? (
                <TailSpin
                  height="20"
                  width="20"
                  color="white"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                <div className="text-white text-base font-semibold leading-snug">
                  Send Instructions
                </div>
              )}
            </button>
          </Form>
        </Formik>

        <Link to="/" className="text-blue-500">
          <div className="text-violet-600 text-xl mt-5 font-medium">Cancel</div>
        </Link>
      </div>
    </>
  );
};

export default ForgotPasswordComponent;
