import CircleUpperLeft from "../../assets/shapes/circle-upper-left.svg";
import CircleUpperRight from "../../assets/shapes/circle-upper-right.svg";
import CircleLowerLeft from "../../assets/shapes/circle-lower-left.svg";
import CircleLowerRight from "../../assets/shapes/circle-lower-right.svg";
import UpViewImage from "../../assets/images/upview.svg";
import Game1 from "./game-1/game-1";
import "./main.css";

const App = () => {
  return (
    <div className="app-main">
      <img
        src={CircleUpperLeft}
        alt="design"
        className="absolute top-0 left-0 img"
      />
      <img
        src={CircleUpperRight}
        alt="design"
        className="absolute top-0 right-0 img"
      />
      <img
        src={CircleLowerLeft}
        alt="design"
        className="absolute bottom-0 left-0 img"
      />
      <img
        src={CircleLowerRight}
        alt="design"
        className="absolute bottom-0 right-0 img"
      />

      <div className="w-full flex justify-center pt-[73px]">
        <img src={UpViewImage} alt="upview-logo" />
      </div>
      <Game1 />
    </div>
  );
};

export default App;
