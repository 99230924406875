import CollabSidebar from "./CollabSidebar";
import Main from "./Main";
import { Header } from "../common/header/Header";

const Collobrate = ({
  collaborationData,
  activeChannel,
  updateActiveChannel,
}) => {
  return (
    <>
      <Header title="Collaboration" />
      <div className="w-full flex flex-col xl:flex-row items-center xl:items-start justify-center  ">
        <CollabSidebar
          collaborationData={collaborationData}
          activeChannel={activeChannel}
          updateActiveChannel={updateActiveChannel}
        />
        <Main
          activeChannel={activeChannel}
          collaborationData={collaborationData}
        />
      </div>
    </>
  );
};

export default Collobrate;
