import { useDispatch, useSelector } from "react-redux";
import {
  addActiveChannel,
  setChannelLoading,
  addSavedCollabsData,
  setShowSaveCollabButton,
} from "../../../redux/store/slice/collaborationSlice";
import { getToken } from "../../../api/api";
import axios from "axios";
import { baseURL } from "../../../utils/const";

const ProfileCard = ({
  name,
  match,
  imageUrl,
  verified,
  isActive,
  channel,
  updateActiveChannel,
  similarity,
  isSavedCollab,
}) => {
  let matchText = "";
  let matchColor = "black";
  if (similarity <= 0.3) {
    matchText = "Low";
    matchColor = "text-amber-600";
  } else if (similarity >= 0.3 && similarity <= 0.7) {
    matchText = "Medium";
    matchColor = "text-yellow-500";
  } else if (similarity >= 0.7) {
    matchText = "High";
    matchColor = "text-green-700";
  }

  const dispatch = useDispatch();
  const channelLoading = useSelector(
    (state) => state?.collaborations?.channelLoading
  );
  const savedCollabsData = useSelector(
    (state) => state.collaborations.savedCollabsData
  );

  const handleSelectSearchResult = async (result) => {
    try {
      const channelId = result?.channel_id;
      dispatch(setChannelLoading(true));
      dispatch(addActiveChannel(""));

      const activeChannelData = savedCollabsData?.find(
        (collaborator) => collaborator?.channel_id === channelId
      );
      if (activeChannelData) {
        dispatch(setChannelLoading(false));
        updateActiveChannel(activeChannelData);
        return;
      }

      const sessionToken = getToken();
      if (!sessionToken) {
        console.error("Access token not found.");
        return;
      }

      const headers = {
        Authorization: `token ${sessionToken}`,
      };

      const aUrl = `${baseURL}/collaborators/manualcollab?channel_id=${channelId}`;
      // TODO /collaborators/manualcollab?channel_id=${channelId}
      const response = await axios.get(aUrl, {
        headers: headers,
      });
      if (response?.data?.data.length > 0) {
        dispatch(addSavedCollabsData(response?.data?.data[0]));
        dispatch(addActiveChannel(response?.data?.data[0]));
      } else {
        updateActiveChannel({ channel_id: channelId, error: "No data found" });
      }

      dispatch(setChannelLoading(false));
    } catch (error) {
      console.error("Error fetching active channel:", error);
    }
  };

  return (
    <div
      className={`p-4 rounded-xl w-full flex flex-row gap-4 items-center justify-start self-stretch shrink-0 relative cursor-pointer ${
        isActive ? "bg-[#E9E4F6]" : ""
      } `}
      onClick={() => {
        if (!channelLoading) {
          if (isSavedCollab) {
            handleSelectSearchResult(channel);
            dispatch(setShowSaveCollabButton(false));
          } else {
            updateActiveChannel(channel);
          }
        }
      }}
    >
      <div className="relative">
        <img
          className="rounded-[50%] min-w-[52px] h-[52px]"
          src={imageUrl}
          alt="Profile"
        />
        {verified && (
          <div className="absolute bottom-0 right-0 w-5 h-5 text-green-500 bg-transparent rounded-full flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="m8.6 22.5l-1.9-3.2l-3.6-.8l.35-3.7L1 12l2.45-2.8l-.35-3.7l3.6-.8l1.9-3.2L12 2.95l3.4-1.45l1.9 3.2l3.6.8l-.35 3.7L23 12l-2.45 2.8l.35 3.7l-3.6.8l-1.9 3.2l-3.4-1.45l-3.4 1.45Zm2.35-6.95L16.6 9.9l-1.4-1.45l-4.25 4.25l-2.15-2.1L7.4 12l3.55 3.55Z"
              />
            </svg>
          </div>
        )}
      </div>

      <div className="flex flex-col gap-1.5 items-start justify-start shrink-0 relative">
        <h1
          className={`text-text-2 text-slate-600 font-semibold text-left relative  lg:w-[200px] break-words  w-[170px] ${
            isActive ? "text-slate-800" : ""
          } `}
        >
          {name}
        </h1>
        <p className="text-sm text-gray-600">
          <span className={`${matchColor}`}>{matchText}</span> Similarity
        </p>
      </div>
    </div>
  );
};

export default ProfileCard;
