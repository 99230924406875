import React, { useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import postHogEvents from "../../constants/events";

const RedirectComponent = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const email = queryParameters.get("email");
  const token = queryParameters.get("token");
  const state = queryParameters.get("state");
  const onboarding_status = queryParameters.get("onboarding_status");
  const title = queryParameters.get("title");
  const navigate = useNavigate();
  const posthog = usePostHog();

  const queryParamsData = {
    email: queryParameters.get("email"),
    token: queryParameters.get("token"),
    collaboration_status: queryParameters.get("collaboration_status"),
    authorization_status: queryParameters.get("state"),
    first_name: queryParameters.get("firstName"),
    last_name: queryParameters.get("lastName"),
    onboarding_status: queryParameters.get("onboarding_status"),
  };

  // initialized();

  useEffect(() => {
    if (title) {
      const youtubeData = {
        title: queryParameters.get("title"),
        channel_id: queryParameters.get("cid"),
        subcribers: queryParameters.get("subcribers"),
        views: queryParameters.get("views"),
        image_url: queryParameters.get("logo"),
      };
      localStorage.setItem("youtube-data", JSON.stringify(youtubeData));

      const data = {
        user: queryParameters.get("email"),
        channelName: `${queryParameters.get("cid")}`,
        channelId: `${queryParameters.get("cid")}`,
      };
      posthog.identify(queryParameters.get("email"));
      posthog.capture(postHogEvents.signUp, data);
    }

    if (token && email) {
      localStorage.setItem("user-data", JSON.stringify(queryParamsData));
      localStorage.setItem("token", token);
      localStorage.setItem("verification", state);
      localStorage.setItem("email", email);
      if (state === "false") {
        navigate("/account-registered", { state: "true" });
      } else {
        if (onboarding_status === "true") {
          navigate("/home", { state: true });
        } else {
          navigate("/onboarding");
        }
      }
    } else {
      if (!email) {
        const localStorageToken = localStorage.getItem("token");
        if (localStorageToken) {
          navigate("/onboarding");
        } else {
          navigate("/");
        }
      }
    }
  });

  return (
    <>
      <div className="flex items-center justify-center h-screen flex-col">
        <TailSpin
          height="40"
          width="40"
          color="purple"
          ariaLabel="tail-spin-loading"
          radius="1"
          // wrapperStyle={{}}
          // wrapperClass=""
          visible={true}
        />
        <div className="mt-5">
          {email}
          {state}
          {token}Redirect to YouTube connection page. Please wait for some time
        </div>
      </div>
    </>
  );
};

export default RedirectComponent;
