import { Link } from "react-router-dom";
import { ReactComponent as Lines } from "../../assets/images/Lines.svg";
import { usePostHog } from "posthog-js/react";
import { useNavigate } from "react-router-dom";
import postHogEvents from "../../constants/events";
import logger from "../../config/logger";

const GeneratedContent = () => {
  const posthog = usePostHog();
  const userData = JSON.parse(localStorage.getItem("user-data"));
  const navigate = useNavigate();

  const logEvent = () => {
    posthog.identify(userData?.email);
    posthog.capture(postHogEvents.aiContentGenerationButtonClicked);
  };
  const handleDivClick = () => {
    logger.log("Explore ideas button clicked");
    navigate("/suggestions/explore"); // Navigate to the 'destination' route
  };

  return (
    <div
      className="bg-violet-600 sm:w-[48%] h-[260px] pl-5 pt-8 rounded-2xl min-w-[300px] mb-2 xl:h-[200px] 2xl:h-[220px] flex relative cursor-pointer"
      onClick={() => {
        handleDivClick();
        logEvent();
      }}
    >
      <div className="w-[100%] flex flex-col justify-around">
        <h1 className="w-[100%] text-white text-[22px] font-bold leading-normal mb-2">
          AI Generated Content Ideas for your Channel!!
        </h1>
        <p className="w-[100%] text-white text-opacity-70 text-xs font-normal leading-[18px] mb-2">
          Create customized AI-generated content recommendations tailored to
          your specific channel and audience!
        </p>
        <Link to={"/suggestions/explore"} onClick={() => logEvent()}>
          <div className="w-[141px] mt-0 mb-6 h-10 px-4 py-3 bg-white rounded-[20px] justify-center items-center gap-2 flex">
            <p className="text-violet-600 text-[13px] font-semibold leading-none">
              Explore Ideas
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M3.33398 8H12.6673"
                stroke="#7948F6"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 3.33301L12.6667 7.99967L8 12.6663"
                stroke="#7948F6"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </Link>
      </div>
      {/* <img alt="lines" src={lines} className="h-[80%] w-[30%] self-end" /> */}
      <div className="h-[80%] w-[30%] self-end">
        <Lines />
      </div>
    </div>
  );
};

export default GeneratedContent;
