import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import rewards1 from "../../assets/images/rewards1.png";
import rewards2 from "../../assets/images/rewards2.png";
import { usePostHog } from "posthog-js/react";
import postHogEvents from "../../constants/events";

export default function RewardsModal({
  modelopen,
  setModalOpen,
  setRaatingModal,
}) {
  const cancelButtonRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem("user-data"));
  const posthog = usePostHog();

  const RedirectToDetailedFeedBack = () => {
    posthog.identify(userData?.email);
    posthog.capture(`DETAILED_FEEDBACK_${postHogEvents.buttonClicked}`);

    const url = "https://tally.so/r/nP1vxP";
    window.open(url, "_blank");
  };

  const RatingModal = () => {
    setModalOpen(false);
    setTimeout(() => {
      setRaatingModal(true);
    }, 1500);
  };

  return (
    <>
      <Transition.Root show={modelopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={() => setModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-30 overflow-y-auto ">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-[100%] lg:w-[40%] p-2 relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 pb-8 ">
                  <div className="bg-white pb-4 pt-5 sm:p-6 sm:pb-4 w-[100%]">
                    <div className="sm:flex sm:items-start w-[100%]">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-[100%]">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        ></Dialog.Title>
                        <div className=" main w-[100%] flex flex-col gap-4">
                          <div className="w-[100%] flex-col justify-center items-center gap-2 flex">
                            <h1 className=" text-zinc-800 text-2xl font-bold">
                              Help us improve and win amazing rewards
                            </h1>
                            <p className="text-center text-gray-600 text-sm font-normal">
                              Choose a feedback from below
                            </p>
                          </div>
                          <div className="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div
                              className="flex flex-col w-full lg:w-[45%] border-[1px] border-slate-100 rounded-lg cursor-pointer h-[400px]"
                              onClick={() => RatingModal()}
                            >
                              <div
                                className="h-48 w-full rounded-lg"
                                style={{
                                  backgroundImage: `url(${rewards1})`,
                                  backgroundSize: "cover",
                                }}
                              ></div>
                              <div className="w-full p-2 bg-white flex-col justify-start items-start gap-4 inline-flex">
                                <div className="self-stretch h-[43px] flex-col justify-start items-start gap-1 flex ">
                                  <h1 className="self-stretch text-zinc-800 text-xl font-bold">
                                    Short Feedback
                                  </h1>
                                  <p className="self-stretch text-gray-600 text-xs font-normal">
                                    Barely 2min
                                  </p>
                                </div>
                                <div className=" flex-col justify-start items-start gap-1.5 flex">
                                  <p className="self-stretch text-zinc-800 text-xs font-semibold">
                                    Rewards
                                  </p>
                                  <p className="self-stretch text-gray-600 text-xs font-normal leading-[18px]">
                                    Win a chance to get a $50 Amazon Gift cards.
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/*  */}
                            {/* <div
                              className="flex flex-col w-full lg:w-[45%]  border-[1px] border-slate-100 cursor-pointer h-[400px]"
                              onClick={() => RedirectToDetailedFeedBack()}
                            >
                              <div
                                className="flex flex-col justify-center items-start p-7 gap-4 w-full h-48 rounded-lg "
                                style={{
                                  backgroundImage: `url(${rewards2})`,
                                  backgroundSize: "cover",
                                }}
                              >
                                <div className="pl-2">
                                  <h2 className="text-white text-[30px] font-bold pl-2">
                                    TechCrunch
                                    <br />
                                    2023
                                  </h2>
                                  <div className="pl-2 mt-2">
                                    <p className="w-[100px] h-[27px] px-4 py-1.5 bg-white rounded-[999px] text-zinc-800 text-xs font-bold flex justify-center">
                                      Free Entry
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="w-full p-2 bg-white flex-col justify-start items-start gap-4 inline-flex ">
                                <div className="self-stretch h-[43px] flex-col justify-start items-start gap-1 flex">
                                  <h1 className="self-stretch text-zinc-800 text-xl font-bold whitespace-nowrap">
                                    Detailed Feedback
                                  </h1>
                                  <p className="self-stretch text-gray-600 text-xs font-normal">
                                    5min
                                  </p>
                                </div>
                                <div className=" h-[75px] flex-col justify-start items-start gap-1.5 flex">
                                  <p className="self-stretch text-zinc-800 text-xs font-semibold">
                                    Rewards
                                  </p>
                                  <p className=" text-gray-600 text-xs font-normal leading-[18px]">
                                    Apart from $50 gift card the best feedback
                                    would get an all-expense-paid trip to
                                    TechCrunch Event in SF, meeting 200+
                                    creators at Upview's official launch on
                                    September 18th
                                  </p>
                                </div>
                              </div>
                            </div> */}
                            {/*  */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
