import React, { useState } from "react";
import ArrowRight from "../../assets/images/arrow-right.png";
import ArrowLeft from "../../assets/images/arrow-left.png";
import Seo from "../../assets/images/seo.png";
import Thumbnail from "../../assets/images/thumbnail.png";
import Collaboration from "../../assets/images/collaboration.png";
import ContentIdea from "../../assets/images/content-idea.png";
import Education from "../../assets/images/education.png";
import Others from "../../assets/images/others.png";
import Scripting from "../../assets/images/scripting.png";
import VideoEditing from "../../assets/images/video-edit.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addOnBoardInterestedData,
  addOutSourceData,
  onBoardState,
} from "../../redux/store/slice/Onboarding";
import { submitonBoarding_data } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import postHogEvents from "../../constants/events";

const OutSource = () => {
  const posthog = usePostHog();
  const {
    onboard_hear_about_data,
    onboard_hear_about_us,
    onboard_referral_data,
    onboard_interested_data,
    onboard_interested_others_data,
    onboard_collab_status,
    onboard_out_source_data,
  } = useSelector((state) => state.Onboarding);
  const [seo, setSeo] = useState(
    onboard_out_source_data.includes(0) ? true : false
  );
  const [thumbnail, setThumbaail] = useState(
    onboard_out_source_data.includes(1) ? true : false
  );
  const [scriptIdea, setScriptIdea] = useState(
    onboard_out_source_data.includes(2) ? true : false
  );
  const [videoEditing, setVideoEditing] = useState(
    onboard_out_source_data.includes(3) ? true : false
  );
  const [education, setEducation] = useState(
    onboard_out_source_data.includes(4) ? true : false
  );
  const [other, setOthers] = useState(false);

  const userData = JSON.parse(localStorage.getItem("user-data"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // initialized();

  const NextStep = async () => {
    if (seo || thumbnail || scriptIdea || videoEditing || education || other) {
      await submitonBoarding_data(
        onboard_hear_about_us,
        onboard_referral_data,
        onboard_interested_data,
        onboard_interested_others_data,
        onboard_collab_status,
        onboard_out_source_data
      )
        .then((res) => {
          navigate("/home");

          posthog.identify(userData?.email);
          posthog.capture(`${postHogEvents.onboarding}_SUCCESS`);
        })

        .catch((err) => {
          posthog.identify(userData?.email);
          posthog.capture(`${postHogEvents.onboarding}_FAILED`, { err });
          navigate("/home");
        });
    }
  };

  return (
    <div className="w-[100%]  md:w-[80%] lg:w-[60%] xl:w-[50%] flex justify-center">
      <div className="w-[50%]  max-sm:w-[100%]  md:w-[100%] lg:w-[100%] xl:w-[100%] max-sm:p-5  p-10 bg-white flex-col justify-center items-center gap-16 flex ">
        <div className="w-56 justify-center items-center flex">
          <div className="w-2 h-2 bg-green-600 rounded-full border" />
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="w-2 h-2 bg-green-600 rounded-full" />
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="w-2 h-2 bg-green-600 rounded-full" />
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="w-2 h-2 bg-green-600 rounded-full" />
        </div>
        <div className="w-[100%] md:w-[100%] lg:w-[100%] xl:w-[100%] flex-col justify-center items-center gap-14 flex">
          <div className=" flex-col justify-start items-center gap-8 flex">
            <div className="w-[100%] max-sm:w-[100%] max-sm:text-sm text-center text-zinc-800 text-3xl font-bold leading-10">
              What do you usually outsource in your content creation process?
            </div>
            <div className="self-stretch text-center text-gray-600 text-sm font-normal">
              You can Choose Multiple
            </div>
          </div>
          <div className="w-[100%]  flex-col justify-between items-center gap-14 flex">
            <div className="w-[80%] max-sm:w-[100%]  max-lg:w-[100%]  max-md:w-[100%] flex-wrap justify-start items-start gap-4 flex">
              <div className="w-[100%] flex gap-4 flex-wrap justify-center">
                <div
                  className={`w-40 h-52 p-4 max-sm:w-[45%]  max-sm:h-[auto] max-md:w-[40%]  ${
                    videoEditing ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOutSourceData(0));
                    setVideoEditing(!videoEditing);
                  }}
                >
                  <img className="w-28 h-28" src={VideoEditing} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Video Editing
                  </div>
                </div>
                <div
                  className={`w-40 h-52 p-4 max-sm:w-[45%]  max-sm:h-[auto] ${
                    thumbnail ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOutSourceData(1));
                    setThumbaail(!thumbnail);
                  }}
                >
                  <img className="w-28 h-28" src={Thumbnail} />
                  <div className="text-center text-zinc-800 text-base font-semibold break-words">
                    Thumbnails
                  </div>
                </div>
                <div
                  className={`w-40 h-52 p-4 max-sm:w-[45%]  max-sm:h-[auto] ${
                    scriptIdea ? `bg-[aliceblue]` : `bg-white`
                  }  rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOutSourceData(2));
                    setScriptIdea(!scriptIdea);
                  }}
                >
                  <img className="w-28 h-28" src={Scripting} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Scripting Ideas
                  </div>
                </div>
                {/*  */}
                <div
                  className={`w-40 h-52 p-4 max-sm:w-[45%]  max-sm:h-[auto] ${
                    education ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOutSourceData(3));
                    setEducation(!education);
                  }}
                >
                  <img className="w-28 h-28" src={Education} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Education
                  </div>
                </div>

                {/*  */}
                <div
                  className={`w-40 h-52 p-4 max-sm:w-[45%]  max-sm:h-[auto] ${
                    seo ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOutSourceData(4));
                    setSeo(!seo);
                  }}
                >
                  <img className="w-28 h-28" src={Seo} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Seo
                  </div>
                </div>
                {/*  */}

                {/*  */}
                <div
                  className={`w-40 h-52 p-4 max-sm:w-[45%]  max-sm:h-[auto] ${
                    other ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOutSourceData(5));
                    dispatch(onBoardState("out_source_other"));
                    setOthers(!other);
                  }}
                >
                  <img className="w-28 h-28" src={Others} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Others
                  </div>
                </div>
                {/*  */}
              </div>

              {/* <div className="w-[100%] flex gap-4">
                <div
                  className={`w-44 h-52 p-4 ${
                    contentIdea ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOnBoardInterestedData("content-idea"));
                    setContentIdea(!contentIdea);
                  }}
                >
                  <img className="w-28 h-28" src={ContentIdea} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Content Ideas
                  </div>
                </div>
                <div
                  className={`w-44 h-52 p-4 ${
                    education ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOnBoardInterestedData("education"));
                    setEducation(!education);
                  }}
                >
                  <img className="w-28 h-28" src={Education} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Education
                  </div>
                </div>
                <div
                  className={`w-44 h-52 p-4 ${
                    other ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(onBoardState("interested_others"));
                    setOthers(!other);
                  }}
                >
                  <img className="w-28 h-28" src={Others} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Others
                  </div>
                </div>
              </div> */}
            </div>
            <div className="w-[80%] max-sm:w-[100%] max-sm:justify-between justify-between items-start gap-4 flex">
              <div
                className="px-8 py-3.5 bg-black bg-opacity-10 max-sm:px-4 max-sm:py-2 rounded-3xl justify-center items-center gap-2 flex cursor-pointer"
                onClick={() => dispatch(onBoardState("collab"))}
              >
                <div className="w-5 h-5 relative">
                  <img src={ArrowRight} />
                </div>
                <div className="text-zinc-800 text-base font-semibold leading-normal">
                  Back
                </div>
              </div>
              <div
                className={`px-8 py-3.5 bg-violet-600 max-sm:px-4 max-sm:py-2 rounded-3xl justify-center items-center gap-2 flex  ${
                  seo ||
                  education ||
                  videoEditing ||
                  scriptIdea ||
                  thumbnail ||
                  other
                    ? `cursor-pointer`
                    : `cursor-not-allowed`
                }`}
                onClick={() => NextStep()}
              >
                <div className="text-white text-base font-semibold leading-normal">
                  Next
                </div>
                <div className="w-5 h-5 relative">
                  <img src={ArrowLeft} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutSource;
