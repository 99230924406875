import React, { useState, createContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SavedIdeas from "../../contents/SavedIdeas";
import SearchResult from "./SearchResult";
import SearchKeyword from "./SearchKeyword";
import { Header } from "../../common/header/Header";
import logger from "../../../config/logger";

export const SearchedDataContext = createContext();

const Generate = () => {
  const [savedIdea, setSavedIdea] = useState(false);
  const location = useLocation();
  const { keyword, genData } = location.state;
  const [searchKeyword, setSearchKeyword] = useState(keyword);
  const [generatedData, setGeneratedData] = useState(genData);
  const [isLoading, setLoading] = useState(false);
  const [remainingCount, setRemainingCount] = useState(undefined);

  useEffect(() => {
    logger.log('Landed on /suggestions/generate page');
    const func = (e) => {
      setRemainingCount(e.detail.val);
    };

    document.addEventListener("set-remaining-count", func);
    return () => document.removeEventListener("set-remaining-count", func);
  }, []);

  return (
    <SearchedDataContext.Provider
      value={{
        searchKeyword,
        setSearchKeyword,
        generatedData,
        setGeneratedData,
        isLoading,
        setLoading,
      }}
    >
      <div className="w-[100%] relative">
        <Header
          title={"Suggestions  > Generate from Keywords"}
          remainingCount={remainingCount}
        />
        <SearchKeyword
          setSavedIdea={setSavedIdea}
          keyword={keyword}
          setRemainingCount={setRemainingCount}
        />
        <SearchResult keyword={keyword} remainingCount={remainingCount} />
        {savedIdea && (
          <div className="absolute top-0 right-0 w-[25%] max-sm:w-[100%] z-10">
            <SavedIdeas setSavedIdea={setSavedIdea} />
          </div>
        )}
      </div>
    </SearchedDataContext.Provider>
  );
};

export default Generate;
