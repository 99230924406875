import Videos from "./Videos";
import Tooltip from "../../common/Tooltip";
import PieChartComponent from "./PieChart";

const MainContent = (props) => {
  const {
    mostPopularRelatedVideos,
    mostRecentRelatedVideo,
    frequency_of_posting,
    time_of_posting,
    percentage_top,
    reach_percent,
    no_of_live,
    no_of_shorts,
    no_of_videos,
    average_length_live,
    average_length_shorts,
    average_length_videos,
    categories_they_speak_in,
  } = props;

  return (
    <div className="bg-[#F5F6FA] max-w-full p-2 lg:p-4 md:flex md:min-h-screen md:flex-row md:flex-wrap gap-3 md:gap-5">
      <div className="flex flex-col gap-3 md:gap-5 md:w-[48%]">
        <Videos
          mostPopularRelatedVideos={mostPopularRelatedVideos}
          mostRecentRelatedVideo={mostRecentRelatedVideo}
        />

        <div className="max-w-full w-full px-6 py-6 bg-white rounded-xl flex flex-col gap-8">
          <div className="flex justify-between items-center">
            <div className="flex flex-row gap-2 items-center w-full">
              <p className="text-gray-600 text-base font-semibold">Frequency</p>
              <Tooltip
                id={"frequency"}
                content="This is the amount of upload this channel posts per week."
              />
            </div>
            <span className="text-gray-600 text-xs font-normal w-14">All Time</span>
          </div>
          <h2 className="text-violet-600 flex items-center gap-3 text-lg md:text-2xl font-bold leading-7">
            {frequency_of_posting === 0
              ? "<1 Video"
              : `${frequency_of_posting} Videos`}

            <span className="text-gray-600 text-lg md:text-2xl font-normal">
              per week
            </span>
          </h2>
          <p className="text-gray-600 text-xs font-normal leading-none">
            {frequency_of_posting === 0
              ? "This channel uploads <1 Video per week on Average"
              : `  This channel uploads ${frequency_of_posting} Videos per week on Average$`}
          </p>
        </div>
        <div className="max-w-full w-full px-6 py-6 bg-white rounded-xl flex flex-col gap-8">
          <div className="flex justify-between items-center">
            <div className="flex flex-row gap-2 items-center w-full">
              <p className="text-gray-600 text-base font-semibold">
                Time of Posting
              </p>
              <Tooltip
                id={"timeposting"}
                content="This represent the typical posting times for this YouTube channel. Posting around the same times is advised to enhance your likelihood of being suggested by this channel."
              />
            </div>
            <span className="text-gray-600 text-xs font-normal w-24">
              Last 28 Days
            </span>
          </div>
          <h1 className="text-violet-600 text-lg md:text-2xl font-bold leading-7">
            {time_of_posting === "" ? "N/A" : time_of_posting?.toUpperCase()}
          </h1>
          <p className="text-gray-600 text-xs font-normal leading-none">
            {percentage_top === 0
              ? "0 Videos posted in this time range"
              : `${percentage_top}% of Videos are posted in this time range `}
          </p>
        </div>
      </div>
      <div className="flex flex-col md:w-[48%] gap-3 md:gap-4">
        <div className="w-full py-4  p-3 lg:p-6 bg-white rounded-xl flex-col justify-start items-start gap-7 inline-flex">
          <div className="flex flex-row gap-2 items-center w-full">
            <h1 className="text-gray-500 text-base font-semibold">Reach</h1>
            <Tooltip
              id="reach"
              content="This percentage is a rough calculation derived from the usual number of subscribers and views this channel usually gets."
            />
          </div>

          <h1 className="flex items-center text-violet-600 text-2xl font-bold leading-7">
            {Math.round(reach_percent) > 10000
              ? `>${Math.round(reach_percent / 100)}x`
              : `${Math.round(reach_percent)}%`}
            <span className="ml-2 text-gray-600 text-2xl font-normal leading-7">
              more audience
            </span>
          </h1>

          <h2 className="lg:w-[90%] text-gray-600 font-normal text-sm leading-snug">
            You could reach{" "}
            {Math.round(reach_percent) > 10000
              ? `>${Math.round(reach_percent / 100)}x `
              : `${Math.round(reach_percent)}% `}
            more audience by collaborating with this channel!!
          </h2>
        </div>

        <div className="w-full md:p-4 rounded-xl bg-white ">
          <div className="flex items-center   justify-between">
            <div className="flex flex-row gap-2 items-center w-full">
              <h1 className="text-gray-500 text-base font-semibold">
                Content Type Overview
              </h1>
              <Tooltip
                id="chart"
                content="This chart determines the type of content frequently posted by this channel."
              />
            </div>

            <p className="text-gray-500 text-xs font-normal w-24">Last 90 Days</p>
          </div>
          <PieChartComponent
            no_of_live={no_of_live}
            no_of_shorts={no_of_shorts}
            no_of_videos={no_of_videos}
            average_length_shorts={average_length_shorts}
            average_length_live={average_length_live}
            average_length_videos={average_length_videos}
          />
        </div>
        <div className="w-full p-6 bg-white rounded-xl flex-col justify-start items-start gap-8 inline-flex">
          <div className="flex flex-row gap-2 items-center w-full">
            <h1 className="text-gray-500 text-base font-semibold">
              Categories
            </h1>
            <Tooltip
              id={"categories"}
              content="These are the primary categories of this channel"
            ></Tooltip>
          </div>

          <div className="self-stretch justify-start items-start gap-2 flex flex-wrap">
            {categories_they_speak_in &&
              categories_they_speak_in.map((item, index) => (
                <div
                  key={index}
                  className="px-2.5 py-1 bg-gray-100 rounded justify-start items-start gap-2.5 flex"
                >
                  <div className="text-neutral-800 text-xs font-medium ">
                    {item}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
