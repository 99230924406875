import React, { useState } from "react";

import { BsThreeDotsVertical } from "react-icons/bs";
import Trash from "../../assets/images/trash.png";

const Popover = ({ onDelete, id }) => {
  const [isHovered, setIsHovered] = useState(false);

  const onClickHover = () => {
    setIsHovered(!isHovered);
  };

  const onClickDelete = () => {
    onDelete(id);
    setIsHovered(false);
  };

  return (
    <div
      onClick={onClickHover}
      style={{
        transition: "background-color 0.3s",
        cursor: "pointer",
      }}
    >
      <div className=" relative flex items-center text-sm ">
        <BsThreeDotsVertical />
        {isHovered && (
          <div
            onClick={onClickDelete}
            className="w-[161px] h-8 px-3 py-2 bg-white rounded-md shadow justify-start items-center gap-2 flex absolute top-5 right-0"
          >
            <img src={Trash} alt="trash" />
            <div className="text-zinc-800 text-xs font-medium">
              Remove from Saved
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Popover;
