import React from "react";
import ForgotPasswordComponent from "../components/auth/ForgotPasswordComponent";
import SideComponent from "../components/auth/SideComponent";
import Logo from "../components/auth/Logo";

const ForgotPassword = () => {
  return (
    <div>
      <div className="bg-violet-600">
        <div className="flex justify-between items-center">
          <div className="w-[100vw] xl:w-[50vw] bg-white relative h-screen">
            <div className="absolute top-4 left-4">
              <Logo />
            </div>
            <ForgotPasswordComponent />
          </div>
          <div className="w-[50vw] hidden xl:flex items-center justify-center h-screen">
            <SideComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
