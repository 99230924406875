import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    collab_status: "",
  },
  userData: {},
  token: "",

  reducers: {
    addCollabStatus(state, action) {
      state.collab_status = action.payload;
    },
    addUserDataTemporary(state, action) {
      state.userData = action.payload;
    },
  },
});
export const { addCollabStatus, addUserDataTemporary } = userSlice.actions;

export default userSlice.reducer;
