import { baseURL } from "../utils/const";
import axios from "axios";

export function getToken() {
  return localStorage.getItem("token");
}

export async function getSavedCollaboratorsData() {
  const url = baseURL + `/collaborators/manualcollab/save`;
  const config = {
    method: "get",
    url: url,
    headers: {
      Authorization: `token ${getToken()}`,
    },
  };
  return await axios(config);
}

export async function submitonBoarding_data(
  hear_about,
  referral,
  interest,
  join_reason,
  onboard_collab_status,
  onboard_out_source_data,
  onboard_out_source_other_data
) {
  const aUrl = baseURL + `/onboarding`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      platform: hear_about,
      referral: referral,
      interest: interest.join(),
      join_reason: join_reason,
      collab_status: onboard_collab_status,
      outsource_content: onboard_out_source_data.join(),
      outsource_content_other: onboard_out_source_other_data
        ? onboard_out_source_other_data
        : "",
    },
    headers: {
      Authorization: `token ${getToken()}`,
    },
  };

  return await axios(config);
}

export async function feedbackApi(feedback) {
  const aUrl = baseURL + `/feedback`;
  const config = {
    method: "post",
    url: aUrl,
    data: {
      description: feedback,
    },
    headers: {
      Authorization: `token ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function collabStatuschangeapi(status) {
  const aUrl = baseURL + `/collaborator/status`; // collaborator/status
  const config = {
    method: "put",
    url: aUrl,
    data: {
      collab_status: status,
    },
    headers: {
      Authorization: `token ${getToken()}`,
    },
  };

  return await axios(config);
}
export async function getCollabStaatus() {
  const aUrl = baseURL + `/collaborator/status`;
  const config = {
    method: "get",
    url: aUrl,
    headers: {
      Authorization: `token ${getToken()}`,
    },
  };

  const resData = await axios(config);
  return resData.data.data.status;
}

export async function getfeedbackQuestionList() {
  const aUrl = baseURL + `/feedback`;
  const config = {
    method: "get",
    url: aUrl,

    headers: {
      Authorization: `token ${getToken()}`,
    },
  };

  return await axios(config);
}

export async function FeedbackFormSubmit(allData) {
  const removeEmptyarray = allData.filter((obj) => Object.keys(obj).length > 0);
  const aUrl = baseURL + `/feedback`; // collaborator/status
  const data = JSON.stringify(removeEmptyarray);
  // const data=removeEmptyarray
  const config = {
    method: "post",
    url: aUrl,
    data: {
      data,
    },
    headers: {
      Authorization: `token ${getToken()}`,
    },
  };

  return await axios(config);
}
