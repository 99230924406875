import React from "react";
import Sidebar from "../components/common/Sidebar";
import Generate from "../components/suggetions/GenerateIdeas";

const GenerateIdeas = () => {
  return (
    <>
      <Sidebar layout={<Generate />} />
    </>
  );
};

export default GenerateIdeas;
