import { useState, useEffect, useCallback } from "react";
import arrow from "../../assets/images/arrow.svg";
import map_pin from "../../assets/images/map_pin.svg";
import { useSelector } from "react-redux";
import SendRequestMessgeModal from "../contents/RequestCollaboratormodal/SendmessageModal";
import RequestSentModal from "../contents/RequestCollaboratormodal/RequestSentModal";
import { formatBigNumbers } from "../../utils/utility-functions";
import logger from "../../config/logger";

export const formatDate = (joinDate) => {
  const options = { year: "numeric", month: "long" };
  const formattedDate = new Date(joinDate).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
};

const CollabSection = (props) => {
  const [requestModal, setRequstModal] = useState(false);
  const [requestSentModalOpen, setRequestSentModalOpen] = useState(false);

  const { activeChannel } = props;
  const {
    channel_title,
    image_url,
    total_subscribers,
    total_views,
    location,
    join_date,
    channel_id,
  } = activeChannel ?? {};

  const apiSavedCollaborators = useSelector(
    (state) => state?.collaborations?.apiSavedCollaborators
  );
  const requestedCollaborators = useSelector(
    (state) => state?.collaborations?.requestedCollaborators
  );
  const isRequested = useCallback(
    () => requestedCollaborators.includes(activeChannel.channel_id),
    [activeChannel.channel_id, requestedCollaborators]
  );

  useEffect(() => {
    isRequested();
  }, [activeChannel, apiSavedCollaborators, isRequested]);

  return (
    <>
      <div className=" bg-white border-solid border-[rgba(0,0,0,0.10)] border-b pt-6 pr-4 pb-6 pl-4 lg:pr-8 lg:pl-6 w-full lg:w-full relative flex flex-col items-center justify-center justify-self-center  gap-6 lg:flex-row lg:items-center lg:gap-6 lg:justify-between">
        <div className="flex items-center justify-center lg:gap-5 flex-col lg:flex-row">
          <a
            href={`https://www.youtube.com/channel/${channel_id}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="rounded-[50%] w-20 h-20 lg:w-20 lg:h-20 relative"
              src={image_url}
              alt="Profile"
            />
          </a>

          <div className="flex flex-col gap-3">
            <a
              href={`https://www.youtube.com/channel/${channel_id}`}
              target="_blank"
              rel="noreferrer"
              className="text-text-1 font-semibold text-22 lg:text-22"
            >
              {channel_title}
            </a>

            <div className="flex gap-6 items-center">
              <p className="text-white font-medium text-12 lg:text-base bg-green-500 w-40 lg:w-44 h-8 rounded-2xl flex justify-center items-center gap-5">
                <span className="text-sm lg:text-base">Ready to Collab</span>
                <img
                  src="https://api.iconify.design/icon-park-solid:correct.svg?color=%23ffffff"
                  className="w-5 h-5 text-white"
                  alt=""
                />
              </p>
              <p className="text-text-1 font-medium text-sm lg:text-base flex items-center gap-2">
                <img src={map_pin} alt="" className="w-4" />
                {location}
              </p>
            </div>

            <div className="flex gap-3 md:gap-6 items-center">
              <p className="text-[#26242D] font-medium text-sm lg:text-base ">
                {total_subscribers && formatBigNumbers(total_subscribers)}{" "}
                Subscribers
              </p>
              <p className="h-4 bg-gray-300 w-px after:block content:''" />
              <p className="text-[#26242D] font-medium text-sm lg:text-base">
                {total_views && formatBigNumbers(total_views)} Views
              </p>
              <p className="h-4 bg-gray-300 w-px after:block content:''" />
              <p className="text-[#26242D] font-medium text-sm lg:text-base">
                Joined on {join_date && formatDate(join_date)}
              </p>
            </div>
          </div>
        </div>

        <button
          onClick={() => {
            if (!isRequested()) {
              logger.log("Request collaboration button clicked");
              setRequstModal(!requestModal);
            } else {
              setRequestSentModalOpen(true);
            }
          }}
          className={` h-12 w-56  px-5 py-3 bg-violet-600 rounded-3xl justify-center items-center flex gap-1 lg:gap-2 ${
            isRequested()
              ? "text-violet-600  bg-opacity-20"
              : "text-white bg-opacity-100"
          } font-semibold text-sm `}
        >
          {isRequested() ? "Collaboration Requested" : "Request Collaboration"}
          {!isRequested() && <img src={arrow} alt="" className="w-5 h-5" />}
        </button>
      </div>
      <SendRequestMessgeModal
        modelopen={requestModal}
        setModalOpen={setRequstModal}
        activeChannel={activeChannel}
      />
      <RequestSentModal
        open={requestSentModalOpen}
        onClose={() => setRequestSentModalOpen(false)}
        requestAlreadySent={true}
        channelTitle={activeChannel.channel_title}
      />
    </>
  );
};
export default CollabSection;
