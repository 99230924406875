import React, { useState } from "react";
import CollabSection from "./CollabSection";
import bookmark from "../../assets/images/bookmark.svg";
import arrow_left from "../../assets/images/arrow-left.svg";
import skeleton from "../../assets/images/skeleton.png";
import axios from "axios";
import {getToken} from '../../api/api';
import { baseURL } from "../../utils/const";
import Lottie from "lottie-react";
import animationData from "../../lotties/startup.json";
import { useSelector, useDispatch } from "react-redux/";
import { usePostHog } from "posthog-js/react";
import error404 from "../../assets/images/404error.png";

import {
  addSavedCollaborator,
  setChannelLoading,
  setShowSaveCollabButton,
  setShowCollabSidebar,
  addSavedCollabsData,
  setApiSavedCollaborators,
  addApiSavedCollaborators,
  addActiveChannel,
} from "../../redux/store/slice/collaborationSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import MainContent from "./main-content";

const Main = ({ activeChannel, collaborationData, ...props }) => {
  const [isSaved, setIsSaved] = useState(false);
  const dispatch = useDispatch();

  const apiSavedCollaborators = useSelector(
    (state) => state?.collaborations?.apiSavedCollaborators
  );
  const firstTimeLoad = useSelector(
    (state) => state?.collaborations?.firstTimeLoad
  );
  const previousActiveChannel = useSelector(
    (state) => state?.collaborations?.previousActiveChannel
  );

  const handleSaveCollaborator = async () => {
    const sessionToken = getToken();
    const headers = {
      Authorization: `token ${sessionToken}`,
    };

    const data = {
      channel_id: activeChannel.channel_id,
      title: activeChannel.channel_title,
      image_url: activeChannel.image_url,
      similarity_scores: activeChannel.similarity_scores,
    };

    dispatch(addApiSavedCollaborators(data));
    try {
      const response = await axios.post(
        `${baseURL}/collaborators/manualcollab/save`,
        data,
        { headers: headers }
      );
      dispatch(addSavedCollabsData(activeChannel));
      dispatch(setShowCollabSidebar(true));
      dispatch(addSavedCollaborator(response.data));
      dispatch(setShowSaveCollabButton(false));
      setIsSaved(true);

      toast.success("Collaborator saved successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error saving collaborator:", error);
      toast.error("Error saving collaborator!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const channelLoading = useSelector(
    (state) => state?.collaborations?.channelLoading
  );

  const showSaveCollabButton = useSelector(
    (state) => state?.collaborations?.showSaveCollabButton
  );

  const isChannelSaved = () => {
    const activeChannelId = activeChannel?.channel_id;
    return apiSavedCollaborators?.some(
      (collaborator) => collaborator.channel_id === activeChannelId
    );
  };

  const {
    reach_percent,
    categories_they_speak_in,
    mostRecentRelatedVideo,
    mostPopularRelatedVideos,
    time_of_posting,
    percentage_top,
    frequency_of_posting,
    no_of_shorts,
    no_of_videos,
    no_of_live,
    average_length_videos,
    average_length_shorts,
    average_length_live,
    channel_title,
  } = activeChannel ?? {};

  if (firstTimeLoad) {
    return (
      <div className="w-full flex flex-col items-center justify-center h-screen">
        {/* <Lottie options={defaultOptions} height={400} width={400} /> */}
        <Lottie
          animationData={animationData}
          loop={true}
          height={400}
          width={400}
        />
        <p className=" text-center text-gray-600 text-sm font-normal leading-normal">
          Shhh... Our page is tiptoeing through the data garden.
          <br />
          Soon, it'll bloom with delightful content!
        </p>
      </div>
    );
  } else if (!activeChannel || channelLoading) {
    return (
      <>
        <div className="flex flex-col items-center xl:hidden justify-center h-screen w-full ">
          <TailSpin
            height="40"
            width="40"
            color="#3b82f6"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
        <div className="flex w-full h-screen hidden xl:block  justify-center items-center">
          <img src={skeleton} alt="" className="w-full" />
        </div>
      </>
    );
  } else if (activeChannel.error) {
    return (
      <div className="md:w-full">
        <div className="flex flex-col w-full h-screen justify-center items-center gap-5">
          <img src={error404} alt="" className="w-96" />
          <h1 className="text-2xl font-bold">{activeChannel.error}</h1>
          <button
            className="bg-blue-600 h-10 w-40 text-white  rounded-lg "
            onClick={() => {
              dispatch(addActiveChannel(previousActiveChannel));
              dispatch(setShowCollabSidebar(true));
            }}
          >
            Go back
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="md:w-full">
        {showSaveCollabButton && (
          <div className=" mx-auto  px-4 py-2 flex flex-col md:flex-row  justify-between items-center bg-purple-900 text-white gap-4">
            <div className="flex items-center gap-6">
              <button
                className="hidden md:block"
                onClick={() => {
                  dispatch(setShowCollabSidebar(true));
                  dispatch(setShowSaveCollabButton(false));
                  dispatch(addActiveChannel(previousActiveChannel));
                }}
              >
                <img src={arrow_left} alt="" className="" />
              </button>
              <h1 className="text-base md:text-xl lg:font-bold leading-loose">
                {channel_title}
              </h1>
            </div>
            <button
              className="px-6 py-2.5 rounded-full border border-white flex items-center gap-2"
              onClick={() => handleSaveCollaborator(activeChannel)}
              disabled={isChannelSaved()}
            >
              <img src={bookmark} alt="" className="" />
              <span className="text-sm md:text-base md:font-semibold leading-normal">
                {isChannelSaved() ? "Saved" : "Save Collaborator"}
              </span>
            </button>
          </div>
        )}

        <CollabSection activeChannel={activeChannel} />

        <MainContent
          mostPopularRelatedVideos={mostPopularRelatedVideos}
          mostRecentRelatedVideo={mostRecentRelatedVideo}
          frequency_of_posting={frequency_of_posting}
          time_of_posting={time_of_posting}
          percentage_top={percentage_top}
          reach_percent={reach_percent}
          no_of_live={no_of_live}
          no_of_shorts={no_of_shorts}
          no_of_videos={no_of_videos}
          average_length_live={average_length_live}
          average_length_shorts={average_length_shorts}
          average_length_videos={average_length_videos}
          categories_they_speak_in={categories_they_speak_in}
        />
        <ToastContainer />
      </div>
    );
  }
};
export default Main;
