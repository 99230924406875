import React from "react";

const PasswordChangeUI = ({ setActiveState }) => {
  return (
    <div className="w-[60%] h-auto flex-col justify-center items-start gap-8 flex">
      <div
        className="justify-start items-center gap-2 flex cursor-pointer"
        onClick={() => setActiveState("setting")}
      >
        <div className="w-4 h-4 relative ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M12.6668 8H3.3335"
              stroke="black"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.00016 12.6667L3.3335 8.00004L8.00016 3.33337"
              stroke="black"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="text-zinc-800 text-sm font-semibold leading-normal">
          Change Password
        </div>
      </div>
      <div className="text-zinc-800 text-xl font-bold leading-7">
        Create New Password
      </div>
      <div className="w-[100%] flex-col justify-start items-center gap-4 flex">
        <div className="w-[100%]  flex-col justify-start items-start  flex">
          <div className=" text-gray-600 text-sm font-normal leading-none mb-2">
            Create a new password for your Upview account.
          </div>
        </div>
        <div className=" flex-col justify-center items-center gap-4 flex">
          <div className="w-[100%] justify-center items-center flex">
            <div className="w-[100%]  p-4 rounded border border-neutral-900 border-opacity-20 justify-start items-center flex">
              <div className="pr-2 justify-start items-start flex">
                <div className="w-4 h-4 px-0.5 py-px flex-col justify-center items-center flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M14.25 8.25H3.75C2.92157 8.25 2.25 8.92157 2.25 9.75V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V9.75C15.75 8.92157 15.0784 8.25 14.25 8.25Z"
                      stroke="#5C566B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.25 8.25V5.25C5.25 4.25544 5.64509 3.30161 6.34835 2.59835C7.05161 1.89509 8.00544 1.5 9 1.5C9.99456 1.5 10.9484 1.89509 11.6517 2.59835C12.3549 3.30161 12.75 4.25544 12.75 5.25V8.25"
                      stroke="#5C566B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <input
                placeholder=" Enter Current password"
                className="text-neutral-900 text-opacity-60 text-base font-normal  w-[100%]  justify-start items-center flex border-none focus:outline-none"
              />
            </div>
          </div>
          <div className="w-[100%] justify-center items-center flex">
            <div className="w-[100%]  p-4 rounded border border-neutral-900 border-opacity-20 justify-start items-center flex">
              <div className="pr-2 justify-start items-start flex">
                <div className="w-4 h-4 px-0.5 py-px flex-col justify-center items-center flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M14.25 8.25H3.75C2.92157 8.25 2.25 8.92157 2.25 9.75V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V9.75C15.75 8.92157 15.0784 8.25 14.25 8.25Z"
                      stroke="#5C566B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.25 8.25V5.25C5.25 4.25544 5.64509 3.30161 6.34835 2.59835C7.05161 1.89509 8.00544 1.5 9 1.5C9.99456 1.5 10.9484 1.89509 11.6517 2.59835C12.3549 3.30161 12.75 4.25544 12.75 5.25V8.25"
                      stroke="#5C566B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <input
                placeholder=" Enter New Password"
                className="text-neutral-900 text-opacity-60 text-base font-normal  w-[100%]  justify-start items-center flex border-none focus:outline-none"
              />
            </div>
          </div>
          <div className="w-[100%] justify-center items-center flex">
            <div className="w-[100%]  p-4 rounded border border-neutral-900 border-opacity-20 justify-start items-center flex">
              <div className="pr-2 justify-start items-start flex">
                <div className="w-4 h-4 px-0.5 py-px flex-col justify-center items-center flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M14.25 8.25H3.75C2.92157 8.25 2.25 8.92157 2.25 9.75V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V9.75C15.75 8.92157 15.0784 8.25 14.25 8.25Z"
                      stroke="#5C566B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.25 8.25V5.25C5.25 4.25544 5.64509 3.30161 6.34835 2.59835C7.05161 1.89509 8.00544 1.5 9 1.5C9.99456 1.5 10.9484 1.89509 11.6517 2.59835C12.3549 3.30161 12.75 4.25544 12.75 5.25V8.25"
                      stroke="#5C566B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <input
                placeholder="Confirm Password"
                className="text-neutral-900 text-opacity-60 text-base font-normal  w-[100%]  justify-start items-center flex border-none focus:outline-none"
              />
            </div>
          </div>
          <div className=" text-gray-600 text-sm font-normal leading-none">
            Password must Contain at least one uppercase, one lowercase, and one
            digit.
          </div>
        </div>
        <div className="w-[100%] flex-col justify-start items-center gap-6 flex">
          <div className="w-[100%] flex-col justify-start items-center gap-6 flex">
            <div className="w-[100%] px-6 py-4 bg-violet-600 rounded-full justify-center items-center gap-2.5 flex cursor-pointer">
              <div className="text-white text-base font-semibold leading-normal">
                Change Password
              </div>
            </div>
            <div className="w-[100%] px-6 py-4 justify-center items-center gap-2.5 flex cursor-pointer">
              <div className="text-violet-600 text-base font-bold leading-normal">
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangeUI;
