import axios from "axios";
import "./card.css";

const Card = ({ image, name, subs, type, desc, values }) => {
  const handleLogin = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user-data");
    const baseURL = "https://app.upview.ai";
    const response = await axios.post(baseURL + "/login/", {
      username: values?.email,
      password: values?.password,
    });
    if (
      response?.data?.message === "User login successfully." &&
      response?.data?.data?.verification_status === true
    ) {
      localStorage.setItem("user-data", JSON.stringify(response?.data?.data));
      localStorage.setItem("token", response?.data?.data?.token);
      localStorage.setItem(
        "onboarding_status",
        response?.data?.data?.onboarding_status
      );
      setTimeout(() => {
        window.location.href = "https://web.upview.ai/home";
      }, 200);
    }
  };

  return (
    <div className="card" onClick={handleLogin}>
      <img src={image} alt="youtuber-logo" />
      <div className="container .gap-24">
        <div className="details">
          <div className="name">{name}</div>
          <div className="subs">{subs}</div>
        </div>
        <div>
          <div className="container gap-12 mt-[24px]">
            <div className="type">{type}</div>
            <div className="desc">{desc}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
