// apiCollaborationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../../../utils/const";
import logger from "../../../../config/logger";

// Replace 'your-api-endpoint' with your actual API endpoint
const apiEndpoint = baseURL;
const authToken = localStorage.getItem("token");

export const fetchApiShortData = createAsyncThunk(
  "/video/short",
  async (Token) => {
    logger.log("Fetching shorts data");

    try {
      const config = {
        headers: {
          Authorization: `token ${Token}`,
        },
      };
      const start_time = Date.now();
      const response = await axios.get(apiEndpoint + "/video/short", config);
      const end_time = Date.now();

      if (response.status === 200) {
        logger.success(
          `Successfully fetched shorts data in ${end_time - start_time}`
        );
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (error) {
      logger.error(`Failed to fetch shorts data`, error);
      return [];
    }
  }
);

const apiSuggestionShortSlice = createSlice({
  name: "api",
  initialState: { shortData: null, loadingShort: "idle", errorShort: null },
  reducers: {
    short_Data_filter_Value(state, action) {
      state.shortData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiShortData.pending, (state) => {
        state.loadingShort = "pending";
      })
      .addCase(fetchApiShortData.fulfilled, (state, action) => {
        state.loadingShort = "succeeded";
        state.shortData = action.payload.sort(
          (a, b) => new Date(b.published) - new Date(a.published)
        );
      })
      .addCase(fetchApiShortData.rejected, (state, action) => {
        state.loadingShort = "failed";
        state.errorShort = action.error.message;
      });
  },
});
export const { short_Data_filter_Value } = apiSuggestionShortSlice.actions;

export default apiSuggestionShortSlice.reducer;
