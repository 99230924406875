import React, { useEffect } from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FeedbackFormSubmit } from "../../api/api";
const RatingModal = ({
  modelopen,
  setModalOpen,
  titleData,
  setRatingModalState,
  ratingModalState,
  questionList,
  setCancelModal,
  //
  selectedRating,
  setSelectedRating,
  setThankyouModal,
  cleaarData,
  setClearData,
}) => {
  const cancelButtonRef = useRef(null);

  // const [selectedRating, setSelectedRating] = useState();
  const [formSubmit, setFormSubmit] = useState(false);
  const [userText, setUserText] = useState();
  const [datatitle, setTitleData] = useState();
  const [close, setClose] = useState(false);

  const [lastQuestionData, setLastquestData] = useState("");

  const [feedbackDataByUser, setFeedbackDataByUser] = useState([]);

  //

  const [question_one, setQuestionOne] = useState({});
  const [question_two, setQuestionTwo] = useState({});
  const [question_three, setQuestionThree] = useState({});
  const [question_four, setQuestionFour] = useState({});
  const [question_five, setQuestionFive] = useState({});
  const [question_six, setQuestionSix] = useState({});
  const [question_seven, setQuestionSeven] = useState({});
  const [question_eight, setQuestionEight] = useState({});

  useEffect(() => {
    setTitleData(questionList?.filter((f) => f.id === ratingModalState));
  }, [ratingModalState, questionList]);

  const StateChange = (value, id, is_textareabox) => {
    if (is_textareabox) {
      if (value === questionList?.length) {
        setFeedbackDataByUser((prev) => [
          ...prev,
          { id: datatitle[0]?.id, value: "text", message: userText },
        ]);
        setFormSubmit(!formSubmit);
        setSelectedRating();

        setUserText();
      } else {
        if (userText) {
          setFeedbackDataByUser((prev) => [
            ...prev,
            { id: datatitle[0]?.id, value: "text", message: userText },
          ]);
          setRatingModalState(value + 1);
          setSelectedRating();
          setUserText();
        } else {
          setRatingModalState(value + 1);
        }
      }
    } else {
      if (value <= questionList?.length - 1) {
        if (selectedRating) {
          setRatingModalState(value + 1);
          setFeedbackDataByUser((prev) => [
            ...prev,
            { id: datatitle[0]?.id, value: selectedRating },
          ]);
          setSelectedRating();
        } else {
          setRatingModalState(value + 1);
        }
      }
    }
  };

  const RatingAdd = (rating) => {
    setSelectedRating(rating);
  };

  // const PreviousFunction=(ratingState,id,isTextBox)=>{

  //   const prevState=id - 1
  //   const checkData=feedbackDataByUser.find((data)=>data.id == prevState)
  //   if(checkData){
  //     if(checkData.value!=="text"){
  //       setSelectedRating(checkData.value)
  //       setRatingModalState(ratingModalState -1)
  //     }else{
  //     }
  //   }else{
  //   }
  // }

  // const NextFunction=(ratingState,id,isTextBox)=>{

  //   const nextState=id +1
  //   const checkData=feedbackDataByUser.find((data)=>data.id == nextState)
  //   if(checkData){
  //     setSelectedRating(checkData.value)
  //     feedbackDataByUser[checkData].value=selectedRating
  //     setRatingModalState(ratingModalState+1)
  //   }else{
  //     setFeedbackDataByUser((prev) => [
  //       ...prev,
  //       { id: datatitle[0]?.id, value: selectedRating },
  //     ]);
  //     setRatingModalState(ratingModalState+1)

  //   }
  // }

  const SubmitFeedbackForm = async () => {
    await FeedbackFormSubmit([
      question_one,
      question_two,
      question_three,
      question_four,
      question_five,
      question_six,
      question_seven,
      question_eight,
    ])
      .then((res) => {
        setRatingModalState(1);
        setModalOpen(false);
        // setFeedbackDataByUser([])
        setTimeout(() => {
          setThankyouModal(true);
        }, 500);
        setClearData();
      })
      .catch((err) => {
        setModalOpen(false);
        setRatingModalState(1);
        setClearData();
        // setFeedbackDataByUser([])
      });
  };

  useEffect(() => {
    if (formSubmit) {
      SubmitFeedbackForm();
    }
  }, [formSubmit]);

  useEffect(() => {
    setSelectedRating();
    setUserText();
    setLastquestData("");
    setQuestionOne({});
    setQuestionTwo({});
    setQuestionThree({});
    setQuestionFour({});
    setQuestionFive({});
    setQuestionSix({});
    setQuestionSeven({});
    setQuestionEight({});
  }, [cleaarData]);

  const prev = (ratingModalState, id, is_textbox) => {
    if (ratingModalState - 1 === 1) {
      setRatingModalState(ratingModalState - 1);
      setSelectedRating(question_one.value);
    }
    if (ratingModalState - 1 === 2) {
      setRatingModalState(ratingModalState - 1);
      setSelectedRating(question_two.value);
    }
    if (ratingModalState - 1 === 3) {
      setRatingModalState(ratingModalState - 1);
      setSelectedRating(question_three.value);
    }
    if (ratingModalState - 1 === 4) {
      setRatingModalState(ratingModalState - 1);
      setSelectedRating(question_four.value);
    }
    if (ratingModalState - 1 === 5) {
      setRatingModalState(ratingModalState - 1);
      setSelectedRating(question_five.value);
    }
    if (ratingModalState - 1 === 6) {
      setRatingModalState(ratingModalState - 1);
      setSelectedRating(question_six.value);
    }
    if (ratingModalState - 1 === 7) {
      setQuestionEight({
        id: datatitle[0].id.toString(),
        value: "text",
        message: userText,
      });
      setRatingModalState(ratingModalState - 1);
      setUserText(question_seven.message);
    }
    // if(ratingModalState===8){

    // }
  };
  const next = (ratingModalState, id, is_textbox) => {
    if (datatitle[0].id === 1) {
      if (selectedRating) {
        setQuestionOne({
          id: datatitle[0].id.toString(),
          value: selectedRating.toString(),
        });
        setRatingModalState(ratingModalState + 1);
        setSelectedRating();
      } else {
        setRatingModalState(ratingModalState + 1);
      }
      setSelectedRating(question_two.value);
    }
    if (datatitle[0].id === 2) {
      if (selectedRating) {
        setQuestionTwo({
          id: datatitle[0].id.toString(),
          value: selectedRating.toString(),
        });
        setRatingModalState(ratingModalState + 1);
        setSelectedRating();
      } else {
        setRatingModalState(ratingModalState + 1);
      }
      setSelectedRating(question_three.value);
    }
    if (datatitle[0].id === 3) {
      if (selectedRating) {
        setQuestionThree({
          id: datatitle[0].id.toString(),
          value: selectedRating.toString(),
        });
        setRatingModalState(ratingModalState + 1);
        setSelectedRating();
      } else {
        setRatingModalState(ratingModalState + 1);
      }
      setSelectedRating(question_four.value);
    }
    if (datatitle[0].id === 4) {
      if (selectedRating) {
        setQuestionFour({
          id: datatitle[0].id.toString(),
          value: selectedRating.toString(),
        });
        setRatingModalState(ratingModalState + 1);
        setSelectedRating();
      } else {
        setRatingModalState(ratingModalState + 1);
      }
      setSelectedRating(question_five.value);
    }
    if (datatitle[0].id === 5) {
      if (selectedRating) {
        setQuestionFive({
          id: datatitle[0].id.toString(),
          value: selectedRating.toString(),
        });
        setRatingModalState(ratingModalState + 1);
        setSelectedRating();
      } else {
        setRatingModalState(ratingModalState + 1);
      }
      setSelectedRating(question_six.value);
    }
    if (datatitle[0].id === 6) {
      if (selectedRating) {
        setQuestionSix({
          id: datatitle[0].id.toString(),
          value: selectedRating.toString(),
        });
        setRatingModalState(ratingModalState + 1);
        setSelectedRating();
      } else {
        setRatingModalState(ratingModalState + 1);
      }
      setSelectedRating(question_seven.value);
    }
    if (datatitle[0].id === 7) {
      if (userText) {
        setQuestionSeven({
          id: datatitle[0].id.toString(),
          value: "text",
          message: userText,
        });
        setRatingModalState(ratingModalState + 1);
        setUserText("");
      } else {
        // setQuestionEight({id:datatitle[0].id.toString(), value:"text",message:userText})
        setRatingModalState(ratingModalState + 1);
      }
      // setUserText(question_eight.message)
    }
    if (datatitle[0].id === 8) {
      if (lastQuestionData) {
        setQuestionEight({
          id: datatitle[0].id.toString(),
          value: "text",
          message: lastQuestionData,
        });
        setFormSubmit(!formSubmit);
        setLastquestData();
      } else {
        setFormSubmit(!formSubmit);
      }
    }
  };

  return (
    <Transition.Root show={modelopen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setClose(!close)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* sm:max-w-lg */}
              <Dialog.Panel
                className={`w-[35%] ${"h-[auto]"}  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 max-sm:w-3/4 `}
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-[100%] flex">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-[100%]">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      <div
                        className="flex justify-end items-center cursor-pointer"
                        onClick={() => setCancelModal(true)}
                      >
                        <div className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <path
                              d="M15 5.5L5 15.5"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5 5.5L15 15.5"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </Dialog.Title>
                    {/*  */}
                    <div className="w-[100%] h-[100%]  justify-center items-start gap-10 flex">
                      <div className="w-[100%] h-[100%]  p-5 bg-white rounded-2xl flex-col justify-between items-center gap-20 flex">
                        <div className=" w-[100%] flex-col justify-start items-center gap-5 flex">
                          <div className=" text-center text-zinc-800 text-2xl font-semibold">
                            {datatitle && datatitle[0]?.question}
                          </div>
                          <div className="self-stretch text-center text-gray-500 text-xs font-normal leading-none">
                            {datatitle && datatitle[0]?.sub_text}
                          </div>
                        </div>

                        <div className="justify-center items-start gap-3 flex w-[100%]">
                          {datatitle && !datatitle[0]?.is_textbox ? (
                            [1, 2, 3, 4, 5].map((r, id) => (
                              <div
                                key={id}
                                className={`w-12 h-12 p-2.5 ${
                                  selectedRating >= r
                                    ? "bg-violet-600 bg-opacity-30"
                                    : "bg-slate-100"
                                }  rounded-full border border-black border-opacity-10 flex-col justify-center items-center gap-2.5 inline-flex cursor-pointer`}
                                onClick={() => RatingAdd(r)}
                              >
                                <div className="text-center text-zinc-800 text-base font-bold leading-none ">
                                  {r}
                                </div>
                              </div>
                            ))
                          ) : questionList?.length === ratingModalState ? (
                            <textarea
                              value={lastQuestionData}
                              rows={6}
                              placeholder={`Kindly elaborate the improvements you want.`}
                              className="text-gray-600 text-base font-normal leading-snug w-[100%]   focus:outline-none p-4 rounded border border-neutral-900 border-opacity-20 justify-start items-start flex"
                              onChange={(e) =>
                                setLastquestData(e.target.value.toString())
                              }
                            />
                          ) : (
                            <textarea
                              value={userText}
                              rows={6}
                              placeholder={`Kindly elaborate the improvements you want.`}
                              className="text-gray-600 text-base font-normal leading-snug w-[100%]   focus:outline-none p-4 rounded border border-neutral-900 border-opacity-20 justify-start items-start flex"
                              onChange={(e) =>
                                setUserText(e.target.value.toString())
                              }
                            />
                          )}
                        </div>
                        <div className=" w-[40%] justify-center items-start gap-5 flex">
                          {questionList && ratingModalState !== 1 && (
                            <div className="h-11 px-1 py-3 justify-center items-center gap-2.5 flex">
                              <div
                                className="text-violet-600 text-xs font-bold leading-tight cursor-pointer"
                                onClick={() =>
                                  prev(
                                    ratingModalState,
                                    datatitle[0]?.id,
                                    datatitle[0]?.is_textbox
                                  )
                                }
                              >
                                Previous
                              </div>
                            </div>
                          )}
                          {questionList?.length === ratingModalState ? (
                            <div
                              className="h-11 px-6 py-3 bg-violet-600 rounded-full justify-center items-center gap-2.5 flex cursor-pointer"
                              onClick={() => {
                                next(
                                  ratingModalState,
                                  datatitle[0]?.id,
                                  datatitle[0]?.is_textbox
                                );
                              }}
                            >
                              <div className="text-white text-xs font-bold leading-tight">
                                Submit
                              </div>
                            </div>
                          ) : (
                            <div
                              className={`h-11 px-6 py-3 bg-violet-600 rounded-full justify-center items-center gap-2.5 flex ${selectedRating} cursor-pointer`}
                              onClick={() => {
                                next(
                                  ratingModalState,
                                  datatitle[0]?.id,
                                  datatitle[0]?.is_textbox
                                );
                              }}
                            >
                              <div className="text-white text-xs font-bold leading-tight">
                                Next
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/*  */}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RatingModal;
