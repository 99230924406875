import { useState } from "react";
import TruffleShuffleImage from "../../../assets/images/truffleshuffle.png";
import UnfairSportsImage from "../../../assets/images/unfairsports.png";
import JamesPropertyImage from "../../../assets/images/jamesproperty.png";
import MyTaropistImage from "../../../assets/images/mytaropist.png";
import Card from "./card/card";
import "./game-1.scss";

const Game1 = () => {
  const [header, setHeader] = useState(
    "Mimic one of the below YouTubers to use Upview"
  );
  return (
    <div id="game-1-main">
      <div className="header-game-1">{header}</div>
      <div className="cards-display">
        <Card
          image={TruffleShuffleImage}
          name="Let's Food"
          subs="1.47K Subscibers"
          type="Food Channel"
          desc="This channel is all about food and creating great dishes. From simple recipes to chef curated cusines 
          they bring a whole new flavor to the table."
          values={{ email: "testf@gmail.com", password: "Test1!" }}
        />
        <Card
          image={UnfairSportsImage}
          name="Unfairsports"
          subs="10.8K Subscibers"
          type="Sports Channel"
          desc="This channel mainly focuses on college football and has found success in
          speaking on the OU football team. "
          values={{ email: "testc@gmail.com", password: "Test1!" }}
        />
        <Card
          image={JamesPropertyImage}
          name="James Property"
          subs="6.65K Subscibers"
          type="Property Channel"
          desc="This channel is mainly focused on real estate tips and insights. From showing properties
          to diving into investment stratigies."
          values={{ email: "testd@gmail.com", password: "Test1!" }}
        />
        <Card
          image={MyTaropistImage}
          name="My Taropist"
          subs="1.53K Subscibers"
          type="Spiritual Channel"
          desc="This channel is all about food and creating great dishes. From simple recipes to chef curated cusines 
          they bring a whole new flavor to the table."
          values={{ email: "teste@gmail.com", password: "Test1!" }}
        />
      </div>
    </div>
  );
};

export default Game1;
