import React, { useState, useEffect } from "react";
import TextBox from "./TextBox";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { baseURL } from "../../utils/const";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import { Link, useNavigate, useLocation } from "react-router-dom";

const ForgotPasswordComponent = () => {
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
  });

  const initialValues = {
    username: "",
    token: "",
    new_password: "",
    confirm_new_password: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string().required("User Name is required"),
    token: Yup.string().required("Token is required"),
    new_password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const onSubmit = async (values) => {
    setLoader(true);
    try {
      const response = await axios.post(baseURL + "/reset-password/", {
        username: values?.username,
        token: values?.token,
        new_password: values?.new_password,
        confirm_new_password: values?.confirm_new_password,
      });

      toast.success(response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        setLoader(false);
        navigate("/");
      }, 2000);
    } catch (error) {
      console.error(error);
      toast.error("Please try again..!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="flex items-center justify-center flex-col min-h-screen">
        <div className="w-[75vw] max-w-[440px] lg:w-[440px] text-zinc-800 text-[28px] font-bold">
          Reset Password
        </div>
        <div className="w-[75vw] max-w-[440px] lg:w-[440px] text-gray-600 text-base font-normal">
        Create a new password for your Upview account.
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="">
            {/* <TextBox
              type="username"
              name="username"
              id="username"
              placeholder="Enter your username"
            />
            <ErrorMessage
              name="username"
              component="div"
              className="text-red-500"
            /> */}
            {/* <TextBox
              type="text"
              name="token"
              id="token"
              placeholder="Enter your token"
            />
            <ErrorMessage
              name="token"
              component="div"
              className="text-red-500"
            /> */}
            <TextBox
              type="password"
              name="new_password"
              id="new_password"
              placeholder="Enter New Password"
              password
            />
            <ErrorMessage
              name="new_password"
              component="div"
              className="text-red-500"
            />
            <TextBox
              type="password"
              name="confirm_new_password"
              id="confirm_new_password"
              placeholder="Confirm Password"
              password
            />
            <ErrorMessage
              name="confirm_new_password"
              component="div"
              className="text-red-500"
            />
            <div className="max-w-[440px] text-gray-600 text-base font-normal leading-tight mt-5">Password must Contain at least one uppercase, one lowercase, and one digit.</div>
            <button
              type="submit"
              disabled={loader}
              className="mt-[46px] w-[75vw] max-w-[440px] lg:w-[440px] h-[54px] px-[25px] py-4 bg-violet-600 rounded-[53px] justify-center items-center gap-2.5 inline-flex"
            >
              {loader ? (
                <TailSpin
                  height="20"
                  width="20"
                  color="white"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                <div className="text-white text-base font-semibold leading-snug">
                  Reset Password
                </div>
              )}
            </button>
          </Form>
        </Formik>

        <Link to="/" className="text-blue-500">
          <div className="text-violet-600 text-xl mt-5 font-medium">Cancel</div>
        </Link>
      </div>
    </>
  );
};

export default ForgotPasswordComponent;
