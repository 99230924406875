// apiCollaborationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../../../utils/const";
import postHogEvents from "../../../../constants/events";
import logger from "../../../../config/logger";

// Replace 'your-api-endpoint' with your actual API endpoint
const apiEndpoint = baseURL;
const authToken = localStorage.getItem("token");

export const fetchApiData = createAsyncThunk(
  "/collaborators/colls",
  async (token, posthog, email) => {
    logger.log("Fetching collaboration data");
    const startTime = Date.now();
    const config = {
      headers: {
        Authorization: `token ${token}`,
      },
    };
    const response = await axios.get(
      apiEndpoint + "/collaborators/colls",
      config
    );
    const timeElapsed = Date.now() - startTime;
    logger.log(`Collaboration data fetched in ${timeElapsed}ms`, {
      status: response.status,
    });
    // if (posthog) {
    //   posthog?.identify(email);
    //   await posthog?.capture(
    //     postHogEvents.collaborationSuggestionsResponseTime,
    //     {
    //       responseTime: timeElapsed,
    //     }
    //   );
    // }

    return response?.data?.data;
  }
);

export const fetchRequestCollabData = createAsyncThunk(
  "/collaborators/request/save",

  async (token) => {
    logger.log("Fetching request collab data");
    const config = {
      headers: {
        Authorization: `token ${token}`,
      },
    };
    const start = Date.now();
    const response = await axios.get(
      apiEndpoint + "/collaborators/request/save",
      config
    );
    const end = Date.now();
    logger.log(`Fetched request collab data in ${end - start}`, {
      status: response.status,
    });

    return response?.data?.data?.prospect_channel_id;
  }
);

const apiCollaborationSlice = createSlice({
  name: "api",
  initialState: { data: null, loading: "idle", error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiData.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchApiData.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchApiData.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      });
  },
});

export default apiCollaborationSlice.reducer;
