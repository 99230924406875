import React, { useState, useEffect } from "react";
import Img1 from "../../assets/images/img1.png";
import Img2 from "../../assets/images/img2.png";
import Img3 from "../../assets/images/img3.png";
import P1 from "../../assets/images/p1.png";
import P2 from "../../assets/images/p2.png";
import P3 from "../../assets/images/p3.png";
import Image1 from "../../assets/images/image1.png";
import Image2 from "../../assets/images/image2.png";
import Image3 from "../../assets/images/image3.png";

const CarouselComponent = () => {
  const [image, setImage] = useState(Image1);
  const [text1, setText1] = useState("Connect with other Collaborators!");
  const [text2, setText2] = useState("");
  //
  useEffect(() => {
    setTimeout(() => {
      if (image === Image1) {
        setImage(Image2);
        setText1("Generate High Conversion Thumbnails!");
        setText2(
          // "Lorem ipsum dolor sit amet consectetur. Urna ornare non neque etiam.Lorem ipsum dolor sit amet consectetur. Urna ornare non neque etiam."
        );
      }
      if (image === Image2) {
        setImage(Image3);
        setText1("Connect with other Collaborators!");
        setText2(
          // "Urna ornare non neque etiam.Lorem ipsum dolor sit amet consectetur. Urna ornare non neque etiam.Lorem ipsum dolor sit amet consectetur. "
        );
      }
      if (image === Image3) {
        setImage(Image1);
        setText1("AI Powered Content ideas!!");
        setText2(
          // "Lorem ipsum dolor sit amet consectetur. Urna ornare non neque etiam.Lorem ipsum dolor sit amet consectetur. Urna ornare non neque etiam."
        );
      }
    }, 5000);
  });

  return (
    <>
      <div className="flex items-center justify-center flex-col -mt-10">
        <div className="flex items-center justify-center">
          {/* <div className="m-0 flex items-center justify-between flex-col w-[270px] h-[344.80px]">
            <img
              className="w-[72px] h-[72px] rounded-full shadow border border-white"
              src={P1}
              alt="p1"
            />
            <img
              className="w-[72px] h-[72px] -ml-32 rounded-full shadow border border-white"
              src={P2}
              alt="p1"
            />
            <img
              className="w-[72px] h-[72px] rounded-full shadow border border-white"
              src={P3}
              alt="p1"
            />
          </div> */}
          <div className="m-0">
            <img
              // className="w-[270px] h-[344.80px] rounded-2"
              className="w-[561px] h-[561px] rounded-2 object-cover"
              src={image}
              alt="img1"
            />
          </div>
        </div>
        <div className="flex items-center justify-center flex-col">
          <div className="text-white text-[22px] font-bold mt-0">{text1}</div>
          <div className="max-w-[30vw] text-center text-white text-opacity-60 text-sm font-medium">
            {text2}
          </div>
          <div className="flex items-center justify-between flex-row mt-10">
            {image === Image1 && (
              <>
                <div className="w-2 h-2 bg-white rounded-full m-4" />
                <div className="w-2 h-2 bg-white bg-opacity-30 rounded-full m-4" />
                <div className="w-2 h-2 bg-white bg-opacity-30 rounded-full m-4" />
              </>
            )}
            {image === Image2 && (
              <>
                <div className="w-2 h-2 bg-white bg-opacity-30 rounded-full m-4" />
                <div className="w-2 h-2 bg-white rounded-full m-4" />
                <div className="w-2 h-2 bg-white bg-opacity-30 rounded-full m-4" />
              </>
            )}
            {image === Image3 && (
              <>
                <div className="w-2 h-2 bg-white bg-opacity-30 rounded-full m-4" />
                <div className="w-2 h-2 bg-white bg-opacity-30 rounded-full m-4" />
                <div className="w-2 h-2 bg-white rounded-full m-4" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselComponent;
