import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Label,
  LabelList,
  ResponsiveContainer,
} from "recharts";

const COLORS = ["#5FD4CB", "#4D46AE", "#6FD8FB"];

function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.ceil(seconds % 60);

  return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}`;
}

const PieChartComponent = ({
  no_of_live = 0,
  no_of_shorts = 0,
  no_of_videos = 0,
  average_length_videos,
  average_length_live,
  average_length_shorts,
}) => {
  const totalVideos = no_of_live + no_of_shorts + no_of_videos;
  const pieData = [
    { name: "Videos", value: (no_of_videos / totalVideos) * 100 },
    { name: "Shorts", value: (no_of_shorts / totalVideos) * 100 },
    { name: "Live Streams", value: (no_of_live / totalVideos) * 100 },
  ];

  const tooltipFormatter = (value, name, entry) => {
    const roundedValue = Math.round(value * 10) / 10;
    return `${name}: ${roundedValue}%`;
  };

  return (
    <div className="max-w-full flex flex-col items-center gap-1 ">
      <ResponsiveContainer width="100%" height={220}>
        <PieChart>
          <Pie
            data={pieData}
            cx="50%"
            cy="50%"
            innerRadius={70}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            labelLine={false}
          >
            {pieData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
            <Label
              value={`${totalVideos}`}
              position="center"
              className="recharts-text recharts-label"
              fontSize="24"
              fontWeight="bold"
              dy={-10}
            />
            <Label
              value="uploads"
              position="center"
              className="recharts-text recharts-label"
              fontSize="16"
              dy={10}
            />
            <LabelList
              dataKey="name"
              position="outside"
              fill="#666"
              fontSize="16"
              content={({ value, name, percent }) =>
                `${name} (${Math.round(percent)}%)`
              }
            />
          </Pie>

          <Tooltip formatter={tooltipFormatter} />
        </PieChart>
      </ResponsiveContainer>
      <div className="w-full p-3 lg:w-full h-auto flex flex-col justify-start items-start gap-4">
        <div className="self-stretch w-full justify-between items-center gap-4 flex">
          <div className="flex items-center  justify-around gap-2">
            <div className="w-3.5 h-3.5 bg-teal-300 rounded-sm" />
            <p className="text-gray-500 text-sm font-normal w-24">Videos</p>
          </div>
          <p className="text-gray-600 text-xs font-normal leading-none w-28 flex  mr-5 justify-start  whitespace-nowrap">
            Avg Length
            <span className="ml-3">{formatTime(average_length_videos)}</span>
          </p>
          <p className="text-right text-gray-500 text-sm font-normal w-10">
            {((no_of_videos / totalVideos) * 100).toFixed(0)}%
          </p>
        </div>
        <div className="self-stretch w-full justify-between items-center gap-4 flex">
          <div className="flex items-center gap-2">
            <div className="w-3.5 h-3.5 bg-indigo-700 rounded-sm" />
            <p className="text-gray-500 text-sm font-normal w-24">Shorts</p>
          </div>
          <p className="text-gray-600 text-xs mr-5 font-normal leading-none flex  w-28 justify-start  whitespace-nowrap">
            Avg Length
            <span className="ml-3">{formatTime(average_length_shorts)}</span>
          </p>
          <p className="text-right text-gray-500 text-sm font-normal w-10">
            {((no_of_shorts / totalVideos) * 100).toFixed(0)}%
          </p>
        </div>
        <div className="self-stretch w-full justify-between items-center gap-4 flex">
          <div className="flex items-center gap-2">
            <div className="w-3.5 h-3.5 bg-sky-300 rounded-sm" />
            <p className="text-gray-500 text-sm font-normal w-24">
              Live Streams
            </p>
          </div>
          <p className="text-gray-600 mr-5 text-xs font-normal leading-none flex w-28 justify-start whitespace-nowrap">
            Avg Length
            <span className="ml-3">{formatTime(average_length_live)}</span>
          </p>
          <p className="text-right text-gray-500 text-sm font-normal w-10">
            {((no_of_live / totalVideos) * 100).toFixed(0)}%
          </p>
        </div>
      </div>
    </div>
  );
};

export default PieChartComponent;
