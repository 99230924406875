import { configureStore } from "@reduxjs/toolkit";

import collaborationData from "./slice/collaborationSlice";
import suggestionData from "./slice/suggestionSlice";
import Onboarding from "./slice/Onboarding";

import apiSuggestionVideoMychannelSlice from "./slice/api/apiSuggestionVideoSlice";
import apiSuggestionShortSlice from "./slice/api/apiShortSlice";
import apiSuggestionOtherSlice from "./slice/api/apiSuggestionOtherSlice";
import apiSuggestionKeyWordSlice from "./slice/api/apiSuggestionKeywordSlice";

import apiSuggestionOtherSlice28day from "./slice/api/apiOther28daySlice";

import apiCollaborationReducer from "./slice/api/apiCollaborationSlice";
import sortSlice from "./slice/sortSlice";
import userSlice from "./slice/user";

const store = configureStore({
  reducer: {
    collaborations: collaborationData,
    suggestion_data: suggestionData,
    Onboarding: Onboarding,
    apiCollaboration: apiCollaborationReducer,
    apiSuggestionMyChannel: apiSuggestionVideoMychannelSlice,
    apiSuggestionShort: apiSuggestionShortSlice,
    apiSuggestionOther: apiSuggestionOtherSlice,
    apiSuggestionKeyWord: apiSuggestionKeyWordSlice,
    SortSlice: sortSlice,
    userSlice: userSlice,
    apiOtherdata28Day: apiSuggestionOtherSlice28day,
  },
});

export default store;
