import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";

export default function ConfirmCancelRewardsModal({ modelopen, setModalOpen,setRaatingModal,selectedRating,setSelectedRating,setRatingModalState,setClearData,cleaarData }) {
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={modelopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={() => setModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-30 overflow-y-auto ">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-[95%] lg:w-[30%] p-2 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8  ">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-[100%]">
                    <div className="sm:flex sm:items-start w-[100%]">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-[100%]">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        ></Dialog.Title>
                        <div className=" p-2 main w-[100%] flex flex-col gap-2 py-7">
                          <div className="w-[100%] flex-col justify-center items-center gap-7 flex">
                            <h1 className="text-center text-black text-2xl font-semibold">
                              Are you sure?
                              <br />
                              You are almost there!
                            </h1>
                            <p className="w-[397px] text-center text-gray-500 text-xs font-normal leading-[18px]">
                              You are really close to winning a chance to get a
                              $50 Amazon gift card. Complete 2 more questions
                              and it is yours.
                            </p>
                            <p
                              className="bg-red-700 bg-opacity-10 rounded-2xl justify-start items-center gap-2 inline-flex
                              px-4 py-2"
                            >
                              <AiOutlineInfoCircle className="text-red-700" />
                              <p className="text-red-700 text-sm font-normal">
                                You will not get the rewards if you cancel now
                              </p>
                            </p>
                          </div>

                          <div className="w-[100%] flex items-center justify-center gap-5 mt-5">
                            <button onClick={()=>setModalOpen(false)} className="bg-violet-600 text-white rounded-3xl border border-violet-600 h-10   w-48 text-xs lg:text-base font-bold leading-normal ">
                              No
                            </button>

                            <button onClick={()=>{setRaatingModal(false);setModalOpen(false);setSelectedRating();setRatingModalState(1);setClearData(!cleaarData)}} className="w-48 lg:w-60 text-red-700 bg-white-600 h-10  rounded-full justify-center items-center flex border-[1px] border-red-700 font-semibold leading-normal cursor-pointer text-xs lg:text-base ">
                              Yes, Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
