import React, { useState } from "react";
import TextBox from "./TextBox";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { baseURL } from "../../utils/const";
import { ToastContainer, toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, Link } from "react-router-dom";
import GoogleAuth from "./GoogleAuth";
import { usePostHog } from "posthog-js/react";
import postHogEvents from "../../constants/events";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApiData,
  fetchRequestCollabData,
} from "../../redux/store/slice/api/apiCollaborationSlice";
import {
  setFirstTimeLoad,
  setRequestedCollaborators,
} from "../../redux/store/slice/collaborationSlice";
import logger from "../../config/logger";
import "react-toastify/dist/ReactToastify.css";

const LoginComponent = () => {
  const [loader, setLoader] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const posthog = usePostHog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Password = localStorage.getItem("password");
  const Email = localStorage.getItem("email");

  const initialValues = {
    email: Email ? Email : "",
    password: Password ? Password : "",
  };
  const { data, loading, error } = useSelector(
    (state) => state.apiCollaboration
  );

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = async (values) => {
    logger.log("User trying to login", { user: values?.email });
    setLoader(true);
    if (rememberMe) {
      localStorage.setItem("email", values?.email);
      localStorage.setItem("password", values?.password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    try {
      const response = await axios.post(baseURL + "/login/", {
        username: values?.email,
        password: values?.password,
      });
      if (
        response?.data?.message === "User login successfully." &&
        response?.data?.data?.verification_status === true
      ) {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        posthog.identify(values.email);
        const eventData = {
          user: values.email,
          channelName: `${response.data.data.channel?.channel_id}`,
          channelId: `${response.data.data.channel?.channel_id}`,
        };
        posthog.capture(postHogEvents.signIn, eventData);

        localStorage.setItem("user-data", JSON.stringify(response?.data?.data));
        localStorage.setItem("token", response?.data?.data?.token);
        logger.success("User logged in");

        if (loading === "idle") {
          dispatch(setFirstTimeLoad(true));
          dispatch(fetchRequestCollabData(response?.data?.data?.token)).then(
            (result) => {
              dispatch(setRequestedCollaborators(result.payload));
            }
          );
          dispatch(fetchRequestCollabData(response?.data?.data?.token));
          dispatch(
            fetchApiData(response?.data?.data?.token, posthog, values.email)
          );
        }
        localStorage.setItem(
          "onboarding_status",
          response?.data?.data?.onboarding_status
        );
      } else {
        setLoader(false);
        toast.error("Verfiy your account and try again", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      setTimeout(() => {
        setLoader(false);
        if (
          response?.data?.message === "User login successfully." &&
          response?.data?.data?.verification_status === true
        ) {
          if (response?.data?.data?.authorization_status === false) {
            navigate("/account-registered", { state: values?.email });
          } else {
            if (response?.data?.data?.onboarding_status) {
              // if (token) {
              navigate("/home", { state: "logged" });
              // }
            } else {
              navigate("/onboarding");
            }
          }
        }
      }, 2000);
    } catch (error) {
      logger.error("User login failed", { user: values?.email });
      toast.error("Please try again..!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);
    }
  };

  const handleRememberMeChange = () => {
    if (!loader) {
      setRememberMe(!rememberMe);
    }
  };

  return (
    <div className="">
      <ToastContainer />
      <div className="flex items-center justify-center flex-col mt-10">
        <div className="flex items-start justify-start flex-col w-[75vw] max-w-[440px] lg:w-[440px]">
          <div className="text-zinc-800 text-4xl font-bold">Welcome Back!!</div>
          <div className="mt-[20px] text-gray-600 text-base font-normal">
            Login to your account and continue your journey
          </div>
        </div>
        <GoogleAuth buttonText="Sign in with Google" />

        <div className="mt-[42px] w-[75vw] max-w-[440px] lg:w-[440px] h-[15px] justify-start items-center gap-3 inline-flex">
          <div className="grow shrink basis-0 h-[0px] border border-black border-opacity-10"></div>
          <div className="text-center text-gray-600 text-xs font-medium">
            Or continue with
          </div>
          <div className="grow shrink basis-0 h-[0px] border border-black border-opacity-10"></div>
        </div>

        {/* form */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="">
            <TextBox
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              email
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500"
            />
            <TextBox
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              Field="Field"
              password
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-red-500"
            />

            <div className="flex items-center justify-between mt-5">
              <div className="justify-start items-center gap-2 flex">
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  className="w-[18px] h-[18px] rounded border border-neutral-900 border-opacity-20"
                />
                <div className="text-gray-600 text-sm font-normal leading-[16.80px]">
                  Remember me
                </div>
              </div>
              <Link
                to="/forgot-password"
                className="text-violet-600 text-sm font-medium cursor-pointer"
              >
                Forgot Password?
              </Link>
            </div>

            <button
              type="submit"
              disabled={loader}
              className="mt-[46px] w-[75vw] max-w-[440px] lg:w-[440px] h-[54px] px-[25px] py-4 bg-violet-600 rounded-[53px] justify-center items-center gap-2.5 inline-flex"
            >
              {loader ? (
                <TailSpin
                  height="20"
                  width="20"
                  color="white"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                <div className="text-white text-base font-semibold leading-snug">
                  Login
                </div>
              )}
            </button>
          </Form>
        </Formik>
        {/* <div className="mt-5 w-[75vw] max-w-[440px] lg:w-[440px] h-[18px] justify-between items-center inline-flex">
          <div className="justify-start items-center gap-2 flex">
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={handleRememberMeChange}
              className="w-[18px] h-[18px] rounded border border-neutral-900 border-opacity-20"
            />
            <div className="text-gray-600 text-sm font-normal leading-[16.80px]">
              Remember me
            </div>
          </div>
          <Link
            to="/forgot-password"
            className="text-violet-600 text-sm font-medium cursor-pointer"
          >
            Forgot Password?
          </Link>
        </div> */}
      </div>
      <div className="flex items-center justify-center">
        <div className="mt-[46px] max-w-[440px] lg:w-[440px] h-[17px] justify-center items-center gap-2 inline-flex">
          <div className="text-center text-black text-sm font-normal">
            Don’t have an Account?
          </div>
          <Link to="/signup" className="text-blue-500">
            <div className="text-violet-600 text-sm font-medium">
              Create an Account
            </div>
          </Link>
        </div>
      </div>
      <div className="text-center my-[46px]">
        <span className="text-gray-500 text-sm font-normal">
          By signing up I agree to Upview’s <br />
        </span>
        <a
          href="https://upview.ai/terms-conditions"
          target="_blank"
          rel="noopener noreferrer"
          className="text-zinc-800 text-sm font-normal underline"
        >
          Terms of Service
        </a>
        <span className="text-zinc-800 text-sm font-normal"> </span>
        <span className="text-gray-500 text-sm font-normal">and</span>
        <span className="text-zinc-800 text-sm font-normal"> </span>
        <a
          href="https://upview.ai/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-zinc-800 text-sm font-normal underline"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

export default LoginComponent;
