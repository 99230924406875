import React, { useEffect } from "react";
import Check from "../../assets/images/check.svg";
import Youtube from "../../assets/images/youtube.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AccountRegisteredComponent = () => {
  const googleClient = process.env.REACT_APP_GOOGLE_CLIENT;
  const redirectURL = process.env.REACT_APP_API_REDIRECT_YOUTUBE_URL;
  const token = localStorage.getItem("token");
  const userData = useSelector((state) => state.userSlice.userData);


  const scope =
    "https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtube.readonly";

  const openNewWindow = () => {
    // Specify the URL of the new window
    // const url = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${googleClient
    //   }&redirect_uri=${redirectURL
    //   }&scope=${scope}&state=${loggedInUser}&access_type=offline&prompt=consent&include_granted_scopes=true`;

    const url = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${googleClient}&redirect_uri=${redirectURL}&scope=https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtube.readonly&state=${token}&access_type=offline&prompt=consent&include_granted_scopes=true`;

    // Open the new window
    window.open(url, "_blank");
  };
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    if (!location?.state) {
      navigate("/");
    }
  });

  const handleBack = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user-data");
    navigate("/signup");
  };

  window.addEventListener("popstate", handleBack);
  // useEffect(() => {
  //   const handleBack = () => {
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("user-data");
  //     navigate("/");
  //   };
  //   return () => {
  //     window.removeEventListener("popstate", handleBack);
  //   };
  // }, []);

  return (
    <>
      <div className="flex items-center justify-center flex-col min-h-screen">
        <div className="w-16 h-16 bg-green-600 rounded-[999px] justify-center items-center gap-2.5 inline-flex">
          <img src={Check} alt="check" className="w-6 h-6 relative" />
        </div>
        <div className="mt-[24px] w-[75vw] max-w-[440px] lg:w-[440px] text-center text-zinc-800 text-[28px] font-bold">
          Account Registered!
        </div>
        <div className="mt-[40px] w-[75vw] max-w-[346px] lg:w-[346px] text-center text-gray-600 text-base font-normal leading-normal">
          Authorise your YouTube account to start getting insights for your
          channel
        </div>
        <div className="cursor-pointer mt-[40px] w-[75vw] max-w-[440px] lg:w-[440px] h-[59px] p-5 bg-white rounded-md shadow justify-center items-center gap-[66px] inline-flex">
          <button
            onClick={() => openNewWindow()}
            className="justify-center items-center gap-2 flex"
          >
            <div className="text-center text-zinc-800 text-base font-medium">
              Sign in with
            </div>
            <div className="w-[84.82px] h-[19px] relative">
              <div className="w-[84.82px] h-[18.94px] left-0 top-[0.03px] absolute">
                <img
                  className="top-[0.74px] absolute"
                  src={Youtube}
                  alt="youtube"
                />
              </div>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default AccountRegisteredComponent;
