import React, { useState } from "react";
import ChromeImage from "../../assets/images/chrome.png";
import YoutubeImage from "../../assets/images/youtube.png";
import GoogleImage from "../../assets/images/google.png";
import ReferralImage from "../../assets/images/referral.png";
import { useDispatch } from "react-redux";
import { hearAboutUs, onBoardState } from "../../redux/store/slice/Onboarding";
const HearAbout = ({ setActive }) => {
  const dispatch = useDispatch();

  const HearAbout = (value) => {
    dispatch(onBoardState(value));
    if (value === "youtube") {
      dispatch(hearAboutUs(0));
    }
    if (value === "chrome") {
      dispatch(hearAboutUs(1));
    }
    if (value === "google") {
      dispatch(hearAboutUs(2));
    }
    if (value === "referral") {
      dispatch(hearAboutUs(3));
    }
  };

  return (
    <div className="w-[50%] max-sm:w-[100%] md:w-[80%] lg:w-[60%] flex justify-center p-5">
      <div className="w-[100%] max-sm:w-[100%] md:w-[100%] p-10 bg-white rounded-3xl flex-col justify-start items-center gap-16 flex">
        <div className="w-56 justify-start items-center flex">
          <div className="w-2 h-2 bg-green-600 rounded-full border" />
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="w-2 h-2 bg-stone-300 rounded-full" />
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="w-2 h-2 bg-stone-300 rounded-full" />
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="w-2 h-2 bg-stone-300 rounded-full" />
        </div>
        <div className="max-sm:w-[100%] flex-col justify-start items-center gap-14 flex">
          <div className="w-96 max-sm:w-[100%] max-sm:text-sm text-center text-zinc-800 text-3xl font-bold leading-10">
            How did you hear about us?
          </div>
          <div className="w-[70%] max-sm:w-[100%] flex-wrap justify-center items-start gap-4 flex">
            <div
              className={`w-44 h-52 px-4 py-8  max-sm:w-[45%] md:w-[44%]
             bg-white
             rounded-2xl border border-black border-opacity-10 flex-col justify-between items-center gap-14 flex cursor-pointer`}
              onClick={() => HearAbout("youtube")}
            >
              <div className="w-14 h-14 relative rounded-md">
                <div className="w-14 h-10 left-0 top-[8.40px] absolute">
                  <img src={YoutubeImage} alt="youtube" />
                </div>
              </div>
              <div className="text-center text-zinc-800 text-base font-semibold leading-normal">
                YouTube
              </div>
            </div>
            <div
              className="w-44 h-52  px-4 py-8 max-sm:w-[45%] md:w-[44%] bg-white rounded-2xl border border-black border-opacity-10 flex-col justify-between items-center gap-14 flex cursor-pointer"
              onClick={() => HearAbout("chrome")}
            >
              <div className="w-14 h-14 relative rounded-md">
                <div className="w-14 h-10 left-0 top-[8.40px] absolute">
                  <img src={ChromeImage} alt="youtube" />
                </div>
              </div>
              <div className="text-center text-zinc-800 text-base font-semibold leading-normal">
                Chrome <br /> Webstore
              </div>
            </div>
            <div
              className="w-44 h-52  px-4 py-8 max-sm:w-[45%] md:w-[44%] bg-white rounded-2xl border border-black border-opacity-10 flex-col justify-between items-center gap-14 flex cursor-pointer"
              onClick={() => HearAbout("google")}
            >
              <div className="w-14 h-14 relative rounded-md">
                <div className="w-14 h-10 left-0 top-[8.40px] absolute">
                  <img src={GoogleImage} alt="youtube" />
                </div>
              </div>
              <div className="text-center text-zinc-800 text-base font-semibold leading-normal">
                Google Search
              </div>
            </div>
            <div
              className="w-44 h-52  px-4 py-8 max-sm:w-[45%] md:w-[44%] bg-white rounded-2xl border border-black border-opacity-10 flex-col justify-between items-center gap-14 flex cursor-pointer"
              onClick={() => HearAbout("referral")}
            >
              <div className="w-14 h-14 relative rounded-md">
                <div className="w-14 h-10 left-0 top-[8.40px] absolute">
                  <img src={ReferralImage} alt="youtube" />
                </div>
              </div>
              <div className="text-center text-zinc-800 text-base font-semibold leading-normal">
                Referral
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HearAbout;
