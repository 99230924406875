import React, { useState } from "react";
import ArrowRight from "../../assets/images/arrow-right.png";
import ArrowLeft from "../../assets/images/arrow-left.png";
import Seo from "../../assets/images/seo.png";
import Thumbnail from "../../assets/images/thumbnail.png";
import Collaboration from "../../assets/images/collaboration.png";
import ContentIdea from "../../assets/images/content-idea.png";
import Education from "../../assets/images/education.png";
import Others from "../../assets/images/others.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addOnBoardInterestedData,
  onBoardState,
} from "../../redux/store/slice/Onboarding";
const Interested = () => {
  const { onboard_referral_data, onboard_interested_data } = useSelector(
    (state) => state.Onboarding
  );
  const [seo, setSeo] = useState(
    onboard_interested_data.includes(0) ? true : false
  );
  const [thumbnail, setThumbaail] = useState(
    onboard_interested_data.includes(1) ? true : false
  );
  const [collaboration, setCollaboration] = useState(
    onboard_interested_data.includes(2) ? true : false
  );
  const [contentIdea, setContentIdea] = useState(
    onboard_interested_data.includes(3) ? true : false
  );
  const [education, setEducation] = useState(
    onboard_interested_data.includes(4) ? true : false
  );
  const [other, setOthers] = useState(false);
  const dispatch = useDispatch();

  const NextStep = () => {
    if (
      seo ||
      thumbnail ||
      collaboration ||
      contentIdea ||
      education ||
      other
    ) {
      dispatch(onBoardState("collab"));
    }
  };

  return (
    <div className="w-[60%] max-sm:w-[100%] md:w-[80%] lg:w-[60%] xl:w-[60%] 2xl:w-[60%] flex justify-center">
      <div className="w-[100%]  max-sm:p-5 md:w-[100%] lg:w-[100%] xl:w-[100%] 2xl:w-[100%]  p-10 bg-white flex-col justify-center items-center gap-16 flex ">
        <div className="w-56 justify-start items-center flex">
          <div className="w-2 h-2 bg-green-600 rounded-full border" />
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="w-2 h-2 bg-green-600 rounded-full" />
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="w-2 h-2 bg-stone-300 rounded-full" />
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="w-2 h-2 bg-stone-300 rounded-full" />
        </div>
        <div className="w-[80%] max-sm:w-[100%] flex-col justify-center items-center gap-14 flex">
          <div className="flex-col justify-start items-center gap-8 flex">
            <div className="w-[80%] max-sm:text-lg max-sm:w-[100%] text-center text-zinc-800 text-3xl font-bold leading-10">
              What got you interested in Upview?
            </div>
            <div className="self-stretch text-center text-gray-600 text-sm font-normal">
              You can Choose Multiple
            </div>
          </div>
          <div className="w-[100%]  flex-col justify-between items-center gap-14 flex">
            <div className="w-[100%] max-sm:w-[100%] max-lg:w-[100%] max-md:w-[100%] flex-wrap justify-start items-start gap-4 flex">
              <div className="w-[100%]  flex gap-4 flex-wrap justify-center">
                <div
                  className={`w-44 h-52 p-4 max-sm:w-[40%] max-lg:w-[40%] ${
                    seo ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOnBoardInterestedData(0));
                    setSeo(!seo);
                  }}
                >
                  <img className="w-28 h-28" src={Seo} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    SEO
                  </div>
                </div>
                <div
                  className={`w-44 h-52 p-4  max-sm:w-[40%] max-lg:w-[40%]   ${
                    thumbnail ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOnBoardInterestedData(1));
                    setThumbaail(!thumbnail);
                  }}
                >
                  <img className="w-28 h-28" src={Thumbnail} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Thumbnails
                  </div>
                </div>
                <div
                  className={`w-44 h-52 p-4 max-sm:w-[40%] max-lg:w-[40%]   ${
                    collaboration ? `bg-[aliceblue]` : `bg-white`
                  }  rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOnBoardInterestedData(2));
                    setCollaboration(!collaboration);
                  }}
                >
                  <img className="w-28 h-28" src={Collaboration} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Collaboration
                  </div>
                </div>

                {/*  */}
                <div
                  className={`w-44 h-52 p-4 max-sm:w-[40%]  max-lg:w-[40%]  ${
                    contentIdea ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOnBoardInterestedData(3));
                    setContentIdea(!contentIdea);
                  }}
                >
                  <img className="w-28 h-28" src={ContentIdea} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Content Ideas
                  </div>
                </div>
                {/*  */}
                <div
                  className={`w-44 h-52 p-4 max-sm:w-[40%] max-lg:w-[40%]   ${
                    education ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOnBoardInterestedData(4));
                    setEducation(!education);
                  }}
                >
                  <img className="w-28 h-28" src={Education} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Education
                  </div>
                </div>
                {/*  */}
                <div
                  className={`w-44 h-52 p-4 max-sm:w-[40%] max-lg:w-[40%]   ${
                    other ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(onBoardState("interested_others"));
                    dispatch(addOnBoardInterestedData(5));
                    setOthers(!other);
                  }}
                >
                  <img className="w-28 h-28" src={Others} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Others
                  </div>
                </div>
                {/*  */}
              </div>

              {/* <div className="w-[100%] flex gap-4">
                <div
                  className={`w-44 h-52 p-4 ${
                    contentIdea ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOnBoardInterestedData("content-idea"));
                    setContentIdea(!contentIdea);
                  }}
                >
                  <img className="w-28 h-28" src={ContentIdea} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Content Ideas
                  </div>
                </div>
                <div
                  className={`w-44 h-52 p-4 ${
                    education ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(addOnBoardInterestedData("education"));
                    setEducation(!education);
                  }}
                >
                  <img className="w-28 h-28" src={Education} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Education
                  </div>
                </div>
                <div
                  className={`w-44 h-52 p-4 ${
                    other ? `bg-[aliceblue]` : `bg-white`
                  } rounded-2xl border border-black border-opacity-10 flex-col justify-start items-center gap-4 flex cursor-pointer`}
                  onClick={() => {
                    dispatch(onBoardState("interested_others"));
                    setOthers(!other);
                  }}
                >
                  <img className="w-28 h-28" src={Others} />
                  <div className="text-center text-zinc-800 text-base font-semibold">
                    Others
                  </div>
                </div>
              </div> */}
            </div>
            <div className="w-[100%] max-sm:w-[100%] justify-between items-start gap-4 flex">
              <div
                className="px-8 py-3.5 bg-black bg-opacity-10 rounded-3xl justify-center items-center gap-2 flex cursor-pointer"
                onClick={() =>
                  dispatch(
                    onBoardState(
                      onboard_referral_data ? "referral" : "hear_about"
                    )
                  )
                }
              >
                <div className="w-5 h-5 relative">
                  <img src={ArrowRight} />
                </div>
                <div className="text-zinc-800 text-base font-semibold leading-normal">
                  Back
                </div>
              </div>
              <div
                className={`px-8 py-3.5 bg-violet-600 rounded-3xl justify-center items-center gap-2 flex  ${
                  seo ||
                  education ||
                  contentIdea ||
                  collaboration ||
                  thumbnail ||
                  other
                    ? `cursor-pointer`
                    : `cursor-not-allowed`
                }`}
                onClick={() => NextStep()}
              >
                <div className="text-white text-base font-semibold leading-normal">
                  Next
                </div>
                <div className="w-5 h-5 relative">
                  <img src={ArrowLeft} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interested;
