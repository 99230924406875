import React, { useEffect, useState } from "react";
import User2 from "../../../assets/images/user-2.svg";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import Logout from "../../../assets/images/log-out.png";
import SettingModal from "../../contents/SettingModal";
import { useSelector } from "react-redux";
import "./header.scss";

export const Header = ({ title, remainingCount }) => {
  const [menu, setMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const [bgLogout, setBgLogout] = useState(false);
  const [bgCollab, setBgCollab] = useState(false);

  const [userData, setUserData] = useState();
  const [youtubeData, setUserYoutubeData] = useState();
  const navigate = useNavigate();

  const currentPath = window.location.pathname;
  const suggestionsRegex = /\/suggestions\/.*/;
  const containsSuggestions = suggestionsRegex.test(currentPath);

  const { collab_status } = useSelector((state) => state.userSlice);

  const onClickImage = () => {
    setMenu(!menu);
  };

  const logout = () => {
    navigate("/");
    localStorage.removeItem("token");
    localStorage.removeItem("user-data");
    window.location.reload();
  };

  const accountSetting = () => {
    const url = "https://studio.youtube.com/channel/UCEsAG-_-1n9anxCl_ns_xPg";
    // const url = "https://www.youtube.com/";
    window.open(url, "_blank");
  };

  const youtubeChannel = () => {
    const url = "https://www.youtube.com/channel/UCEsAG-_-1n9anxCl_ns_xPg";
    // const url = "https://www.youtube.com/";
    window.open(url, "_blank");
  };

  // ------------------------------------------ tailwind css code ------------------------------------
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user-data")));
    setUserYoutubeData(JSON.parse(localStorage.getItem("youtube-data")));
  }, []);

  const onBgLogoutEnter = () => {
    setBgLogout(true);
  };

  const onBgLogoutLeave = () => {
    setBgLogout(false);
  };

  const onBgCollabEnter = () => {
    setBgCollab(true);
  };

  const onBgCollabLeave = () => {
    setBgCollab(false);
  };

  return (
    <div className="bg-[#ffffff] border-solid border-[rgba(0,0,0,0.10)] border-b w-full px-8 font-semibold h-[82px] relative flex items-center justify-between">
      <h1 className="text-md">{title ? title : "Collaboration"}</h1>
      <div className="flex items-center gap-x-[12px]">
        {/* {containsSuggestions && remainingCount !== undefined ? (
          <div className="remaining-content-ideas">
            <img src={DocumentIcon} alt="document-icon" />
            {remainingCount} Content Generation left for today
          </div>
        ) : (
          ""
        )} */}
        <div className="relative">
          {/* ------------------------------------- tailwind menu --------------------------------- */}
          <Menu as="div" className="relative inline-block text-left">
            <div className="w-[100%]">
              <Menu.Button className=" inline-flex w-full justify-start gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900">
                <div className={`flex items-center justify-start gap-x-4 `}>
                  <img
                    src={
                      userData?.channel?.image_url
                        ? userData?.channel?.image_url
                        : youtubeData?.image_url
                        ? youtubeData?.image_url
                        : User2
                    }
                    className="w-10 h-10 rounded-full  cursor-pointer object-cover"
                    alt="profile"
                  />
                </div>
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className=" absolute right-0 z-10  divide-y divide-gray-200 origin-top-right rounded-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-80">
                <div className="py-0">
                  {/*  */}
                  <Menu.Item className=" inline-flex w-full justify-start gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900">
                    <div
                      className={`flex items-center justify-start gap-x-4 mb-0 pb-5 border-b border-black border-opacity-10 `}
                    >
                      <img
                        src={
                          userData?.channel?.image_url
                            ? userData?.channel?.image_url
                            : youtubeData?.image_url
                            ? youtubeData?.image_url
                            : User2
                        }
                        className="w-10 h-10 rounded-full  cursor-pointer object-cover"
                        alt="profile"
                      />
                      {userData?.first_name && (
                        <div className="flex flex-col  items-start gap-y-1 ">
                          <p className="text-zinc-800 text-lg font-semibold">
                            {userData?.channel?.title || youtubeData?.title}
                          </p>
                          <p className="text-gray-600 text-base font-normal">
                            {userData?.email}
                          </p>
                        </div>
                      )}
                    </div>
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <button
                        // href="#"
                        className={classNames(
                          active
                            ? " bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          " px-4 py-2 text-sm  flex items-center cursor-pointer w-full"
                        )}
                        onClick={() => setOpen(!open)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 mr-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>

                        <label htmlFor="">Settings</label>
                      </button>
                    )}
                  </Menu.Item>
                </div>

                <div
                  onMouseEnter={onBgLogoutEnter}
                  onMouseLeave={onBgLogoutLeave}
                  className={`py-1 ${
                    bgLogout ? "bg-gray-100 rounded-b-2xl" : ""
                  }`}
                >
                  <Menu.Item onClick={logout}>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active ? "bg-gray-100 text-gray-900" : "text-red-400",
                          " px-4 py-2 text-sm cursor-pointer flex items-center rounded-b-xl"
                        )}
                      >
                        <img alt="logout" src={Logout} className="mr-5" />
                        Logout
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <SettingModal modelopen={open} setModalOpen={setOpen} />
        </div>
      </div>
    </div>
  );
};
