import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import thankyou from "../../assets/images/thankyou.png";

export default function ThankyouModal({ modelopen, setModalOpen }) {
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={modelopen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={() => setModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-30 overflow-y-auto ">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-[95%] lg:w-[30%]  p-2 relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8  ">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-[100%]">
                    <div className="sm:flex sm:items-start w-[100%]">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-[100%]">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        ></Dialog.Title>
                        <div className=" p-2 main w-[100%] flex flex-col justify-center items-center gap-8">
                          <img src={thankyou} alt="" className="w-56 h-40" />
                          <h1 className="text-zinc-800 text-[40px] font-bold">
                            Thank you!!
                          </h1>
                          <p className="text-center text-gray-600 text-base font-normal">
                            We'll get in touch with you soon regarding the
                            reward.
                          </p>

                          {/* <button
                            className="bg-violet-600 text-white rounded-3xl border border-violet-600 h-10   w-28 text-xs lg:text-base font-semibold leading-normal "
                           
                          >
                            Okay
                          </button> */}
                          <div className="w-44 h-14 px-6 py-4 bg-violet-600 rounded-full justify-center items-center gap-2.5 inline-flex cursor-pointer"  onClick={() => setModalOpen(false)}>
                              <div className="text-white text-base font-semibold leading-normal">Okay</div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
