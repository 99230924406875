const postHogEvents = {
  signUp: "USER_SIGNED_UP",
  signIn: "USER_SIGNED_IN",
  signUpFailure: "USER_SIGN_UP_FAILED",
  buttonClicked: "BUTTON_CLICKED",
  aiContentGenerationButtonClicked: "AI_CONTENT_GENERATION_BUTTON_CLICKED",
  keywordContentGenerationClicked: "KEYWORD_CONTENT_GENERATION_CLICKED",
  aiIdeasSave: "AI_IDEAS_SAVE",
  aiIdeasDisplay: "AI_IDEAS_DISPLAY",
  onboarding: "ONBOARDING",
  aiIdeasResponseTime: "AI_IDEAS_RESPONSE_TIME",
  saveCollaborators: "SAVE_COLLABORATORS",
  saveCollaboratorsResponseTime: "SAVE_COLLABORATORS_RESPONSE_TIME",
  collabSearch: "COLLAB_SEARCH"
};

export default postHogEvents;
