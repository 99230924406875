import React from "react";
import Sidebar from "../components/common/Sidebar";

const Thumbnail = () => {
  return (
    <>
      <Sidebar layout={<div>Thumbnail page</div>} />
    </>
  );
};

export default Thumbnail;
