import { createSlice } from "@reduxjs/toolkit";

const SortSlice = createSlice({
  name: "sort",
  initialState: {
    activeState: "myChannel",
    activeDay: 7,
    otheractiveDay: 7,
  },

  reducers: {
    AddActiveState(state, action) {
      state.activeState = action.payload;
    },
    AddActiveDay(state, action) {
      state.activeDay = action.payload;
    },
    AddotherActiveDay(state, action) {
      state.otheractiveDay = action.payload;
    },
  },
});
export const { AddActiveState, AddActiveDay, AddotherActiveDay } =
  SortSlice.actions;

export default SortSlice.reducer;
