import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Rocket from "../../assets/images/rocket.png";
import MainLogo from "../../assets/images/mainlogo.svg";
import Home from "../../assets/images/Home.png";
import StarSidebar from "../../assets/images/Star.png";
import Collaboration from "../../assets/images/Collaborator.png";
import FeedbackModal from "../contents/FeedbackModal";
import "./Sidebar.css";
import RatingModal from "../feedback/RatingModal";
import { usePostHog } from "posthog-js/react";
import { getfeedbackQuestionList } from "../../api/api";
import RewardsModal from "../feedback/RewardsModal";
import ThankyouModal from "../feedback/ThankyouModal";
import ConfirmCancelRewardsModal from "../feedback/ConfirmCancelRewardsModal";

import Trendingup from "../../assets/images/trending-up.png";
import postHogEvents from "../../constants/events";

const Sidebar = ({ layout }) => {
  const [mSidebar, setMSidebar] = useState(false);
  const [overview, setOverview] = useState(false);
  const [suggestions, setSuggestions] = useState(false);
  const [home, setHome] = useState(false);
  const [collaboration, setCollaboration] = useState(false);
  const [thumbnail, setThumbnail] = useState(false);
  const [hook, setHook] = useState(false);
  const [feedbackModal, setfeedbackModal] = useState(false);
  const [trending, setTrending] = useState(false);
  const [ratingModelopen, setRatingModalOpen] = useState(false);
  const [ratingModalState, setRatingModalState] = useState(1);
  const [questionList, setQustionList] = useState();
  const [ratingModal, setRaatingModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [thankyou_model, setThankyouModal] = useState(false);
  const [cleaarData, setClearData] = useState(false);
  const posthog = usePostHog();
  const [selectedRating, setSelectedRating] = useState("");

  const userData = JSON.parse(localStorage.getItem("user-data"));

  const sidebarData = [
    // { title: "overview", imageSrc: Chart, hookValue: overview },
    { title: "home", imageSrc: Home, hookValue: home },
    { title: "suggestions", imageSrc: StarSidebar, hookValue: suggestions },
    {
      title: "collaboration",
      imageSrc: Collaboration,
      hookValue: collaboration,
    },
    { title: "trending", imageSrc: Trendingup, hookValue: trending },
    // { title: "thumbnail", imageSrc: Image, hookValue: thumbnail },
    // { title: "hook", imageSrc: Show, hookValue: hook },
  ];
  const TitleData = [
    {
      id: 1,
      title: "How do you the overall experience with the product?",
      subTitle:
        "Rate your experience on a scale of 1-5. 5 Being the best experience.",
      ratingValue: 0,
    },
    {
      id: 2,
      title: "How helpful do you find the suggestions?",
      subTitle: "Rate your experience on a scale of 1-5. 5 Being most helpful.",
      ratingValue: 0,
    },
    {
      id: 3,
      title: "How do you like the AI content ideas?",
      subTitle:
        "Rate your experience on a scale of 1-5. 5 Being best experience.",
      ratingValue: 0,
    },
    {
      id: 4,
      title: "How would you rate the collaboration features?",
      subTitle:
        "Rate your experience on a scale of 1-5. 5 Being best experience.",
      ratingValue: 0,
    },
    {
      id: 5,
      title: "How would you rate the ‘Trending in Niche’ feature?",
      subTitle:
        "Rate your experience on a scale of 1-5. 5 Being best experience.",
      ratingValue: 0,
    },
    {
      id: 6,
      title: "How likely are you to recommend our product to a fellow creator?",
      subTitle:
        "Rate your experience on a scale of 1-5. 5 being most likely to recommend.",
      ratingValue: 0,
    },
  ];

  useEffect(() => {
    if (window.location.pathname === "/overview") {
      setOverview(true);
    }
    if (
      window.location.pathname === "/suggestions" ||
      window.location.pathname === "/suggestions/generate" ||
      window.location.pathname === "/suggestions/explore"
    ) {
      setSuggestions(true);
    }
    if (window.location.pathname === "/collaboration") {
      setCollaboration(true);
    }
    if (window.location.pathname === "/thumbnail") {
      setThumbnail(true);
    }
    if (window.location.pathname === "/hook") {
      setHook(true);
    }
    if (window.location.pathname === "/home") {
      setHome(true);
    }
    if (window.location.pathname === "/trending") {
      setTrending(true);
    }
  }, []);

  const sidebar = () => {
    setMSidebar(!mSidebar);
  };
  const HandleClick = (title) => {
    posthog.identify(userData?.email);
    posthog.capture(`${title}_${postHogEvents.buttonClicked}`);
  };

  const getFeedbackQueestions = async () => {
    await getfeedbackQuestionList()
      .then((res) => {
        setQustionList(res.data.data);
      })
      .catch((err) => {
        console.log("err feeedack", err);
      });
  };

  useEffect(() => {
    getFeedbackQueestions();
  }, []);

  return (
    <div>
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        onClick={sidebar}
        className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="default-sidebar"
        className={`fixed top-0 left-0 z-40 transition-transform ${
          !mSidebar
            ? "-translate-x-full lg:translate-x-0"
            : "translate-x-0 lg:-translate-x-full"
        }`}
        aria-label="Sidebar"
      >
        {/* <div className="relative px-3 py-4 overflow-y-auto w-[220px] h-screen bg-slate-950"> */}
        <div className="relative flex flex-col items-center px-3 py-4 overflow-y-auto w-[220px] h-screen bg-slate-950">
          <div
            onClick={sidebar}
            className="absolute right-4 top-7 text-white border-2 inline-flex lg:hidden items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium rounded-full"
          >
            <span className="-mt-[7px] text-2xl">x</span>
          </div>
          <ul className="space-y-2 flex flex-col items-center gap-2">
            <Link to="/home">
              <img
                src={MainLogo}
                alt="logo"
                className="mb-[32px] w-[70%] lg:w-[90%] mt-2 lg:mt-0"
                style={{ cursor: "pointer" }}
              />
            </Link>
            {sidebarData.map((data, idx) => {
              return (
                <li key={idx}>
                  <Link
                    to={"/" + data?.title}
                    className={`flex items-center w-[187px] h-12 pl-3 pr-4 py-3  justify-start gap-3  ${
                      data?.hookValue && "bg-violet-600 rounded-xl"
                    }`}
                    onClick={() => HandleClick(data?.title)}
                  >
                    <img
                      src={data?.imageSrc}
                      alt="chart-icon"
                      style={{ color: "" }}
                    />
                    <span className="text-white text-base font-medium leading-normal capitalize">
                      {data?.title}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
          <div className="absolute bottom-10 left-[3px] px-4 py-4 sidebar-padding">
            <div
              className="w-44 h-[200px]
              } px-[15px] pt-20 pb-6 bg-white bg-opacity-5 rounded-xl flex-col justify-start items-center gap-[26px] inline-flex"
            >
              <div className="w-[146px] text-center text-white text-base font-medium">
                Tell us how we can improve
              </div>
              <button
                className="px-5 py-2 bg-white rounded-[39px] justify-center items-center gap-2 inline-flex"
                onClick={() => setRatingModalOpen(!ratingModelopen)}
              >
                <div className="text-slate-950 text-sm font-semibold leading-normal">
                  Share Feedback
                </div>
              </button>
              <img
                className="w-[87px] h-[87px] left-[60px] top-[-10px] absolute origin-top-left"
                src={Rocket}
                alt="aircraft"
              />
            </div>
            {/* 
            <div className="w-48 h-8 p-2 rounded-full border border-white border-opacity-20 justify-between items-center gap-2.5 inline-flex">
  <div className="grow shrink basis-0 text-center text-white text-xs font-normal">Tell us how we can improve</div>
</div> */}

            {/* <div
              className="rounded-full p-2 border border-white border-opacity-20 text-center text-white text-opacity-70 text-xs font-normal mt-5 cursor-pointer"
              onClick={() => setRatingModalOpen(!ratingModelopen)}
              // onClick={() => setfeedbackModal(!feedbackModal)}
            >
              Tell us how we can improve
            </div> */}
          </div>
        </div>
      </aside>

      <div className="lg:ml-[220px]">
        <div className="">{layout}</div>
      </div>

      <></>

      {/* <FeedbackModal
        modelopen={feedbackModal}
        setModalOpen={setfeedbackModal}
      />
      {/* {
        questionList && ( */}

      <RatingModal
        modelopen={ratingModal}
        setModalOpen={setRaatingModal}
        titleData={TitleData}
        setRatingModalState={setRatingModalState}
        ratingModalState={ratingModalState}
        questionList={questionList}
        cancelModal={cancelModal}
        setCancelModal={setCancelModal}
        selectedRating={selectedRating}
        setSelectedRating={setSelectedRating}
        setThankyouModal={setThankyouModal}
        cleaarData={cleaarData}
        setClearData={setClearData}
      />
      {/* )
      } */}
      <RewardsModal
        modelopen={ratingModelopen}
        setModalOpen={setRatingModalOpen}
        setRaatingModal={setRaatingModal}
      />

      <ConfirmCancelRewardsModal
        modelopen={cancelModal}
        setModalOpen={setCancelModal}
        setRaatingModal={setRaatingModal}
        selectedRating={selectedRating}
        setSelectedRating={setSelectedRating}
        setRatingModalState={setRatingModalState}
        setClearData={setClearData}
        cleaarData={cleaarData}
      />

      <ThankyouModal
        modelopen={thankyou_model}
        setModalOpen={setThankyouModal}
      />
    </div>
  );
};

export default Sidebar;
