import React, { useState } from "react";
import { formatBigNumbers } from "../../../utils/utility-functions";
import error from "../../../assets/images/error.png";

const VideoCard = ({ videoData }) => {
  const { title_r, view_rvid, image_url_r, video_url_r, video_url_rvid } =
    videoData ?? {};
  if (videoData === "undefined") {
    return (
      <>
        <img src={error} alt="error" className="" />
        <p className="text-zinc-800 text-base">Oops</p>
        <p className="text-gray-600 text-sm">
          We couldn’t get this section for you now! Try Reloading the tab.
        </p>
      </>
    );
  } else {
    return (
      <a
        className="w-full  p-4  bg-gray-100 rounded-lg justify-start items-center gap-3 flex h-24"
        href={video_url_r || video_url_rvid}
        target="_blank"
        rel="noreferrer"
      >
        <div className="w-full  justify-start  gap-4 flex items-center">
          <img
            className="w-[102px] h-[64px] rounded"
            src={image_url_r}
            alt={title_r}
          />

          <div className=" flex-col justify-start items-start gap-2 inline-flex">
            <h1 className="self-stretch text-neutral-800 text-sm font-semibold">
              {title_r}
            </h1>
            <p className="self-stretch text-gray-500 text-xs font-normal">
              {view_rvid && formatBigNumbers(view_rvid)} Views
            </p>
          </div>
        </div>
      </a>
    );
  }
};
const Videos = ({ mostPopularRelatedVideos, mostRecentRelatedVideo }) => {
  const [activeTab, setActiveTab] = useState("popular");

  const activeVideos =
    activeTab === "popular" ? mostPopularRelatedVideos : mostRecentRelatedVideo;

  return (
    <div className=" w-full p-4 lg:p-6 bg-white rounded-xl flex-col justify-start items-start gap-6 inline-flex ">
      <div className="flex justify-between items-center w-full">
        <h1 className=" text-gray-600 text-base font-semibold leading-tight">
          Video Uploads
        </h1>
        <h1 className="text-gray-600 text-xs  leading-tight">Last 90 Days</h1>
      </div>

      <div className="flex gap-4">
        <button
          className={`w-20 h-7 text-sm border-[1px] font-medium rounded-2xl ${
            activeTab === "popular"
              ? "bg-[#E9E4F6] text-black font-semibold"
              : "text-slate-500"
          }`}
          onClick={() => setActiveTab("popular")}
        >
          Popular
        </button>
        <button
          className={`w-20 h-7 text-sm border-[1px]  rounded-2xl font-medium ${
            activeTab === "recent"
              ? "bg-[#E9E4F6] text-black font-semibold"
              : "text-slate-500"
          }`}
          onClick={() => setActiveTab("recent")}
        >
          Recent
        </button>
      </div>
      <div className="w-full self-stretch  flex-col justify-start items-start gap-3 flex">
        {activeVideos.length === 0 ? (
          <div className="flex flex-col w-full h-full mt-10 justify-center items-center">
            <img src={error} alt="error" className="" />
            <p className="text-zinc-800 text-base">Oops</p>
            <p className="text-gray-600 text-sm">
              There are no videos available for this tab.
            </p>
          </div>
        ) : (
          activeVideos.map((video) => (
            <VideoCard key={video.video_url_r} videoData={video} />
          ))
        )}
      </div>
    </div>
  );
};

export default Videos;
