import { formatBigNumbers } from "../../utils/utility-functions";

const TrendingChannelNicheList = ({ channel }) => {
  if (channel.type === "channel") {
    return (
      <a
        className=" p-5 bg-slate-100 rounded-2xl flex-col justify-start items-center gap-8 w-[47%] lg:gap-12 flex lg:w-[250px] "
        href={channel.url}
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="channelimage"
          src={channel.thumbnail_url}
          className="rounded-full w-32 h-32"
        />
        <div className="flex-col justify-center items-center gap-2  w-full text-center flex">
          <h1 className="text-zinc-800 text-sm font-bold h-12 flex justify-center items-center line-height-[0.9rem]">
            {channel.title}
          </h1>
          <div className="text-zinc-800 text-sm font-normal">
            {channel.channel_subscribers &&
              formatBigNumbers(channel.channel_subscribers)}
            <span className="ml-2">Subscribers</span>
          </div>
        </div>
      </a>
    );
  } else {
    return null;
  }
};

export default TrendingChannelNicheList;
