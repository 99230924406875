import { Header } from "../common/header/Header";
import Cuate from "../../assets/images/scripting.png";
import Pana from "../../assets/images/collaboration.png";
import User2 from "../../assets/images/user-2.svg";
import TrendingImage from "../../assets/images/seo.png";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlineMinus } from "react-icons/hi";
import { createPortal } from "react-dom";
import { formatBigNumbers } from "../../utils/utility-functions";
import PaymentSuccess from "../payments-page/success";
import HomeCard from "./HomeCard";
import "./style.css";

const HomeMiddle = () => {
  const [userData, setUserData] = useState();
  const [youtubeData, setUserYoutubeData] = useState();
  const location = useLocation();

  const cardsData = [
    {
      heading: "Video Suggestions",
      description:
        "Know your audience preferences and generate ideas that help you grow faster.",
      buttonname: "Get Suggestions",
      image: Cuate,
      redirect: "/suggestions",
    },
    {
      heading: "Collaborate with Creators",
      description:
        "Get to know creators in your niche with whom you can collaborate and grow.",
      buttonname: "Find Creators",
      image: Pana,
      redirect: "/collaboration",
    },
    {
      heading: "Trending in Niche",
      description:
        "Get the latest updates about what are the trending channels and videos on YouTube!!!",
      buttonname: "Explore Now",
      image: TrendingImage,
      redirect: "/trending",
    },
  ];

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user-data")));
    setUserYoutubeData(JSON.parse(localStorage.getItem("youtube-data")));
  }, []);

  return (
    <>
      <Header title={"Home"} />
      <div className=" bg-blue-50 w-[100%] h-[auto] max-sm:h-[auto] flex-col justify-start items-start gap-8 flex">
        <div className={`w-[100%] header p-5 `}>
          <div className="w-[100%] flex-col justify-start items-start gap-8 flex pb-4">
            <div className="text-white text-opacity-70 text-lg font-bold">
              Welcome to Upview
            </div>
            <div className="w-[100%] justify-center items-center gap-5 flex">
              <img
                className="w-16 h-16 rounded-full "
                src={
                  userData?.channel?.image_url
                    ? userData?.channel?.image_url
                    : youtubeData?.image_url
                    ? youtubeData?.image_url
                    : User2
                }
                alt=""
              />
              <div className=" w-[100%] flex-col justify-center items-start  flex">
                <div className="text-white text-2xl font-bold leading-loose">
                  {userData?.channel?.title || youtubeData?.title}
                </div>
                <div className="w-[100%] justify-start items-center gap-1 flex">
                  <div className=" text-white text-opacity-70 text-base font-normal">
                    {userData?.channel?.total_subscribers
                      ? formatBigNumbers(userData?.channel?.total_subscribers)
                      : youtubeData?.subcribers
                      ? formatBigNumbers(youtubeData?.subcribers)
                      : ""}{" "}
                    Subscribers
                  </div>
                  <div className=" rotate-90 text-white text-opacity-70 ">
                    <HiOutlineMinus size={"25px"} />
                  </div>
                  <div className="  text-white text-opacity-70 text-base font-normal">
                    {userData?.channel?.total_views
                      ? formatBigNumbers(userData?.channel?.total_views)
                      : youtubeData?.views
                      ? formatBigNumbers(youtubeData?.views)
                      : ""}{" "}
                    Views
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full flex flex-col gap-6 p-8 pt-1"
          style={{ backgroundColor: "#F2F4FA" }}
        >
          <div className="text-gray-600 text-2xl font-bold leading-loose">
            Take your Channel to next level with Upview
          </div>

          {cardsData.map((card, index) => (
            <HomeCard
              key={index} // Make sure to use a unique key for each card
              heading={card.heading}
              description={card.description}
              buttonname={card.buttonname}
              image={card.image}
              redirect={card.redirect}
            />
          ))}
        </div>
      </div>
      {location.pathname === "/payment-success" &&
        createPortal(<PaymentSuccess />, document.body)}
    </>
  );
};

export default HomeMiddle;
