import React from "react";
import SignupComponent from "../components/auth/SignupComponent";
import SideComponent from "../components/auth/SideComponent";
import Logo from "../components/auth/Logo";

const SignupForm = () => {
  return (
    <div className="bg-violet-600">
      <div className="flex justify-between items-center">
        <div className="w-[100vw] xl:w-[50vw] bg-white relative h-[100%]">
          <div className="absolute top-4 left-4">
            <Logo />
          </div>
          <SignupComponent />
        </div>
        <div className="w-[50vw] hidden xl:flex items-center justify-center h-[100%]">
          <SideComponent />
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
