import React from "react";
import LogoImage from "../../assets/images/logo.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Logo = () => {
  const location = useLocation();
  const navigate = useNavigate();


  const Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user-data");
    navigate("/");
  };

  return (
    <>
      <div className="flex justify-between w-[100%] items-center">
        <img src={LogoImage} alt="logo" />
        {location.pathname === "/account-registered" && (
          <div
            className="w-20 h-5 justify-start items-center gap-3 inline-flex cursor-pointer"
            onClick={() => Logout()}
          >
            <div className="w-4 h-4 relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M6 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V3.83333C2 3.47971 2.14048 3.14057 2.39052 2.89052C2.64057 2.64048 2.97971 2.5 3.33333 2.5H6"
                  stroke="#5C566B"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.667 11.8337L14.0003 8.50033L10.667 5.16699"
                  stroke="#5C566B"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14 8.5H6"
                  stroke="#5C566B"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="text-gray-600 text-base font-normal">Logout</div>
          </div>
        )}
      </div>
    </>
  );
};

export default Logo;
