import React, { useState, useContext, useEffect } from "react";
import { FilteredDataContext } from "../../pages/Suggestions";
import FilterSection from "../suggestions/filtersSectionSuggestions";
import { AiFillYoutube } from "react-icons/ai";
import { WiStars } from "react-icons/wi";
import ytShorts from "../../assets/images/ytShorts.png";
import { useSelector } from "react-redux";
import { usePostHog } from "posthog-js/react";

const ContentSelect = ({ data }) => {
  const [active, setActive] = useState("video");
  const {
    showVideoSuggestion,
    setVideosSuggestion,
    myChannelData,
    setMyChannelResponseData,
  } = useContext(FilteredDataContext);
  const posthog = usePostHog();
  const suggestion_mychannel_data = useSelector(
    (state) => state.suggestion_data.suggestion_video_content_mychannel
  );
  const suggestion_others_data = useSelector(
    (state) => state.suggestion_data.suggestion_video_content_others
  );
  const userData = JSON.parse(localStorage.getItem("user-data"));

  useEffect(() => {
    if (active === "video") {
      setVideosSuggestion(true);
    } else {
      setVideosSuggestion(false);
    }
  });

  const logEvent = (title, action) => {
    posthog.identify(userData?.email);
    posthog.capture(title, { action: action });
  };

  return (
    <>
      <div className="flex flex-wrap justify-between items-center mb-4 mt-5">
        <div className=" border-opacity-20 justify-start items-start flex-wrap gap-10 flex mb-0">
          {data.map((i, idx) => (
            <div
              className={`py-4 ${
                active === i.value ? `border-b-2 border-indigo-600` : ``
              }  justify-start items-center gap-4 flex cursor-pointer`}
              onClick={() => {
                setActive(i.value);
                logEvent(i.amplitudeTitle, i.amplitudeAction);
              }}
              key={idx}
            >
              {i.value === "video" && <AiFillYoutube color="red" size="30px" />}
              {i.value !== "video" && <img alt="shorts" src={ytShorts} />}

              <div
                className={`${
                  active === i.value ? `text-violet-600` : `text-gray-500`
                } text-base font-medium leading-snug`}
              >
                {i.name}
              </div>
            </div>
          ))}
        </div>
        <FilterSection active={active} />
        <hr className="w-[100%]" />
      </div>
      <div className=" p-4 bg-violet-100 rounded-2xl border justify-start items-center gap-4 flex">
        <div className=" h-8 pl-2.5 pr-4 py-1.5 rounded-2xl border border-violet-600 justify-center items-center gap-1.5 inline-flex">
          <div className="w-4 h-4 relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M6.542 10.6027L6 12.5L5.458 10.6027C5.31792 10.1126 5.05527 9.66625 4.69484 9.30583C4.33441 8.9454 3.88809 8.68275 3.398 8.54267L1.5 8L3.39733 7.458C3.88743 7.31792 4.33375 7.05527 4.69417 6.69484C5.0546 6.33441 5.31725 5.88809 5.45733 5.398L6 3.5L6.542 5.39733C6.68209 5.88743 6.94473 6.33375 7.30516 6.69417C7.66559 7.0546 8.11191 7.31725 8.602 7.45733L10.5 8L8.60267 8.542C8.11257 8.68209 7.66625 8.94473 7.30583 9.30516C6.9454 9.66559 6.68275 10.1119 6.54267 10.602L6.542 10.6027ZM12.1727 5.81L12 6.5L11.8273 5.81C11.7285 5.41439 11.524 5.05308 11.2357 4.76469C10.9475 4.47629 10.5862 4.27166 10.1907 4.17267L9.5 4L10.1907 3.82733C10.5862 3.72834 10.9475 3.52371 11.2357 3.23531C11.524 2.94692 11.7285 2.58561 11.8273 2.19L12 1.5L12.1727 2.19C12.2716 2.58569 12.4761 2.94706 12.7645 3.23546C13.0529 3.52386 13.4143 3.72844 13.81 3.82733L14.5 4L13.81 4.17267C13.4143 4.27156 13.0529 4.47614 12.7645 4.76454C12.4761 5.05294 12.2716 5.41431 12.1727 5.81ZM11.2627 13.7113L11 14.5L10.7373 13.7113C10.6637 13.4904 10.5396 13.2897 10.375 13.125C10.2103 12.9604 10.0096 12.8363 9.78867 12.7627L9 12.5L9.78867 12.2373C10.0096 12.1637 10.2103 12.0396 10.375 11.875C10.5396 11.7103 10.6637 11.5096 10.7373 11.2887L11 10.5L11.2627 11.2887C11.3363 11.5096 11.4604 11.7103 11.625 11.875C11.7897 12.0396 11.9904 12.1637 12.2113 12.2373L13 12.5L12.2113 12.7627C11.9904 12.8363 11.7897 12.9604 11.625 13.125C11.4604 13.2897 11.3363 13.4904 11.2627 13.7113Z"
                fill="#7948F6"
                stroke="#7948F6"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="text-violet-600 text-xs font-semibold leading-none">
            Upview Pro Tip
          </div>
        </div>
        <p className="text-neutral-800 text-xs font-semibold leading-tight">
          🚀 Your viewers thoroughly enjoyed these videos. Exceptional job!🎉 We
          suggest creating content along similar lines to expand your audience
          further.
        </p>
      </div>
    </>
  );
};

export default ContentSelect;
