import React from "react";
import ArrowRight from "../../assets/images/arrow-right.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addCollabStatusData,
  onBoardState,
} from "../../redux/store/slice/Onboarding";

const CollaborateWithOtherYoutuber = () => {
  const { onboard_interested_others_data, onboard_collab_status } = useSelector(
    (state) => state.Onboarding
  );
  console.log(onboard_collab_status)
  const dispatch = useDispatch();

  return (
    <div className="w-[50%] max-sm:w-[100%] md:w-[80%] lg:w-[60%] mt-2 flex justify-center">
      <div className="w-[100%]  max-sm:p-6 max-sm:w-[100%]  md:w-[100%] lg:w-[100%] p-10 bg-white rounded-3xl flex-col justify-start items-center gap-16 flex">
        <div className="w-56 justify-start items-center inline-flex">
          <div className="w-2 h-2 bg-green-600 rounded-full border" />
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="w-2 h-2 bg-green-600 rounded-full" />
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="w-2 h-2 bg-green-600 rounded-full" />
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="w-2 h-2 bg-stone-300 rounded-full" />
        </div>
        <div className="w-[100%] flex-col justify-between items-start gap-36 flex">
          <div className=" w-[100%] flex-col justify-start items-center gap-11 flex">
            <div className=" w-[100%] flex-col justify-start items-center gap-8 flex">
              <div className="max-sm:text-lg text-center text-zinc-800 text-3xl font-bold leading-10">
                Are you open to collaborate with other Youtubers?
              </div>
              <div className=" text-center text-gray-600 text-sm font-normal">
                Collaborating with other creators is a great way to grow your
                channel. Enabling collaboration in Upview will let other
                creators in Upview connect with you seamlessly.
              </div>
            </div>
            <div className="w-[100%]  flex-col justify-start items-start gap-4 flex">
              <div
                className={`w-[100%] px-4 py-8 bg-white rounded-3xl border border-black border-opacity-10 flex-col justify-center items-center gap-14 flex cursor-pointer ${
                  onboard_collab_status =="True" ? `bg-[#7fbef5]` : `bg-white`}`}
                onClick={() => {
                  dispatch(onBoardState("outsource"));
                  dispatch(addCollabStatusData("True"));
                }}
              >
                <div className="text-center text-gray-600 text-xl font-semibold leading-normal">
                  Yes
                </div>
              </div>
              <div
                className={`w-[100%] px-4 py-8 bg-white rounded-3xl border border-black border-opacity-10 flex-col justify-center items-center gap-14 flex cursor-pointer ${
                  onboard_collab_status =="False" ? `bg-[#7fbef5]` : `bg-white`}`}
                onClick={() => {
                  dispatch(onBoardState("outsource"));
                  dispatch(addCollabStatusData("False"));
                }}
              >
                <div className="text-center text-gray-600 text-xl font-semibold leading-normal">
                  Not Now
                </div>
              </div>
            </div>
          </div>
          <div
            className="px-8 py-3.5 bg-black bg-opacity-10 rounded-3xl justify-center items-center gap-2 flex cursor-pointer"
            onClick={() =>
              dispatch(
                onBoardState(
                  onboard_interested_others_data?.length > 0
                    ? "interested_others"
                    : "interested"
                )
              )
            }
          >
            <div className="w-5 h-5 relative">
              <img src={ArrowRight} />
            </div>
            <div className="text-zinc-800 text-base font-semibold leading-normal">
              Back
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollaborateWithOtherYoutuber;
