import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState, useEffect } from "react";
import { BsBookmarkFill, BsBookmark } from "react-icons/bs";
import { ReactComponent as DownIcon } from "../../assets/images/downicon.svg";
import { ReactComponent as UpIcon } from "../../assets/images/upicon.svg";
import { TiTick } from "react-icons/ti";
import copy from "../../assets/images/copy.png";
import { baseURL } from "../../utils/const";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import heroicons_sparkles from "../../assets/images/heroicons_sparkles.png";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { usePostHog } from "posthog-js/react";
import "react-toastify/dist/ReactToastify.css";
import postHogEvents from "../../constants/events";
import logger from "../../config/logger";
const url = baseURL + "/llm/savecontent";

const DropDown = ({
  data,
  index,
  copyTitle,
  copytags,
  copyDesc,
  setCopyTitle,
  setCopyTags,
  setCopyDesc,
  onClickCopyTitle,
  onClickCopyDescription,
  onClickCopyTags,
  contentIndex,
  keyword,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [color, setColor] = useState(false);
  const posthog = usePostHog();
  const [hashtag, setHasTagData] = useState();
  const [token, setToken] = useState("");
  const { id, title, description, tags, is_saved } = data;
  const dispatch = useDispatch();

  const saved_ideas_data = useSelector(
    (state) => state.suggestion_data.suggestion_saved_ideas_data
  );
  const userData = JSON.parse(localStorage.getItem("user-data"));

  useEffect(() => {
    if (index === 0) {
      setShowDropDown(true);
    }
  }, []);

  useEffect(() => {
    if (is_saved) {
      setColor(true);
    }
  }, []);

  const onClickDropDown = () => {
    setShowDropDown(!showDropDown);
    setCopyTitle(false);
    setCopyTags(false);
    setCopyDesc(false);
  };

  const postTheSavedIdeas = async () => {
    const postData = { content_id: id.toString() };
    posthog.identify(userData?.email);
    posthog.capture(`IDEAS_SAVE_${postHogEvents.buttonClicked}`);
    logger.log("Saving content idea");

    const start = Date.now();
    try {
      const response = await axios.post(url, postData, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const end = Date.now();
      logger.success(`Idea content saved in ${end - start}ms`);
      setColor(true);
      toast.success("Saved", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        closeButton: false,
      });

      posthog.identify(userData?.email);
      posthog.capture(`${postHogEvents.aiIdeasSave}_SUCCESS`);
    } catch (error) {
      const end = Date.now();
      logger.error(`Failed to save idea content after ${end - start}ms`, error);
      posthog.identify(userData?.email);
      posthog.capture(`${postHogEvents.aiIdeasSave}_FAILURE`);
      console.error("Error:", error);
    }
  };

  const onClickSaved = () => {
    const findIdeas = saved_ideas_data.find((each) => each.title === title);
    if (findIdeas === undefined) {
      postTheSavedIdeas();
    }
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  useEffect(() => {
    if (data?.tags) {
      if (data.tags.includes("#")) {
        setHasTagData(data?.tags.trim().split("#"));
      } else {
        setHasTagData(data?.tags.trim().split(","));
      }
    }
  }, [index]);

  return (
    <div className="w-[100%] p-6 bg-slate-100 rounded-2xl  flex-col justify-start items-start gap-5 inline-flex mt-4">
      <div className="w-[100%] flex justify-between items-center">
        <div className="justify-start items-start gap-4 flex">
          <div className="text-violet-600 text-opacity-75 text-[32px] font-bold">
            {index + 1}
          </div>
          <div className="text-neutral-800 text-xl font-bold leading-7  w-[93%] self-center">
            {data?.title.replace(/'/g, "")}
          </div>
          <div className="w-5 h-5 relative cursor-pointer mt-4">
            {showDropDown && (
              <CopyToClipboard text={data?.title.replace(/'/g, "")}>
                <button
                  type="button"
                  className="border-none bg-transparent outline-none"
                  onClick={() => onClickCopyTitle(index)}
                >
                  {contentIndex === index && copyTitle ? (
                    <TiTick color="green" size="25px" />
                  ) : (
                    <>
                      <img
                        src={copy}
                        alt="copy desc"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="copy"
                      />
                      <Tooltip id="my-tooltip" />
                    </>
                  )}
                </button>
              </CopyToClipboard>
            )}
          </div>
        </div>

        <div className="flex items-center gap-4">
          {color ? (
            <BsBookmarkFill color="purple" />
          ) : (
            <>
              <BsBookmark
                onClick={() => onClickSaved()}
                className="cursor-pointer"
                data-tooltip-id="my-tooltip"
                data-tooltip-content="save"
              />
              <Tooltip id="my-tooltip" />
            </>
          )}
          <button
            type="button"
            className="bg-transparent border-none w-[10px]"
            onClick={onClickDropDown}
          >
            {showDropDown ? <UpIcon /> : <DownIcon />}
          </button>
        </div>
      </div>
      {showDropDown && (
        <div className="w-[100%]">
          {/* <hr className="w-[100%] border border-black border-opacity-20" /> */}
          <hr
            style={{ width: "100%", border: "1px solid #000", opacity: 0.2 }}
          />
          {index === 0 && (
            <div className="h-9 pl-[13px] pr-[19px] py-2.5 bg-violet-600 bg-opacity-10 rounded-[26px] border border-violet-600 justify-center items-center gap-1.5 inline-flex mt-4">
              <img alt="heroic" src={heroicons_sparkles} />
              <p className="text-violet-600 text-sm font-semibold leading-none">
                Upview Recommended
              </p>
            </div>
          )}
          <div className="w-[100%] flex items-center justify-between mt-4">
            {/*  */}
            <div className=" justify-between items-start gap-4 flex">
              <div className="text-neutral-800 text-base font-normal leading-normal text-justify w-[95%]">
                {data?.description.split(":")[1]}
              </div>
              <div className="w-5 h-5 relative cursor-pointer">
                <CopyToClipboard text={data.description.split(":")[1]}>
                  <button
                    type="button"
                    className="border-none bg-transparent outline-none"
                    onClick={() => onClickCopyDescription(index)}
                  >
                    {contentIndex === index && copyDesc ? (
                      <TiTick color="green" size="25px" />
                    ) : (
                      <>
                        <img
                          src={copy}
                          alt="copy desc"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="copy"
                        />
                        <Tooltip id="my-tooltip" />
                      </>
                    )}
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="w-[100%] flex justify-between items-center  mt-4">
            <div className="w-[100%] justify-between items-center gap-6 flex">
              <div className="text-neutral-800 text-base font-bold">
                Use these Keywords
              </div>
              <div className="w-5 h-5 relative">
                <CopyToClipboard text={data.tags}>
                  <button
                    type="button"
                    className="border-none bg-transparent outline-none"
                    onClick={() => onClickCopyTags(index)}
                  >
                    {contentIndex === index && copytags ? (
                      <TiTick color="green" size="25px" />
                    ) : (
                      <>
                        <img
                          src={copy}
                          alt="copy desc"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="copy"
                        />
                        <Tooltip id="my-tooltip" />
                      </>
                    )}
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <ul className="w-[100%] flex flex-wrap gap-2 mt-4 ">
            {hashtag
              ?.filter((item) => item.trim() !== "")
              .map((each, idx) => (
                <li
                  key={idx}
                  className="h-9 px-5 py-1.5 bg-gray-200 rounded-[44px] justify-center items-center gap-2.5 inline-flex text-neutral-800 text-sm font-medium leading-normal"
                >
                  {each}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropDown;
