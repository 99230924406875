import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./app.routes";
import store from "./redux/store/index";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { PostHogProvider } from "posthog-js/react";
import { createPortal } from "react-dom";
import PaymentsPage from "./components/payments-page/payments-page";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

function App() {
  return (
    <Provider store={store}>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <Router>
          <ToastContainer />
          <Suspense>
            <AppRoutes />
            {/* {createPortal(<PaymentsPage />, document.body)} */}
          </Suspense>
        </Router>
      </PostHogProvider>
    </Provider>
  );
}

export default App;
