import React from "react";
import { Link } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";

const SuggestionTitle = ({ setSavedIdea }) => {
  return (
    <div className="w-[100%]  max-sm:px-3 max-sm:gap-1 px-4 pt-4 pb-6 bg-violet-600  items-center gap-2 flex flex-row ">
      <Link to={"/suggestions"}>
        <div className="w-6 h-6 relative mr-3 mt-1 sm:mt-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 12H5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 19L5 12L12 5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </Link>
      <div className="flex flex-row justify-between w-[100%] items-center mt-3  max-sm:mt-0">
        <div className=" text-white text-2xl font-bold leading-loose max-sm:text-sm max-sm:w-80">
          AI Generated Content Ideas for your Channel!!
        </div>

        <div className="mr-5 mt-3 h-7 justify-start items-center gap-3 inline-flex">
          <div
            className="pl-[5px] pr-[7px] py-1.5 bg-white bg-opacity-20 rounded-[38px] justify-start items-start gap-2.5 flex"
            onClick={() => setSavedIdea(true)}
          >
            <FiChevronLeft className="text-white" />
          </div>
          <div className="text-white text-sm font-medium">Saved Ideas</div>
        </div>
      </div>
    </div>
  );
};

export default SuggestionTitle;
