import { useContext } from "react";
import { FilteredDataContext } from "../../pages/Suggestions";
import { BiLike, BiDislike } from "react-icons/bi";
import { useSelector } from "react-redux";

const VideosGettingContentItem = (props) => {
  const { data, name } = props;

  const { viewsText } = useContext(FilteredDataContext);

  const { activeState, activeDay, otheractiveDay } = useSelector(
    (state) => state.SortSlice
  );

  const getTotalViews = () => {
    if (data.views_by_days < 1000) {
      return Math.floor(data.views_by_days) + " Views";
    } else if (data.views_by_days >= 1000 && data.views_by_days < 1000000) {
      return Math.floor(data.views_by_days / 1000) + "K Views";
    } else {
      return Math.floor(data.views_by_days / 1000000) + "M Views";
    }
  };

  const showLikes = () => {
    if (data.likes_by_days < 1000) {
      return Math.floor(data.likes_by_days);
    } else if (data.likes_by_days >= 1000 && data.likes_by_days < 1000000) {
      return Math.floor(data.likes_by_days / 1000) + "K";
    } else {
      return Math.floor(data.likes_by_days / 1000000) + "M";
    }
  };

  const showLikes2 = () => {
    if (data.like < 1000) {
      return Math.floor(data.like);
    } else if (data.like >= 1000 && data.like < 1000000) {
      return Math.floor(data.like / 1000) + "K";
    } else {
      return Math.floor(data.like / 1000000) + "M";
    }
  };

  const showDisLikes = () => {
    if (data.dislikes_by_days < 1000) {
      return Math.floor(data.dislikes_by_days);
    } else if (
      data.dislikes_by_days >= 1000 &&
      data.dislikes_by_days < 1000000
    ) {
      return Math.floor(data.dislikes_by_days / 1000) + "K";
    } else {
      return Math.floor(data.dislikes_by_days / 1000000) + "M";
    }
  };

  const showDislikes2 = () => {
    if (data.dislike < 1000) {
      return Math.floor(data.dislike);
    } else if (data.dislike >= 1000 && data.dislike < 1000000) {
      return Math.floor(data.dislike / 1000) + "K";
    } else {
      return Math.floor(data.dislike / 1000000) + "M";
    }
  };

  const onRedirectToVideoOnYoutube = () => {
    const youtubeVideoURL = `https://www.youtube.com/watch?v=${data.video_id}`;
    window.open(youtubeVideoURL, "_blank");
  };

  return (
    <li className="flex flex-col w-[100%] bg-slate-50 rounded-2xl border border-black border-opacity-10 mb-3 sm:w-[45%] xl:w-[32%] overflow-hidden">
      <div
        className="w-[100%] h-[204px] rounded-2xl flex flex-col justify-end cursor-pointer relative"
        onClick={onRedirectToVideoOnYoutube}
      >
        <img
          src={data.thumbnail}
          alt="img"
          className="w-[100%] h-[204px] object-cover rounded-t-xl"
        />
        <div className="w-[100%] h-16 p-4 bg-black bg-opacity-25 backdrop-blur-[17px] flex-col justify-start items-start inline-flex absolute">
          <p className="text-white text-xs font-semibold leading-normal cursor-default">
            {data.title}
          </p>
        </div>
      </div>
      <div className="w-[100%] flex justify-between  p-4">
        <div className="w-[48%] p-4 rounded-lg border border-black border-opacity-10 flex-col justify-center items-start gap-1 inline-flex">
          <p className="text-stone-950 text-sm font-semibold leading-tight">
            {getTotalViews()}
          </p>
          <p className=" text-stone-500 text-xs font-normal">
            {/* {viewsText ? "In Last 28 days" : "In Last 7 days"} */}
            {`In Last ${
              name === "mychannel"
                ? activeDay
                : name === "other"
                ? otheractiveDay
                : ""
            } days`}
          </p>
        </div>
        <div className=" w-[48%] p-4 bg-green-100 rounded-lg border border-black border-opacity-10 flex-col justify-start items-start gap-0 inline-flex">
          <div className="flex gap-3">
            <div className="text-center">
              <div className="flex items-center gap-1">
                <BiLike />
                <p className="text-stone-950 text-sm font-semibold leading-tight">
                  {data.likes_by_days ? showLikes() : showLikes2()}
                </p>
              </div>
            </div>
            <div className="text-center">
              <div className="flex items-center gap-1">
                <BiDislike />
                <p className="text-stone-950 text-sm font-semibold leading-tight">
                  {data.dislikes_by_days === undefined
                    ? showDislikes2()
                    : showDisLikes()}
                </p>
              </div>
            </div>
          </div>
          <p className=" text-stone-500 text-xs font-normal mt-2">
            Likes / Dislikes
          </p>
        </div>
      </div>
    </li>
  );
};

export default VideosGettingContentItem;
