import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { suggestionExploreIdeasData } from "../../redux/store/slice/suggestionSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DropDown from "../suggestions/dropDownSuggestion";
import { TailSpin } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import RegenerateIcon from "../../assets/shapes/regenarate.svg";
import { exploreKeywordData } from "../suggestions/othersData";
import { usePostHog } from "posthog-js/react";
import { baseURL } from "../../utils/const";
import "react-toastify/dist/ReactToastify.css";
import "./generate-ideas.scss";
import postHogEvents from "../../constants/events";
import logger from "../../config/logger";

const url = baseURL + "/analytics/channel/llm";

const IdeaSection = ({
  title,
  width,
  type,
  rightText,
  remainingCount,
  setRemainingCount,
}) => {
  const [exploreIdeasData, setExploreIdeasData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [copyTitle, setCopyTitle] = useState(false);
  const [copytags, setCopyTags] = useState(false);
  const [copyDesc, setCopyDesc] = useState(false);
  const [contentIndex, setContentindex] = useState();
  const suggestion_explore_ideas_data = useSelector(
    (state) => state.suggestion_data.suggestion_explore_ideas_data
  );
  const userData = JSON.parse(localStorage.getItem("user-data"));
  const dispatch = useDispatch();
  const posthog = usePostHog();

  const getExploreIdeasData = async () => {
    logger.log("Fetching explore ideas data");
    const Token = localStorage.getItem("token");
    setLoading(true);
    const startTime = Date.now();
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `token ${Token}`,
        },
      });
      const timeElapsed = Date.now() - startTime;
      posthog.identify(userData?.email);
      posthog.capture(postHogEvents.aiIdeasResponseTime, {
        responseTime: timeElapsed,
        page: "explore-ideas",
      });
      logger.success(`Explore ideas data fetched in ${timeElapsed}ms`);

      setExploreIdeasData(response?.data?.finaloutput);
      dispatch(suggestionExploreIdeasData(response?.data?.finaloutput));
      setRemainingCount(response?.data?.remaining_count || 0);
      setLoading(false);
    } catch (error) {
      const timeElapsed = Date.now() - startTime;
      logger.error(
        `Failed to fetch explore ideas data after ${timeElapsed}ms`,
        error
      );
      posthog.identify(userData?.email);
      posthog.capture(postHogEvents.aiIdeasResponseTime, {
        responseTime: timeElapsed,
        page: "explore-ideas",
      });
      setLoading(false);
      setRemainingCount(error?.response?.data?.remaining_count || 0);
      toast.error(
        error?.response?.data?.message ||
          "Server Error, Please Try Again After Some Time",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const onClickCopyTitle = (index) => {
    setContentindex(index);
    setCopyTitle(true);
    setCopyTags(false);
    setCopyDesc(false);
  };

  const onClickCopyDescription = (index) => {
    setContentindex(index);
    setCopyDesc(true);
    setCopyTitle(false);
    setCopyTags(false);
  };

  const onClickCopyTags = (index) => {
    setContentindex(index);
    setCopyTags(true);
    setCopyDesc(false);
    setCopyTitle(false);
  };

  useEffect(() => {
    const abortController = new AbortController();
    getExploreIdeasData();
    return () => {
      abortController.abort();
    };
  }, []);

  const loading = () => {
    return (
      <div className="flex flex-col items-center justify-center  w-full">
        <TailSpin
          height="40"
          width="40"
          color="#3b82f6"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  };

  return (
    <>
      <div className="w-[100%] p-10 max-sm:p-5">
        <div className="w-[100%] flex justify-between items-center">
          <div className="text-gray-500 text-xl font-bold  max-sm:font-medium ">
            {title}
          </div>
          <button
            className={`regenerate-ideas-button`}
            onClick={() => {
              logger.log("Content regenerate button clicked");
              getExploreIdeasData();
            }}
            // disabled={!remainingCount}
          >
            <img src={RegenerateIcon} alt="regenerate-icon" /> Regenerate Ideas
          </button>
        </div>

        {!isLoading ? (
          <>
            <ul>
              {suggestion_explore_ideas_data?.map((each, idx) => (
                <DropDown
                  key={each.idea_number}
                  data={each}
                  index={idx}
                  onClickCopyTitle={onClickCopyTitle}
                  onClickCopyDescription={onClickCopyDescription}
                  onClickCopyTags={onClickCopyTags}
                  setCopyTags={setCopyTags}
                  setCopyDesc={setCopyDesc}
                  setCopyTitle={setCopyTitle}
                  copyTitle={copyTitle}
                  copytags={copytags}
                  copyDesc={copyDesc}
                  contentIndex={contentIndex}
                />
              ))}
            </ul>
          </>
        ) : (
          loading()
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default IdeaSection;
