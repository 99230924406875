import TrendingVideo from "./trendingVideo";

const TrendingNiches = (props) => {
  const { trendingData } = props;
  
  return (
    <div>
      <div className="flex justify-between items-center pr-4 mt-2">
        <h1 className="text-gray-600 text-[22px] font-bold leading-7">
          Trending Videos In Your Niche
        </h1>
        <p className="text-gray-600 text-sm font-normal leading-none">
          Last Updated in 20 Minutes ago
        </p>
      </div>
      <div className=" w-[99%] p-4 bg-violet-100 rounded-2xl border justify-start items-center gap-4 flex mt-6">
        <div className=" pl-2.5 pr-4 py-[7px] rounded-[15px] border border-violet-600 justify-center items-center gap-1.5 flex">
          <div className="w-4 h-4 relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M6.542 10.6027L6 12.5L5.458 10.6027C5.31792 10.1126 5.05527 9.66625 4.69484 9.30583C4.33441 8.9454 3.88809 8.68275 3.398 8.54267L1.5 8L3.39733 7.458C3.88743 7.31792 4.33375 7.05527 4.69417 6.69484C5.0546 6.33441 5.31725 5.88809 5.45733 5.398L6 3.5L6.542 5.39733C6.68209 5.88743 6.94473 6.33375 7.30516 6.69417C7.66559 7.0546 8.11191 7.31725 8.602 7.45733L10.5 8L8.60267 8.542C8.11257 8.68209 7.66625 8.94473 7.30583 9.30516C6.9454 9.66559 6.68275 10.1119 6.54267 10.602L6.542 10.6027ZM12.1727 5.81L12 6.5L11.8273 5.81C11.7285 5.41439 11.524 5.05308 11.2357 4.76469C10.9475 4.47629 10.5862 4.27166 10.1907 4.17267L9.5 4L10.1907 3.82733C10.5862 3.72834 10.9475 3.52371 11.2357 3.23531C11.524 2.94692 11.7285 2.58561 11.8273 2.19L12 1.5L12.1727 2.19C12.2716 2.58569 12.4761 2.94706 12.7645 3.23546C13.0529 3.52386 13.4143 3.72844 13.81 3.82733L14.5 4L13.81 4.17267C13.4143 4.27156 13.0529 4.47614 12.7645 4.76454C12.4761 5.05294 12.2716 5.41431 12.1727 5.81ZM11.2627 13.7113L11 14.5L10.7373 13.7113C10.6637 13.4904 10.5396 13.2897 10.375 13.125C10.2103 12.9604 10.0096 12.8363 9.78867 12.7627L9 12.5L9.78867 12.2373C10.0096 12.1637 10.2103 12.0396 10.375 11.875C10.5396 11.7103 10.6637 11.5096 10.7373 11.2887L11 10.5L11.2627 11.2887C11.3363 11.5096 11.4604 11.7103 11.625 11.875C11.7897 12.0396 11.9904 12.1637 12.2113 12.2373L13 12.5L12.2113 12.7627C11.9904 12.8363 11.7897 12.9604 11.625 13.125C11.4604 13.2897 11.3363 13.4904 11.2627 13.7113Z"
                fill="#7948F6"
                stroke="#7948F6"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="text-violet-600 text-[11px] font-semibold leading-none">
            Upview Pro Tip
          </div>
        </div>
        <div className="grow shrink basis-0 text-zinc-800 text-xs font-semibold leading-tight">
          Keeping track of the trending topics and Videos in you niche is really
          important. Use upview’s trending in your niche to stay updated about
          the trending topics.
        </div>
      </div>
      <div className="mt-6 w-[100%] sm:flex justify-normal flex-wrap gap-6 p-0 sm:justify-between xl:justify-normal">
        {trendingData.map((eachVideo) => (
          <TrendingVideo key={eachVideo.url} video={eachVideo} />
        ))}
      </div>
    </div>
  );
};

export default TrendingNiches;
