import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logger from "../../config/logger";

const HomeCard = ({ heading, description, buttonname, redirect, image }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    heading && logger.log(`${heading} clicked`);
  }, [heading]);

  const Redirect = (url) => {
    logger.log(`Navigating to ${url}`);
    navigate(url);
  };

  return (
    <div
      className="w-[100%] px-8 py-[23px] bg-white rounded-3xl justify-between items-start gap-6 flex flex-col sm:flex-row cursor-pointer"
      onClick={() => Redirect(`${redirect}`)}
    >
      <div className="flex flex-col items-center gap-4 sm:flex-row">
        <img alt="cuate" src={image} className="self-start" />
        <div className="flex flex-col gap-5">
          <p className=" text-zinc-800 text-lg font-bold leading-normal">
            {heading}
          </p>
          <div className=" text-zinc-800 text-sm font-normal leading-tight">
            {description}
          </div>
        </div>
      </div>
      <div className="w-40 h-11 px-6 py-3 bg-violet-600 rounded-[999px] justify-center items-center gap-2.5 flex sm:self-center cursor-pointer">
        <button
          type="button"
          className="text-white text-[13px] font-bold leading-tight"
          onClick={() => Redirect(`${redirect}`)}
        >
          {buttonname}
        </button>
      </div>
    </div>
  );
};

export default HomeCard;
