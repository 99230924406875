import React, { useState, useEffect } from "react";
import TrendingNiches from "../components/trending/trendingNiches";
import TrendingChannelNicheList from "../components/trending/trendingChannelNiches";
import Sidebar from "../components/common/Sidebar";
import { Header } from "../components/common/header/Header";
import { baseURL } from "../utils/const";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import TrendingShorts from "../components/trending/trending-shorts";
import { getToken } from "../api/api";
import logger from "../config/logger";

const TrendingPage = () => {
  const [trendingData, setTrendingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const sessionToken = getToken();

  useEffect(() => {
    logger.log("Trending page opened");
    const fetchData = async () => {
      const start = Date.now();
      try {
        const headers = {
          Authorization: `token ${sessionToken}`,
          "Content-Type": "application/json",
        };

        const response = await axios.get(`${baseURL}/trendingniche`, {
          headers,
        });
        const end = Date.now();
        logger.success(
          `Successfully fetched trending in niche content in ${end - start}ms`
        );

        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }

        setTrendingData(response.data.data.list);
        setIsLoading(false);
      } catch (error) {
        const end = Date.now();
        logger.success(
          `Failed to fetch trending in niche content after ${end - start}ms`,
          error
        );
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Sidebar
      layout={
        <>
          <Header title={"Trending in your Niche   "} />
          <div className="p-12">
            <TrendingNiches trendingData={trendingData} isLoading={isLoading} />
            <h1 className="text-gray-600 text-[22px] font-bold leading-7 mt-14 mb-10">
              Trending shorts In Your Niche
            </h1>
            {isLoading ? ( // Display loader while loading
              <div className="flex justify-center items-center mt-5">
                <TailSpin height="40" width="40" color="#3b82f6" />
              </div>
            ) : (
              <ul className="mt-5 flex flex-wrap justify-start gap-4 lg:gap-6 xl:gap-7 2xl:gap-6 ">
                {trendingData?.map((short, id) => (
                  <TrendingShorts short={short} key={id} />
                ))}
              </ul>
            )}
            <h1 className="text-gray-600 text-[22px] font-bold leading-7 mt-14 mb-10">
              Top 10 Trending Channels in your Niche
            </h1>
            {isLoading ? ( // Display loader while loading
              <div className="flex justify-center items-center mt-5">
                <TailSpin height="40" width="40" color="#3b82f6" />
              </div>
            ) : (
              <div className="mt-5 flex flex-wrap justify-start gap-4 lg:gap-6 xl:gap-7 2xl:gap-6 ">
                {trendingData?.map((channel, id) => (
                  <TrendingChannelNicheList channel={channel} key={id} />
                ))}
              </div>
            )}
          </div>
        </>
      }
    />
  );
};

export default TrendingPage;
