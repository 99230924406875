import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { baseURL } from "../../utils/const";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { fetchApiKeywordData } from "../../redux/store/slice/api/apiSuggestionKeywordSlice";
import Tooltip from "../common/Tooltip";


const MakeVideosSort = () => {
  const [makeVideoTags, setMakeVideoTags] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { keywordData, loadingKeyword, errorKeyword } = useSelector(
    (state) => state.apiSuggestionKeyWord
  );
  const Token = localStorage.getItem("token");

  const getMakeVideoSectionTags = async () => {
    const url = baseURL + "/analytics/channel/keywords";

    try {
      setLoading(true);
      const response = await axios.get(url, {
        headers: {
          Authorization: `token ${Token}`,
        },
      });
      setMakeVideoTags(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loadingKeyword === "idle") {
      dispatch(fetchApiKeywordData(Token));
    }
  }, [dispatch, loadingKeyword]);

  const loading = () => {
    return (
      <div className="flex flex-col items-center justify-center w-full">
        <TailSpin
          height="40"
          width="40"
          color="#3b82f6"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  };

  return (
    <div className="mt-7 p-6 bg-slate-100 rounded-2xl flex-col justify-start items-start gap-5 inline-flex sm: w-[98%]">
      <div className=" w-[100%] flex justify-between ">
        <div className="text-neutral-800 text-lg font-bold mb-3">
          Make videos on these topics
        </div>
        {/* <>
          <img
            alt="info"
            src={info}
            className="w-[20px] h-[20px]"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="These represent the keywords that your audience is inputting to discover your content. Crafting videos aligned with your audience's search queries has been established as an effective strategy for boosting website visits."
          />
          <Tooltip
            id="my-tooltip"
          />
        </> */}
        <Tooltip id="ideas" content="These represent the keywords that your audience is inputting to discover your content. Crafting videos aligned with your audience's search queries has been established as an effective strategy for boosting website visits." />
      </div>
      <ul className="w-[100%] flex flex-wrap gap-2 ">
        {loadingKeyword === "pending" ? (
          loading()
        ) : (
          <>
            {keywordData?.map((each, idx) => (
              <li
                key={idx}
                className="h-9 px-5 py-1.5 bg-gray-200 rounded-[44px] justify-center items-center gap-2.5 inline-flex text-neutral-800 text-sm font-medium leading-normal"
              >
                {each.keyword}
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
};

export default MakeVideosSort;
