import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import requestsent from "../../../assets/images/requestsent.png";

export default function RequestSentModal({
  open,
  onClose,
  channelTitle,
  requestAlreadySent,
}) {
  const closeModal = () => {
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="w-full fixed inset-0 z-30 flex items-center justify-center"
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="w-[95%] lg:w-[33%]  p-10 bg-white rounded-2xl flex-col justify-start items-center gap-4 md:gap-10 inline-flex">
            <img className="" src={requestsent} alt="Request Sent" />
            <div className="self-stretch h-[110px] flex-col justify-start items-center gap-6 flex">
              <h1 className="text-zinc-800 text-lg lg:text-2xl xl:text-3xl font-bold">
                {requestAlreadySent ? "Request Already Sent" : "Request Sent"}
              </h1>
              <p className="self-stretch text-center text-gray-600 text-base font-normal">
                {requestAlreadySent ? (
                  "We will let you know when they respond."
                ) : (
                  <>
                    We have let
                    <span className="font-semibold ml-2 mr-2">
                      {channelTitle}
                    </span>
                    know that you are interested in collaborating with them.
                  </>
                )}
              </p>
            </div>
            <button
              className="w-36 h-7 lg:h-10 lg:w-[180px] px-6 py-4 rounded-[999px] border border-violet-600 text-violet-600 justify-center items-center gap-2.5 inline-flex text-sm lg:text-base "
              onClick={closeModal}
            >
              Okay
            </button>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
