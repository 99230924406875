import React, { useState } from "react";
import HearAbout from "../components/Onboarding/HearAbout";
import Referral from "../components/Onboarding/Referral";
import Interested from "../components/Onboarding/Interested";
import InterestedOther from "../components/Onboarding/Interested-Other";
import CollaborateWithOtherYoutuber from "../components/Onboarding/CollaborateWithOtherYoutuber";
import { useDispatch, useSelector } from "react-redux";
import OutSource from "../components/Onboarding/OutSource";
import UpviewImage from "../assets/images/upview.png";
import { get_onBoarding_data, submitonBoarding_data } from "../api/api";
import OutSourceOther from "../components/Onboarding/OutSource-Others";
const Onboarding = () => {
  const [active, setActive] = useState("");
  const {
    onboard_state,
    onboard_hear_about_us,
    onboard_interested_others_data,
    onboard_referral_data,
    onboard_hear_about_data,
    onboard_out_source_data,
    onboard_collab_status,
    onboard_interested_data,
  } = useSelector((state) => state.Onboarding);

  const getOnboardingData = async () => {
    await submitonBoarding_data(
      onboard_hear_about_us,
      onboard_referral_data,
      onboard_interested_data,
      onboard_interested_others_data,
      onboard_collab_status,
      onboard_out_source_data
    )
      .then((res) => {
      })
      .catch((err) => {
      });
  };

  // getOnboardingData();
  // bg-[grey] w-[100%] flex justify-center h-[100vh]
  return (
    <div className="p-5 w-[100%] bg-[aliceblue]">
      <header className="mb-2">
        <img src={UpviewImage} alt="logo" />
      </header>
      {onboard_state === "hear_about" ? (
        <div className="w-[100%] flex justify-center">
          <HearAbout setActive={setActive} />
        </div>
      ) : onboard_state === "referral" ? (
        <div className="w-[100%] flex justify-center">
          <Referral setActive={setActive} />
        </div>
      ) : onboard_state === "interested" ||
        onboard_state === "chrome" ||
        onboard_state === "google" ||
        onboard_state === "youtube" ? (
        <div className="w-[100%] flex justify-center">
          <Interested />
        </div>
      ) : onboard_state === "interested_others" ? (
        <div className="w-[100%] flex justify-center">
          <InterestedOther />
        </div>
      ) : onboard_state === "collab" ? (
        <div className="w-[100%] flex justify-center">
          <CollaborateWithOtherYoutuber />
        </div>
      ) : onboard_state === "outsource" ? (
        <div className="w-[100%]  flex justify-center">
          <OutSource />
        </div>
      ) : onboard_state === "out_source_other" ? (
        <div className="w-[100%] flex justify-center">
          <OutSourceOther />
        </div>
      ) : (
        <HearAbout setActive={setActive} />
      )}
    </div>
  );
};

export default Onboarding;
