import React from "react";
import Sidebar from "../components/common/Sidebar";

const Hook = () => {
  return (
    <>
      <Sidebar layout={<div>Hook page</div>} />
    </>
  );
};

export default Hook;
