import React, { useEffect, useState } from "react";
import TextBox from "./TextBox";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { baseURL } from "../../utils/const";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";

const EmailVerification = () => {
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state) {
      // localStorage.removeItem("user-data");
      // localStorage.removeItem("token");
      navigate("/");
    }
  });

  const initialValues = {
    verification: "",
  };

  const validationSchema = Yup.object({
    verification: Yup.string().required("OTP is required"),
  });

  const onSubmit = async (values) => {
    setLoader(true);
    try {
      const response = await axios.post(baseURL + "/verify/", {
        code: values?.verification,
        username: location?.state,
      });
      setLoader(false);
      if (response?.data?.message === "Invalid verification code.") {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        setTimeout(() => {
          navigate("/account-registered", { state: location?.state });
        }, 2000);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Please try again..!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);
    }
  };

  const resendOTP = async () => {
    setLoader(true);
    try {
      const response = await axios.post(baseURL + "/resend/code/", {
        username: location?.state,
      });

      toast.success(response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    } catch (error) {
      console.error(error);
      toast.error("Please try again..!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);
    }
  };

  const handleBack = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user-data");
    navigate("/signup");
  };

  window.addEventListener("popstate", handleBack);

  return (
    <>
      <ToastContainer />
      <div className="flex items-center justify-center flex-col min-h-screen">
        <div className="w-[75vw] max-w-[440px] lg:w-[440px] text-zinc-800 text-[28px] font-bold">
          Email Verification
        </div>
        <div className="w-[75vw] max-w-[440px] lg:w-[440px] text-gray-600 text-base font-normal">
          OTP sent to your Email address for verification.
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="">
            <TextBox
              type="text"
              name="verification"
              id="verification"
              placeholder="Enter Verification Code here"
            />
            <ErrorMessage
              name="verification"
              component="div"
              className="text-red-500"
            />

            <div className="flex items-center mt-4 gap-2">
              <div className="text-black text-sm font-normal">
                Didn’t receive OTP?
              </div>
              <div
                className={`${
                  !loader ? "text-violet-600 cursor-pointer" : "opacity-50"
                } text-sm font-medium`}
                onClick={!loader && resendOTP}
              >
                Resend OTP
              </div>
            </div>

            <button
              type="submit"
              disabled={loader}
              className="mt-[46px] w-[75vw] max-w-[440px] lg:w-[440px] h-[54px] px-[25px] py-4 bg-violet-600 rounded-[53px] justify-center items-center gap-2.5 inline-flex"
            >
              {loader ? (
                <TailSpin
                  height="20"
                  width="20"
                  color="white"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                <div className="text-white text-base font-semibold leading-snug">
                  Verify
                </div>
              )}
            </button>
          </Form>
        </Formik>
        {/* <div className="mt-2 ml-4 w-[75vw] max-w-[440px] lg:w-[440px] h-[17px] justify-start items-center gap-2 inline-flex">
          <div className="text-black text-sm font-normal">
            Didn’t receive OTP
          </div>
          <div
            className={`${
              !loader ? "text-violet-600 cursor-pointer" : "opacity-50"
            } text-sm font-medium`}
            onClick={!loader && resendOTP}
          >
            Resend OTP
          </div>
        </div> */}
      </div>
    </>
  );
};

export default EmailVerification;
