import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import Collaboration from "./pages/Collaboration";
import Overview from "./pages/Overview";
import Hook from "./pages/Hook";
import Suggestions from "./pages/Suggestions";
import Thumbnail from "./pages/Thumbnail";
import SuggetionExplore from "./pages/SuggetionExplore";
import GenerateIdeas from "./pages/GenerateIdeas";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Verification from "./pages/Verification";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AccountRegistered from "./pages/AccountRegistered";
import Onboarding from "./pages/Onboarding";
import Redirect from "./pages/Redirect";
import HomePage from "./pages/HomePage";
import WillMissYou from "./components/contents/settings/willMissYouPage";
import DeleteAccount from "./components/contents/settings/DeleteAccount";
import GameScreen from "./components/games/main";
import TrendingPage from "./pages/trending";

export function AppRoutes() {
  const Token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("user-data"));

  const routes = useRoutes([
    {
      path: "/",
      exact: true,
      children: [
        { path: "/", exact: true, element: <Navigate to="/signup" /> },
        {
          path: "login",
          exact: true,
          element: !Token ? <Login /> : <Navigate to="/home" />,
        },
        {
          path: "signup",
          exact: true,
          element: !Token ? <Signup /> : <Navigate to="/home" />,
        },
        { path: "verification", exact: true, element: <Verification /> },
        { path: "forgot-password", exact: true, element: <ForgotPassword /> },
        { path: "reset-password", exact: true, element: <ResetPassword /> },
        { path: "redirect", exact: true, element: <Redirect /> },
        {
          path: "account-registered",
          exact: true,
          element: <AccountRegistered />,
        },
        {
          path: "/home",
          exact: true,
          element: Token ? <HomePage /> : <Navigate to="/signup" />,
        },
        {
          path: "collaboration",
          exact: true,
          element: Token ? <Collaboration /> : <Navigate to="/signup" />,
        },
        {
          path: "overview",
          exact: true,
          element: Token ? <Overview /> : <Navigate to="/signup" />,
        },
        {
          path: "thumbnail",
          exact: true,
          element: Token ? <Thumbnail /> : <Navigate to="/signup" />,
        },
        {
          path: "suggestions",
          exact: true,
          element: Token ? <Suggestions /> : <Navigate to="/signup" />,
        },
        {
          path: "hook",
          exact: true,
          element: Token ? <Hook /> : <Navigate to="/signup" />,
        },
        {
          path: "suggestions/explore",
          exact: true,
          element: Token ? <SuggetionExplore /> : <Navigate to="/signup" />,
        },
        {
          path: "suggestions/generate",
          exact: true,
          element: Token ? <GenerateIdeas /> : <Navigate to="/signup" />,
        },
        {
          path: "onboarding",
          exact: true,
          element: Token ? <Onboarding /> : <Navigate to="/signup" />,
        },
        {
          path: "settings1",
          exact: true,
          element: Token ? <DeleteAccount /> : <Navigate to="/signup" />,
        },
        {
          path: "WillMissYou",
          exact: true,
          element: Token ? <WillMissYou /> : <Navigate to="/signup" />,
        },
        {
          path: "trending",
          exact: true,
          element: Token ? <TrendingPage /> : <Navigate to="/signup" />,
        },
        {
          path: "demoAccounts",
          exact: true,
          element: <GameScreen />,
        },
        {
          path: "payment-success",
          exact: true,
          element: <HomePage />,
        },
      ],
    },
  ]);
  return routes;
}
