import React, { useState } from "react";
import EmailLogo from "../../assets/images/email.svg";
import PasswordLogo from "../../assets/images/password.svg";
import PasswordView from "../../assets/images/passwordView.svg";
import { BiHide } from "react-icons/bi";
import { Field } from "formik";

const TextBox = ({ placeholder, type, name, id, email, password }) => {
  const [showPassword, setShowPassword] = useState(true);

  const handleOnClick = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="relative mt-[26px] mb-[4px] w-[75vw] max-w-[440px] lg:w-[440px] h-[54px]">
      <Field
        className={`py-4 w-full rounded border border-neutral-900 border-opacity-20 ${
          (name != "name" && name != "verification") && "px-8"
        }`}
        placeholder={placeholder}
        type={showPassword ? type : "text"}
        name={name}
        id={id}
        autoComplete="new-password"
      />
      {email && (
        <div
          className="absolute top-5 left-2 flex items-center justify-center w-[18px] h-[18px] rounded-lg bg-cover"
          style={{ backgroundImage: `url(${EmailLogo})` }}
        ></div>
      )}
      {password && (
        <>
          <div
            className="absolute top-5 left-2 flex items-center justify-center w-[18px] h-[18px] rounded-lg bg-cover"
            style={{ backgroundImage: `url(${PasswordLogo})` }}
          ></div>
          {showPassword ? (
            <div
              onClick={handleOnClick}
              className="absolute top-5 right-2 cursor-pointer flex items-center justify-center w-[18px] h-[18px] rounded-lg bg-cover"
              style={{
                backgroundImage: `url(${showPassword ? PasswordView : null})`,
              }}
            ></div>
          ) : (
            <div
              onClick={handleOnClick}
              className="absolute top-5 right-2 cursor-pointer flex items-center justify-center w-[18px] h-[18px] rounded-lg bg-cover"
            >
              <BiHide />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TextBox;
