import React from "react";
import LoginComponent from "../components/auth/LoginComponent";
import SideComponent from "../components/auth/SideComponent";
import Logo from "../components/auth/Logo";

const Login = () => {
  return (
    <div className="bg-violet-600">
      <div className="flex justify-between items-center">
        <div className="w-[100vw] xl:w-[50vw] relative bg-white h-[100%]">
          <div className="absolute top-4 left-4">
            <Logo />
          </div>
          <LoginComponent />
        </div>
        <div className="w-[50vw] hidden xl:flex items-center h-[100%] justify-center">
          <SideComponent />
        </div>
      </div>
    </div>
  );
};

export default Login;
