// apiCollaborationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../../../utils/const";
import logger from "../../../../config/logger";

// Replace 'your-api-endpoint' with your actual API endpoint
const apiEndpoint = baseURL;
const authToken = localStorage.getItem("token");

export const fetchApiMyChannelData = createAsyncThunk(
  "/analytics/channel/video/top?timeframe=7",
  async (data) => {
    logger.log("Fetching channel videos content data");

    const config = {
      headers: {
        Authorization: `token ${data[0]}`,
      },
    };

    const start_time = Date.now();
    const response = await axios.get(
      apiEndpoint + `/analytics/channel/video/top?timeframe=${data[1]}`,
      config
    );
    const end_time = Date.now();
    const time = end_time - start_time;
    logger.log(`Fetched channel videos data in ${time}`, {
      status: response.status,
    });
    return response?.data?.data;
  }
);

export const fetchApiMyChannelData28days = createAsyncThunk(
  "/analytics/channel/video/top?timeframe=28",
  async (data) => {
    logger.log("Fetching channel videos content data 28 days");
    const config = {
      headers: {
        Authorization: `token ${data[0]}`,
      },
    };
    const start_time = Date.now();
    const response = await axios.get(
      apiEndpoint + `/analytics/channel/video/top?timeframe=28`,
      config
    );
    const end_time = Date.now();
    const time = end_time - start_time;
    logger.log(`Fetched channel videos data 28 days in ${time}`, {
      status: response.status,
    });
    return response?.data?.data;
  }
);

const apiSuggestionVideoMychannelSlice = createSlice({
  name: "api",
  initialState: {
    mychannelVideoData: null,
    twentyEightDaysData: null,
    loading: "idle",
    loading28day: "idle",
    error: null,
    error28day: null,
    sevenDaysApiCall: true,
    twentyEightDaysApiCall: false,
  },
  reducers: {
    modifyData(state, action) {
      state.mychannelVideoData = action.payload;
    },
    AddtwentyEightDaysData(state, action) {
      state.mychannelVideoData = action.payload;
    },
    daysApiStatusChange(state, action) {
      if (action.payload === 7) {
        state.sevenDaysApiCall = true;
      }
      if (action.payload === 28) {
        state.twentyEightDaysApiCall = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiMyChannelData.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchApiMyChannelData.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.mychannelVideoData = action.payload;
      })
      .addCase(fetchApiMyChannelData.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchApiMyChannelData28days.pending, (state) => {
        state.loading28day = "pending";
      })
      .addCase(fetchApiMyChannelData28days.fulfilled, (state, action) => {
        state.loading28day = "succeeded";
        state.twentyEightDaysData = action.payload;
      })
      .addCase(fetchApiMyChannelData28days.rejected, (state, action) => {
        state.loading28day = "failed";
        state.error28day = action.error.message;
      });
  },
});

export const { modifyData, daysApiStatusChange } =
  apiSuggestionVideoMychannelSlice.actions;

export default apiSuggestionVideoMychannelSlice.reducer;
