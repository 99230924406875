import { useContext, useEffect, useState } from "react";
import { FilteredDataContext } from "../../pages/Suggestions";
import { useDispatch, useSelector } from "react-redux";
import { short_Data_filter_Value } from "../../redux/store/slice/api/apiShortSlice";
import {
  daysApiStatusChange,
  fetchApiMyChannelData,
  fetchApiMyChannelData28days,
  modifyData,
} from "../../redux/store/slice/api/apiSuggestionVideoSlice";
import {
  AddActiveDay,
  AddActiveState,
  AddotherActiveDay,
} from "../../redux/store/slice/sortSlice";
import { fetchApiOtherData28day } from "../../redux/store/slice/api/apiOther28daySlice";
import { fetchApiOtherData } from "../../redux/store/slice/api/apiSuggestionOtherSlice";

const FilterSection = ({ active }) => {
  const suggestion_mychannel_data = useSelector(
    (state) => state.suggestion_data.suggestion_video_content_mychannel
  );
  const suggestion_others_data = useSelector(
    (state) => state.suggestion_data.suggestion_video_content_others
  );
  const suggestion_shorts_data = useSelector(
    (state) => state.suggestion_data.suggestion_video_short_data
  );

  const { shortData, loadingShort, errorShort } = useSelector(
    (state) => state.apiSuggestionShort
  );
  const {
    mychannelVideoData,
    loading,
    error,
    sevenDaysApiCall,
    twentyEightDaysApiCall,
    twentyEightDaysData,
  } = useSelector((state) => state.apiSuggestionMyChannel);

  const { activeState, activeDay, otheractiveDay } = useSelector(
    (state) => state.SortSlice
  );

  const [defValue1, setDefValue1] = useState(activeState);
  const [defvalue2, setDefValue2] = useState(activeDay);
  const [otherSelect, setOtherSelect] = useState(otheractiveDay);
  const [shortState, setShortState] = useState("recent");

  const dispatch = useDispatch();

  const Token = localStorage.getItem("token");

  const { otherData, loadingOther, errorOther } = useSelector(
    (state) => state.apiSuggestionOther
  );
  const { loadingOther28day, errorOther28day, otherData28day } = useSelector(
    (state) => state.apiOtherdata28Day
  );
  const userData = JSON.parse(localStorage.getItem("user-data"));

  const {
    showVideoSuggestion,
    setShortsData,
    myChannelData,
    myChannelResponseData,
    setMyChannelResponseData,
    sortCondition,
    setSortCondition,
    setMyChannelData,
    othersData,
    setViewsText,
  } = useContext(FilteredDataContext);

  const onChangeMyChannel = (event) => {
    if (event === "myChannel") {
      dispatch(AddActiveState("myChannel"));

      setSortCondition(true);
    } else {
      dispatch(AddActiveState("others"));

      setSortCondition(false);
    }
  };

  const onFilterShortVideos = (event) => {
    if (event === "recent") {
      dispatch(
        short_Data_filter_Value(
          [...shortData].sort(
            (a, b) => new Date(b.published) - new Date(a.published)
          )
        )
      );
      setShortsData(suggestion_shorts_data);
      setShortState("recent");
    } else {
      setShortsData(
        [...suggestion_shorts_data].sort((a, b) => b.like - a.like)
      );
      dispatch(
        short_Data_filter_Value([...shortData].sort((a, b) => b.like - a.like))
      );
      setShortState("popular");
    }
  };

  const onFilterVideos = (event) => {
    if (event.target.value == 7) {
      dispatch(AddActiveDay(7));
      setDefValue2(7);
      if (!mychannelVideoData) {
        dispatch(fetchApiMyChannelData([Token, 7]));
        dispatch(daysApiStatusChange(7));
      }

      setViewsText(false);
    } else if (event.target.value == 28) {
      dispatch(AddActiveDay(28));
      setDefValue2(28);
      if (!twentyEightDaysData) {
        dispatch(fetchApiMyChannelData28days([Token, 28]));
        dispatch(daysApiStatusChange(28));
      }

      setViewsText(true);
    }
  };

  const onFilterVideosOther = (event) => {
    if (event.target.value == 7) {
      dispatch(AddotherActiveDay(7));
      setOtherSelect(7);
      if (!otherData) {
        dispatch(fetchApiOtherData([Token, 7]));
      }
      setViewsText(false);
    } else if (event.target.value == 28) {
      dispatch(AddotherActiveDay(28));
      setOtherSelect(28);
      if (!otherData28day) {
        dispatch(fetchApiOtherData28day([Token, 28]));
      }
      setViewsText(true);
    }
  };

  return (
    <>
      <div className="flex flex-wrap items-center gap-2 mb-0">
        {active === "video" && (
          <div className="  p-1.5 bg-slate-100 rounded-full flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="justify-start items-start inline-flex">
              <div
                className={`h-8 px-4 py-2  ${
                  activeState === "myChannel" && "bg-white"
                } rounded-full justify-center items-center gap-2.5 flex cursor-pointer`}
                onClick={() => {
                  onChangeMyChannel("myChannel");
                }}
              >
                <div
                  className={`  ${
                    activeState === "myChannel"
                      ? "text-violet-600"
                      : "text-black"
                  } text-sm font-medium leading-none`}
                >
                  My Channel
                </div>
              </div>
              <div
                className={`h-8 px-4 py-2  ${
                  activeState === "others" && "bg-white"
                } rounded-full justify-center items-center gap-2.5 flex cursor-pointer`}
                onClick={() => {
                  onChangeMyChannel("others");
                }}
              >
                <div
                  className={`  ${
                    activeState === "others" ? "text-violet-600" : "text-black"
                  } text-sm font-medium leading-none`}
                >
                  Other Channels
                </div>
              </div>
            </div>
          </div>
        )}

        {active === "video" && activeState === "myChannel" && (
          <div className="px-4 py-1.5 rounded-[36px] border  justify-start items-center gap-2 inline-flex">
            <select
              className="text-neutral-800 text-sm font-medium leading-[14px] outline-none border-none"
              defaultValue={defvalue2}
              onChange={(e) => onFilterVideos(e)}
              onFocus={(e) => {
                e.target.style.outline = "none";
                e.target.style.boxShadow = "none";
              }}
            >
              <option value="7">Last 7 Days </option>
              <option value="28">Last 28 Days </option>
            </select>
          </div>
        )}
        {active === "video" && activeState === "others" && (
          <div className="px-4 py-1.5 rounded-[36px] border  justify-start items-center gap-2 inline-flex">
            <select
              className="text-neutral-800 text-sm font-medium leading-[14px] outline-none border-none"
              defaultValue={otherSelect}
              onChange={(e) => onFilterVideosOther(e)}
              onFocus={(e) => {
                e.target.style.outline = "none";
                e.target.style.boxShadow = "none";
              }}
            >
              <option value="7">Last 7 Days</option>
              <option value="28">Last 28 Days </option>
            </select>
          </div>
        )}
        {!showVideoSuggestion && (
          <div className="px-4 py-1.5 rounded-[36px] border  justify-start items-center gap-2 inline-flex">
            <select
              className="text-neutral-800 text-sm font-medium leading-[14px] outline-none border-none"
              defaultValue={shortState}
              onChange={(e) => onFilterShortVideos(e.target.value)}
              onFocus={(e) => {
                e.target.style.outline = "none";
                e.target.style.boxShadow = "none";
              }}
            >
              <option value="recent">Recent </option>
              <option value="popular">Most Popular </option>
            </select>
          </div>
        )}
      </div>
    </>
  );
};

export default FilterSection;
