import React from "react";
import { useContext, useState } from "react";
import { SearchedDataContext } from "./index";
import DropDown from "../../suggestions/dropDownSuggestion";
import { TailSpin } from "react-loader-spinner";
import RegenerateIcon from "../../../assets/shapes/regenarate.svg";
import { useSelector } from "react-redux";
import logger from "../../../config/logger";

const SearchResult = ({ keyword, remainingCount }) => {
  const [copyTitle, setCopyTitle] = useState(false);
  const [copytags, setCopyTags] = useState(false);
  const [copyDesc, setCopyDesc] = useState(false);
  const [contentIndex, setContentindex] = useState();

  const suggestion_keyword_idea_data = useSelector(
    (state) => state.suggestion_data.suggestion_keyword_idea_section
  );
  const isLoading = useSelector(
    (state) => state.suggestion_data.explore_keywords_loading
  );

  const onClickCopyTitle = (index) => {
    setContentindex(index);
    setCopyTitle(true);
    setCopyTags(false);
    setCopyDesc(false);
  };

  const onClickCopyDescription = (index) => {
    setContentindex(index);
    setCopyDesc(true);
    setCopyTitle(false);
    setCopyTags(false);
  };

  const onClickCopyTags = (index) => {
    setContentindex(index);
    setCopyTags(true);
    setCopyDesc(false);
    setCopyTitle(false);
  };

  const regenerateData = () => {
    const event = new Event("call-onClickGenerateData");

    document.dispatchEvent(event);
  };

  return (
    <div className="w-[100%]  flex-col justify-start items-start flex-wrap  inline-flex">
      <div className="w-[100%] p-5 max-sm:p-5">
        <div className="flex justify-end mb-4">
          <button
            className={`regenerate-ideas-button items-end`}
            onClick={() => {
              logger.log("Regenerate keyword content data button clicked");
              regenerateData();
            }}
            // disabled={!remainingCount}
          >
            <img src={RegenerateIcon} alt="regenerate-icon" /> Regenerate Ideas
          </button>
        </div>
        <div className="w-[100%] flex justify-between items-center pl-2 pr-2">
          <div className="text-gray-500 text-xl font-bold  max-sm:font-medium ">
            Results
          </div>
          <p className="text-gray-500 text-sm font-normal">
            {suggestion_keyword_idea_data?.length} results
          </p>
        </div>

        {!isLoading ? (
          <ul>
            {suggestion_keyword_idea_data?.map((each, idx) => (
              <DropDown
                key={each.idea_number}
                data={each}
                index={idx}
                onClickCopyTitle={onClickCopyTitle}
                onClickCopyDescription={onClickCopyDescription}
                onClickCopyTags={onClickCopyTags}
                setCopyTags={setCopyTags}
                setCopyDesc={setCopyDesc}
                setCopyTitle={setCopyTitle}
                copyTitle={copyTitle}
                copytags={copytags}
                copyDesc={copyDesc}
                contentIndex={contentIndex}
                keyword={keyword}
              />
            ))}
          </ul>
        ) : (
          <div className="flex flex-col items-center justify-center  w-full">
            <TailSpin
              height="40"
              width="40"
              color="#3b82f6"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResult;
