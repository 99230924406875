import React, { useState } from "react";
import ArrowRight from "../../assets/images/arrow-right.png";
import ArrowLeft from "../../assets/images/arrow-left.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addInterestedOtherData,
  onBoardState,
} from "../../redux/store/slice/Onboarding";
const InterestedOther = () => {
  const dispatch = useDispatch();
  const { onboard_interested_data, onboard_interested_others_data } =
    useSelector((state) => state.Onboarding);
  const [interestedOtherValue, setInterestedOtherValue] = useState(
    onboard_interested_others_data ? onboard_interested_others_data : ""
  );
  const Submit = async (value) => {
    if (value.length > 1) {
      dispatch(addInterestedOtherData(value));
      dispatch(onBoardState("collab"));
    }
  };

  const interestedOther = () => {
    dispatch(addInterestedOtherData(""));
    dispatch(onBoardState("interested"));
  };

  return (
    // <div className="w-[50%] max-sm:w-[100%] flex justify-center">
    //   <div className="w-[100%]  p-10 bg-white rounded-3xl flex-col justify-start items-center gap-16 inline-flex">
    //     <div className="w-56 justify-start items-center inline-flex">
    //       <div className="w-2 h-2 bg-green-600 rounded-full border" />
    //       <div className="grow shrink basis-0 h-px border border-green-600"></div>
    //       <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
    //       <div className="w-2 h-2 bg-stone-300 rounded-full" />
    //       <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
    //       <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
    //       <div className="w-2 h-2 bg-stone-300 rounded-full" />
    //       <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
    //       <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
    //       <div className="w-2 h-2 bg-stone-300 rounded-full" />
    //     </div>
    //     <div className="w-[60%]   flex-col justify-between items-center gap-14 flex">
    //       <div className="flex-col justify-start items-center gap-20 flex">
    //         <div className="w-[80%] max-sm:text-sm text-center text-zinc-800 text-3xl font-bold leading-10">
    //           What got you interested in Upview?
    //         </div>
    //         <div className="h-20 flex-col justify-start items-start gap-3.5 flex">
    //           <div className="text-black text-base font-medium leading-snug">
    //             Tell us why you are here
    //           </div>
    //           <div className="w-96 h-12 relative">
    //             <div className="w-96 h-12 left-0 top-0 absolute rounded border border-violet-600 border-opacity-20" />
    //             <input
    //               type="text"
    //               value={interestedOtherValue}
    //               placeholder="Type here"
    //               className="w-96 h-12 px-4 py-3 left-0 top-0 absolute rounded border border-violet-600 justify-start items-center inline-flex"
    //               required
    //               onChange={(e) => setInterestedOtherValue(e.target.value)}
    //             />
    //             {/* <div className="w-px h-5 bg-violet-600 rounded-sm" />
    //             <div className="w-72 h-5 rounded-sm" /> */}
    //             {/* </input> */}
    //           </div>
    //         </div>
    //       </div>
    //       <div className="w-[100%] justify-between items-start gap-4 inline-flex">
    //         <div
    //           className="px-8 py-3.5 bg-black bg-opacity-10 rounded-3xl justify-center items-center gap-2 flex cursor-pointer"
    //           onClick={() => dispatch(onBoardState("interested"))}
    //         >
    //           {/* <div className="w-5 h-5 relative origin-top-left -rotate-180" /> */}
    //           <div className="w-5 h-5 relative">
    //             <img src={ArrowRight} />
    //           </div>
    //           <div className="text-zinc-800 text-base font-semibold leading-normal">
    //             Back
    //           </div>
    //         </div>
    //         <div
    //           className={`px-8 py-3.5 bg-violet-600 rounded-3xl justify-center items-center gap-2 flex ${
    //             interestedOtherValue ? `cursor-pointer` : `cursor-not-allowed`
    //           } `}
    //           onClick={() => Submit(interestedOtherValue)}
    //         >
    //           <div className="text-white text-base font-semibold leading-normal">
    //             Next
    //           </div>
    //           <div className="w-5 h-5 relative">
    //             <img src={ArrowLeft} />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="w-[50%] max-sm:w-[100%] md:w-[80%] lg:w-[60%] xl:w-[50%] flex justify-center">
      <div className="w-[100%] h-[100vh] max-sm:p-5 p-10 bg-white rounded-3xl flex-col justify-start items-center gap-16 flex">
        <div className="w-56 justify-start items-center flex">
          <div className="w-2 h-2 bg-green-600 rounded-full border" />
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="w-2 h-2 bg-stone-300 rounded-full" />
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="w-2 h-2 bg-stone-300 rounded-full" />
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="w-2 h-2 bg-stone-300 rounded-full" />
        </div>
        <div className="w-[60%] h-[80%] max-sm:w-[100%] md:w-[100%] lg:w-[100%] xl:w-[60%] flex-col justify-between items-center gap-14 flex">
          <div className="w-[100%] max-sm:w-[100%] flex-col justify-start items-center gap-20 flex">
            <div className=" text-center text-zinc-800 text-3xl font-bold leading-10">
              What got you interested in Upview?
            </div>
            <div className="max-sm:w-[80%] flex-col justify-start items-start gap-3.5 flex">
              <div className="text-black text-base font-medium leading-snug">
                Tell us why you are here
              </div>
              <div className="w-96 max-sm:w-[100%]   h-12 relative">
                {/* <div className="w-96 h-12 left-0 top-0 absolute rounded border border-violet-600 border-opacity-20" /> */}
                <input
                  type="text"
                  value={interestedOtherValue}
                  placeholder="Enter the Name here"
                  className="w-96 max-sm:w-[100%] h-12 px-4 py-3 left-0 top-0 absolute rounded border border-violet-600 justify-start items-center inline-flex"
                  onChange={(e) => setInterestedOtherValue(e.target.value)}
                />
                {/* <div className="w-px h-5 bg-violet-600 rounded-sm" />
              <div className="w-72 h-5 rounded-sm" /> */}
                {/* </input> */}
              </div>
            </div>
          </div>
          <div className="w-[50%] max-sm:w-[100%] md:w-[100%] justify-between items-start gap-4 inline-flex">
            <div
              className="px-8 py-3.5 bg-black bg-opacity-10 rounded-3xl justify-center items-center gap-2 flex cursor-pointer"
              onClick={() => interestedOther()}
            >
              {/* <div className="w-5 h-5 relative origin-top-left -rotate-180" /> */}
              <div className="w-5 h-5 relative">
                <img src={ArrowRight} />
              </div>
              <div className="text-zinc-800 text-base font-semibold leading-normal">
                Back
              </div>
            </div>
            <div
              className={`px-8 py-3.5 ${
                onBoardState ? `cursor-not-allowed` : `cursor-pointer`
              } bg-violet-600
             rounded-3xl justify-center items-center gap-2 flex cursor-pointer `}
              onClick={() => Submit(interestedOtherValue)}
            >
              <div
                className={`text-white  text-base font-semibold leading-normal`}
              >
                Next
              </div>
              <div className="w-5 h-5 relative">
                <img src={ArrowLeft} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestedOther;
