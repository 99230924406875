import { Tooltip as __Tooltip } from "react-tooltip";
import { ReactComponent as Info } from "../../assets/images/info.svg";
import "./tooltip.scss";

const Tooltip = ({ content, id, ...rest }) => {
  return (
    <>
      {/* <img
        alt="info"
        src={info}
        className="w-[20px] h-[20px]"
        data-tooltip-id={id}
        data-tooltip-content={content}
      /> */}
      <div
        className="w-[20px] h-[20px]"
        data-tooltip-id={id}
        data-tooltip-content={content}
      >
        <Info />
      </div>
      <__Tooltip id={id} {...rest} />
    </>
  );
};

export default Tooltip;
