import React from "react";
import Google from "../../assets/images/google.svg";

const GoogleAuth = ({ buttonText }) => {
  const googleClient = process.env.REACT_APP_GOOGLE_CLIENT;
  const redirectURL = process.env.REACT_APP_REDIRECT_GOOGLE_URL;

  const openNewWindow = () => {
    localStorage.removeItem("user-data");
    localStorage.removeItem("token");
    localStorage.removeItem("youtube-data");
    if (!localStorage.getItem("token")) {
      // Specify the URL of the new window
      const url = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${googleClient}&redirect_uri=${redirectURL}&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&state=simple_passout_through&access_type=offline&prompt=consent&include_granted_scopes=true`;

      // Open the new window
      window.open(url, "_blank");
    }
  };
  return (
    <button
      onClick={openNewWindow}
      className="w-[75vw] max-w-[440px] lg:w-[440px] mt-[42px] h-16 p-5 bg-white rounded-md shadow justify-center items-center gap-[66px] flex"
    >
      <div className="justify-center items-center gap-3 flex">
        {/* <div className="w-6 h-6 relative" /> */}
        <img src={Google} alt="google-logo" />
        <div className="text-center text-zinc-800 text-base font-medium">
          {buttonText}
        </div>
      </div>
    </button>
  );
};

export default GoogleAuth;
