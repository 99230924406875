import { createSlice } from "@reduxjs/toolkit";

const suggestionData = createSlice({
  name: "suggestion",
  initialState: {
    suggestionData: [],
    suggestion_video_content_mychannel: [],
    suggestion_video_content_others: [],
    suggestion_video_short_data: [],
    suggestion_explore_ideas_data: [],
    suggestion_keyword_idea_section: [],
    suggestion_saved_ideas_data: [],
    loading: false,
    explore_keywords_loading: false,
    error: null,
  },

  reducers: {
    suggestionVideoContentData(state, action) {
      state.suggestion_video_content_mychannel = action.payload;
      //   state.push(action.payload);
    },
    suggestionVideoContentOthersData(state, action) {
      state.suggestion_video_content_others = action.payload;
    },
    suggestionVideoShortData(state, action) {
      state.suggestion_video_short_data = action.payload;
    },
    suggestionExploreIdeasData(state,action){
      state.suggestion_explore_ideas_data = action.payload
    },
    suggestionKeywordIdeaSection(state,action){
      state.suggestion_keyword_idea_section = action.payload
    },
    suggestionSavedIdeasData(state,action){
      state.suggestion_saved_ideas_data = action.payload
    },
    exploreKeyWordsLoading(state,action){
      state.explore_keywords_loading = action.payload
    }
    
  },
});
export const {
  suggestionVideoContentData,
  suggestionVideoShortData,
  suggestionVideoContentOthersData,
  suggestionExploreIdeasData,
  suggestionKeywordIdeaSection,
  suggestionSavedIdeasData,
  exploreKeyWordsLoading
} = suggestionData.actions;

export default suggestionData.reducer;
