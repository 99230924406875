import React, { useState, useEffect } from "react";
import Google from "../../assets/images/google.svg";
import TextBox from "./TextBox";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { baseURL } from "../../utils/const";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, Link } from "react-router-dom";
import GoogleAuth from "./GoogleAuth";
import { useDispatch } from "react-redux";
import { usePostHog } from "posthog-js/react";
import { addUserDataTemporary } from "../../redux/store/slice/user";
import postHogEvents from "../../constants/events";

const SignupComponent = () => {
  const [loader, setLoader] = useState(false);
  const posthog = usePostHog();
  const dispatch = useDispatch();

  // initialized();

  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
  };

  const validationSchema = Yup.object().shape({
    // username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    name: Yup.string().required("Full Name is required"),
  });

  const handleSubmit = async (values) => {
    setLoader(true);

    try {
      const response = await axios.post(baseURL + "/register/", {
        username: values?.email,
        name: values?.name,
        password: values?.password,
        email: values?.email,
        confirm_password: values?.confirmPassword,
      });
      toast.success(response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

      dispatch(addUserDataTemporary(response?.data?.data));
      localStorage.setItem("token", response?.data?.data?.token);
      localStorage.setItem("user-data", JSON.stringify(response?.data?.data));

      setTimeout(() => {
        setLoader(false);
        navigate("/verification", { state: values?.email });
      }, 2000);

      const eventData = {
        user: values.email,
      };
      posthog.identify(values.email);
      posthog.capture(postHogEvents.signUp, eventData);
    } catch (error) {
      console.error(error);
      toast.error("Please try again..!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);

      const eventData = {
        user: values.email,
      };
      posthog.capture(postHogEvents.signUpFailure, eventData);
    }
  };

  useEffect(() => {
    const handleBack = () => {
      localStorage.removeItem("user-data");
      localStorage.removeItem("token");
    };

    window.addEventListener("popstate", handleBack);

    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, []);

  return (
    <div className="">
      <ToastContainer />
      <div className="flex items-center justify-center flex-col mt-10">
        <div className="flex items-start justify-start flex-col w-[75vw] max-w-[440px] lg:w-[440px]">
          <div className="text-zinc-800 text-4xl font-bold">
            Welcome to the Club!!
          </div>
          <div className="mt-[20px] text-gray-600 text-base font-normal">
            Create account with us and boost your Youtube Journey
          </div>
        </div>
        <GoogleAuth buttonText="Register with Google" />

        <div className="mt-[42px] w-[75vw] max-w-[440px] lg:w-[440px] h-[15px] justify-start items-center gap-3 inline-flex">
          <div className="grow shrink basis-0 h-[0px] border border-black border-opacity-10"></div>
          <div className="text-center text-gray-600 text-xs font-medium">
            Or continue with
          </div>
          <div className="grow shrink basis-0 h-[0px] border border-black border-opacity-10"></div>
        </div>

        {/* form */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="">
            <TextBox
              type="text"
              name="name"
              id="name"
              placeholder="Full Name"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="text-red-500"
            />
            {/* <TextBox
              type="text"
              name="username"
              id="username"
              placeholder="username"
            />
            <ErrorMessage
              name="username"
              component="div"
              className="text-red-500"
            /> */}
            <TextBox
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              email
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500"
            />
            <TextBox
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              Field="Field"
              password
            />
            <ErrorMessage
              name="password"
              component="div"
              className="text-red-500"
            />
            <TextBox
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password"
              Field="Field"
              password
            />
            <ErrorMessage
              name="confirmPassword"
              component="div"
              className="text-red-500"
            />

            <button
              type="submit"
              //   disabled={loader}
              className="mt-[46px] w-[75vw] max-w-[440px] lg:w-[440px] h-[54px] px-[25px] py-4 bg-violet-600 rounded-[53px] justify-center items-center gap-2.5 inline-flex"
            >
              {loader ? (
                <TailSpin
                  height="20"
                  width="20"
                  color="white"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                <div className="text-white text-base font-semibold leading-snug">
                  Register
                </div>
              )}
            </button>
          </Form>
        </Formik>
      </div>
      <div className="flex items-center justify-center">
        <div className="mt-[46px] max-w-[440px] lg:w-[440px] h-[17px] justify-center items-center gap-2 inline-flex">
          <div className="text-center text-black text-sm font-normal">
            Already have an Account?
          </div>
          <Link to="/login" className="text-blue-500">
            <div className="text-violet-600 text-sm font-medium">Login</div>
          </Link>
        </div>
      </div>
      <div className="text-center my-[46px]">
        <span className="text-gray-500 text-sm font-normal">
          By signing up, I agree to Upview’s <br />
        </span>
        <a
          href="https://upview.ai/terms-conditions"
          target="_blank"
          rel="noopener noreferrer"
          className="text-zinc-800 text-sm font-normal underline"
        >
          Terms of Service
        </a>
        <span className="text-zinc-800 text-sm font-normal"> </span>
        <span className="text-gray-500 text-sm font-normal">and</span>
        <span className="text-zinc-800 text-sm font-normal"> </span>
        <a
          href="https://upview.ai/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="text-zinc-800 text-sm font-normal underline"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

export default SignupComponent;
