import PaymentSuccessImage from "../../assets/images/payment-success.png";
import "./payments-page.scss";

const PaymentSuccess = () => {
  return (
    <div id="modal">
      <div id="payment-success">
        <img
          src={PaymentSuccessImage}
          className="payment-success__image"
          alt="payment-success"
        />
        <div className="payment-success__container">
          <div className="payment-success__success-message">Success!!</div>
          <div className="payment-success__success-desc">
            You have successfully completed the payment.
          </div>
        </div>
        <button
          className="payment__button"
          onClick={() => window.location.replace("https://web.upview.ai/home")}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
