import React, { useState } from "react";
import ArrowRight from "../../assets/images/arrow-right.png";
import ArrowLeft from "../../assets/images/arrow-left.png";
import {
  addReferralData,
  onBoardState,
} from "../../redux/store/slice/Onboarding";
import { useDispatch, useSelector } from "react-redux";
const Referral = () => {
  const dispatch = useDispatch();
  const { onboard_referral_data } = useSelector((state) => state.Onboarding);
  const [referral_data, setReferralData] = useState(
    onboard_referral_data ? onboard_referral_data : ""
  );

  const submitReferral = () => {
    if (referral_data.length > 1) {
      dispatch(addReferralData(referral_data));
      dispatch(onBoardState("interested"));
    }
  };
  const backReferral = () => {
    dispatch(addReferralData(""));
    dispatch(onBoardState("hear_about"));
  };

  return (
    <div className="w-[50%] max-sm:w-[100%] md:w-[80%] lg:w-[60%] xl:w-[50%] flex justify-center">
      <div className="w-[100%] h-[100vh] max-sm:p-5 p-10 bg-white rounded-3xl flex-col justify-start items-center gap-16 flex">
        <div className="w-56 justify-start items-center flex">
          <div className="w-2 h-2 bg-green-600 rounded-full border" />
          <div className="grow shrink basis-0 h-px border border-green-600"></div>
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="w-2 h-2 bg-stone-300 rounded-full" />
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="w-2 h-2 bg-stone-300 rounded-full" />
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="grow shrink basis-0 h-px border border-black border-opacity-10"></div>
          <div className="w-2 h-2 bg-stone-300 rounded-full" />
        </div>
        <div className="w-[60%] h-[80%] max-sm:w-[100%] md:w-[100%] flex-col justify-between items-center gap-14 flex">
          <div className="w-[100%] max-sm:w-[100%] flex-col justify-start items-center gap-20 flex">
            <div className=" text-center text-zinc-800 text-3xl font-bold leading-10">
              How did you hear about us?
            </div>
            <div className="max-sm:w-[80%] flex-col justify-start items-start gap-3.5 flex">
              <div className="text-black text-base font-medium leading-snug">
                Who referred you?
              </div>
              <div className="w-96 max-sm:w-[100%]   h-12 relative">
                {/* <div className="w-96 h-12 left-0 top-0 absolute rounded border border-violet-600 border-opacity-20" /> */}
                <input
                  type="text"
                  value={referral_data}
                  placeholder="Enter the Name here"
                  className="w-96 max-sm:w-[100%] h-12 px-4 py-3 left-0 top-0 absolute rounded border border-violet-600 justify-start items-center inline-flex"
                  onChange={(e) => setReferralData(e.target.value)}
                />
                {/* <div className="w-px h-5 bg-violet-600 rounded-sm" />
                <div className="w-72 h-5 rounded-sm" /> */}
                {/* </input> */}
              </div>
            </div>
          </div>
          <div className="w-[50%] max-sm:w-[100%] md:w-[100%] justify-between items-start gap-4 inline-flex">
            <div
              className="px-8 py-3.5 bg-black bg-opacity-10 rounded-3xl justify-center items-center gap-2 flex cursor-pointer"
              onClick={() => backReferral()}
            >
              {/* <div className="w-5 h-5 relative origin-top-left -rotate-180" /> */}
              <div className="w-5 h-5 relative">
                <img src={ArrowRight} />
              </div>
              <div className="text-zinc-800 text-base font-semibold leading-normal">
                Back
              </div>
            </div>
            <div
              className={`px-8 py-3.5 ${
                !referral_data ? `cursor-not-allowed` : `cursor-pointer`
              } bg-violet-600
               rounded-3xl justify-center items-center gap-2 flex cursor-pointer `}
              onClick={() => {
                submitReferral();
              }}
            >
              <div
                className={`text-white  text-base font-semibold leading-normal`}
              >
                Next
              </div>
              <div className="w-5 h-5 relative">
                <img src={ArrowLeft} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
