import React, { useEffect, useState } from "react";
import Sidebar from "../components/common/Sidebar";
import Collobrate from "../components/collobrations/Collobrate";
import { useDispatch, useSelector } from "react-redux";
import {
  addCollaboratorData,
  addActiveChannel,
  setFirstTimeLoad,
  setShowCollabSidebar,
  setRequestedCollaborators,
} from "../redux/store/slice/collaborationSlice";
import {
  fetchApiData,
  fetchRequestCollabData,
} from "../redux/store/slice/api/apiCollaborationSlice";
import { usePostHog } from "posthog-js/react";
import logger from "../config/logger";

const Collaboration = () => {
  const [state, setState] = useState(false);
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const token = localStorage.getItem("token");
  const userDetails = localStorage.getItem("user-data");

  const { collabData, activeChannel } = useSelector(
    (state) => state.collaborations
  );
  const { data, loading } = useSelector((state) => state.apiCollaboration);

  useEffect(() => {
    logger.log('Collaboration page opened');
  }, [])

  useEffect(() => {
    if (loading === "idle") {
      dispatch(setFirstTimeLoad(true));
      dispatch(fetchRequestCollabData(token)).then((result) => {
        dispatch(setRequestedCollaborators(result.payload));
      });
      dispatch(fetchRequestCollabData(token));
      dispatch(fetchApiData(token, posthog, userDetails?.email));
      setState(!state);
    }
  }, [dispatch, loading, state, token, posthog]);

  useEffect(() => {
    if (data) {
      dispatch(setFirstTimeLoad(false));
      dispatch(setShowCollabSidebar(true));
      dispatch(addCollaboratorData(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (collabData.length > 0) {
      dispatch(addActiveChannel(collabData[0]));
    }
  }, [collabData, dispatch]);

  const channelLoading = useSelector(
    (state) => state?.collaborations?.channelLoading
  );

  const updateActiveChannel = (channel) => {
    if (!channelLoading) {
      dispatch(addActiveChannel(channel));
    }
  };

  return (
    <>
      <Sidebar
        layout={
          <Collobrate
            collaborationData={collabData}
            activeChannel={activeChannel}
            updateActiveChannel={updateActiveChannel}
          />
        }
      />
    </>
  );
};

export default Collaboration;
