import { formatBigNumbers } from "../../utils/utility-functions";

export function formatDateString(dateString) {
  const currentDate = new Date();
  const inputDate = new Date(dateString);
  const timeDifference = currentDate - inputDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else {
    return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
  }
}

const TrendingVideo = ({ video }) => {
  if (video.type === "video") {
    return (
      <a
        className="flex flex-col w-[100%] bg-slate-50 rounded-2xl border border-black border-opacity-10  sm:w-[48%] xl:w-[31%] lg:w-[30%]"
        href={video.url}
        target="_blank"
        rel="noreferrer"
      >
        <div className="w-[100%] h-[204px] rounded-2xl flex flex-col justify-end bg-cover relative">
          <img
            src={video.thumbnail_url}
            alt="img"
            className="w-[100%] h-[204px] object-cover rounded-t-xl rounded-b-2xl"
          />

          <div className="w-[100%]  h-20 pl-3 pb-2 rounded-b-2xl bg-black bg-opacity-25 backdrop-blur-[17px] flex-col justify-center items-start inline-flex absolute">
            <p className=" text-white text-xs font-semibold leading-normal">
              {video.title}
            </p>
            <div className="flex items-center gap-5 mt-3">
              <p className="text-white text-xs font-normal leading-none">
                {video.total_views && formatBigNumbers(video.total_views)} views
              </p>
              <p className="text-white text-xs font-normal leading-none">
                {video.published_at && formatDateString(video.published_at)}
              </p>
            </div>
          </div>
        </div>
      </a>
    );
  } else {
    return null;
  }
};

export default TrendingVideo;
