import React, { useState, useEffect, createContext } from "react";
import Sidebar from "../components/common/Sidebar";
import { FiChevronLeft } from "react-icons/fi";
import { Header } from "../components/common/header/Header";
import GeneratedContent from "../components/suggestions/generatedContentSuggestions";
import CreateContent from "../components/suggestions/createContentSuggestions";
import ContentSelect from "../components/suggestions/ContentSelectSuggestions";
import MakeVideosSort from "../components/suggestions/makeVideosSortSuggestions";
import ShortContentItem from "../components/suggestions/shortContentItemSuggestions";
import VideosGettingContentItem from "../components/suggestions/videosGettingContentItemSuggestions";
import SavedIdeas from "../components/contents/SavedIdeas";
import axios from "axios";
import { suggestionVideoContentData } from "../redux/store/slice/suggestionSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { baseURL } from "../utils/const";
import { TailSpin } from "react-loader-spinner";

import { fetchApiMyChannelData } from "../redux/store/slice/api/apiSuggestionVideoSlice";
import { fetchApiShortData } from "../redux/store/slice/api/apiShortSlice";
import { fetchApiOtherData } from "../redux/store/slice/api/apiSuggestionOtherSlice";
import logger from "../config/logger";

const url1 = baseURL + "/analytics/channel/video/top?timeframe=7"; // mychannel
const url = baseURL + "/analytics/channel/video/related"; // othersData
const url2 = baseURL + "/video/short"; // shorts

export const FilteredDataContext = createContext(); // context

const Suggestions = () => {
  const [state, setState] = useState(false);
  const [state1, setState1] = useState(false);
  const [state2, setState2] = useState(false);
  const [isLoading1, setIsloading1] = useState(false);
  const [savedIdea, setSavedIdea] = useState(false);
  const [myChannelData, setMyChannelData] = useState([]);
  const [othersData, setOthersData] = useState([]);
  const [sortCondition, setSortCondition] = useState(true);
  const [showVideoSuggestion, setVideosSuggestion] = useState(true);
  const [ShortsData, setShortsData] = useState([]);
  const [viewsText, setViewsText] = useState(false);
  const Token = localStorage.getItem("token");
  const {
    mychannelVideoData,
    loading,
    error,
    twentyEightDaysData,
    loading28day,
  } = useSelector((state) => state.apiSuggestionMyChannel);

  const [myChannelResponseData, setMyChannelResponseData] =
    useState(mychannelVideoData);
  const { shortData, loadingShort, errorShort } = useSelector(
    (state) => state.apiSuggestionShort
  );
  const { otherData, loadingOther, errorOther } = useSelector(
    (state) => state.apiSuggestionOther
  );
  const { activeState, activeDay, otheractiveDay } = useSelector(
    (state) => state.SortSlice
  );

  const { loadingOther28day, errorOther28day, otherData28day } = useSelector(
    (state) => state.apiOtherdata28Day
  );

  const dispatch = useDispatch();

  useEffect(() => {
    logger.log("Suggestions tab opened");
  }, []);

  const getSortTheResponse = (responseData) => {
    return responseData.sort(
      (video1, video2) => video1.views_7_days - video2.views_28_days
    );
  };

  const fetchedMyChannelVideosContentData = async () => {
    // fetching myChannel data
    logger.log("Fetching channel videos content data");
    const start_time = Date.now();
    setIsloading1(true);
    try {
      const response = await axios.get(url1, {
        headers: {
          Authorization: `token ${Token}`,
        },
      });
      const end_time = Date.now();
      const time = end_time - start_time;
      logger.success(`Successfully fetched channel videos data in ${time}`);
      setMyChannelData(response.data.data);
      setMyChannelResponseData(getSortTheResponse(response?.data?.data));
      dispatch(suggestionVideoContentData(response?.data?.data));
      setIsloading1(false);
    } catch (error) {
      logger.error(`Failed to fetch channel videos data`, error);
      setIsloading1(false);
    }
  };

  useEffect(() => {
    if (loading === "idle") {
      dispatch(fetchApiMyChannelData([Token, 7]));
      setState(!state);
    }
  }, [dispatch, loading]);

  // const fetchedOthersData = async () => {
  //   // fetching others Data
  //   setIsloading1(true);
  //   try {
  //     const response = await axios.get(url, {
  //       headers: {
  //         Authorization: `token ${Token}`,
  //       },
  //     });

  //     setOthersData(response?.data?.data);
  //     dispatch(suggestionVideoContentOthersData(response?.data?.data));
  //     setIsloading1(false);
  //   } catch (error) {
  //     setIsloading1(false);
  //   }
  // };

  useEffect(() => {
    if (loadingOther === "idle") {
      dispatch(fetchApiOtherData([Token, 7]));
      setState1(!state1);
    }
  }, [dispatch, loadingOther]);

  // const fetchedShortsContentData = async () => {
  //   // fetching shorts Data
  //   setIsloading2(true);
  //   try {
  //     const response = await axios.get(url2, {
  //       headers: {
  //         Authorization: `token ${Token}`,
  //       },
  //     });
  //     setShortsData(response?.data?.data);
  //     dispatch(suggestionVideoShortData(response?.data?.data));
  //     setIsloading2(false);
  //   } catch (error) {
  //     setIsloading2(false);
  //   }
  // };

  useEffect(() => {
    if (loadingShort === "idle") {
      dispatch(fetchApiShortData(Token));
      setState2(!state2);
    }
  }, [dispatch, loadingShort]);

  useEffect(() => {
    fetchedMyChannelVideosContentData();
    // fetchedOthersData();
    // fetchedShortsContentData();
  }, []);

  useEffect(() => {
    setMyChannelResponseData(mychannelVideoData);
  }, [mychannelVideoData]);

  function renderVideos(
    activeState,
    activeDay,
    myChannelResponseData,
    twentyEightDaysData,
    otherData,
    otherData28day
  ) {
    if (activeState === "myChannel") {
      const channelData =
        activeDay === 7 ? myChannelResponseData : twentyEightDaysData;
      if (!channelData) {
        return showNoData();
      }
      return channelData.map((each) => (
        <VideosGettingContentItem
          key={each.video_id}
          data={each}
          name="mychannel"
        />
      ));
    } else if (activeState === "others") {
      const channelData = otheractiveDay === 7 ? otherData : otherData28day;
      if (!channelData) {
        return showNoData();
      }
      return channelData.map((each) => (
        <VideosGettingContentItem
          key={each.video_id}
          data={each}
          name="other"
        />
      ));
    }
  }

  function renderShortContent(shortData) {
    if (!shortData) {
      return showNoData();
    } else {
      return shortData
        .slice(0, 6)
        .map((each) => <ShortContentItem key={each.video_id} data={each} />);
    }
  }

  const loader = () => {
    return (
      <div className="flex flex-col items-center justify-center w-full">
        <TailSpin
          height="40"
          width="40"
          color="#3b82f6"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  };

  const showNoData = () => {
    return (
      <div className="h-[10vh] w-[100%] text-center bg-slate-50 ">
        <p className="text-neutral-800 text-lg font-bold mt-8">
          No Videos Are Available
        </p>
      </div>
    );
  };

  const data = [
    {
      name: "Videos Getting Attention",
      value: "video",
      amplitudeTitle: "Video suggestions’ tab clicked on suggestions page",
      amplitudeAction: "video_sugg_click",
    },
    {
      name: "Shorts Getting Attention",
      value: "shorts_suggest",
      amplitudeTitle: "‘Short suggestions’ tab clicked on suggestions page",
      amplitudeAction: "short_sugg_click",
    },
    // { name: " AI Generated Ideas", value: "ai_generated" },
  ];

  return (
    <>
      <Sidebar
        layout={
          <>
            <Header title="Suggestions" />
            <div className="ml-3 p-6">
              <div className="flex justify-between mb-4">
                <h1 className="text-gray-500 text-2xl font-bold leading-loose">
                  Content ideas for your channel
                </h1>
                {/* <div
                  className="flex flex-row items-center gap-3 p-2 border-2 rounded cursor-pointer"
                  onClick={() => setSavedIdea(!savedIdea)}
                >
                  <FiChevronLeft />
                  <p className="text-gray-500 text-sm font-medium leading-snug">
                    Saved Ideas
                  </p>
                </div> */}
                <div
                  className="mr-7 mt-3 h-7 justify-start items-center gap-3 inline-flex  cursor-pointer"
                  onClick={() => setSavedIdea(!savedIdea)}
                >
                  <div className="pl-[5px] pr-[7px] py-1.5 bg-gray-200 rounded-[38px] justify-start items-start gap-2.5 flex">
                    <FiChevronLeft />
                  </div>
                  <div className="text-neutral-800 text-sm font-medium ">
                    Saved Ideas
                  </div>
                </div>
              </div>
              <div className="flex  flex-row flex-wrap gap-6 ">
                <GeneratedContent />
                <CreateContent />
              </div>

              <FilteredDataContext.Provider
                value={{
                  myChannelResponseData,
                  setShortsData,
                  setMyChannelResponseData,
                  showVideoSuggestion,
                  sortCondition,
                  setSortCondition,
                  setVideosSuggestion,
                  myChannelData,
                  setMyChannelData,
                  othersData,
                  setOthersData,
                  viewsText,
                  setViewsText,
                }}
              >
                <MakeVideosSort />
                <div className="flex flex-col p-3 ">
                  <ContentSelect data={data} />
                </div>

                {showVideoSuggestion ? (
                  <ul className="w-[100%] sm:flex justify-normal flex-wrap gap-4 p-2 sm:justify-between xl:justify-normal">
                    {loading === "pending" ||
                    loadingOther28day === "pending" ||
                    loading28day === "pending" ? (
                      loader()
                    ) : (
                      <>
                        {renderVideos(
                          activeState,
                          activeDay,
                          myChannelResponseData,
                          twentyEightDaysData,
                          otherData,
                          otherData28day
                        )}
                      </>
                    )}
                  </ul>
                ) : (
                  <ul className="w-[100%] sm:flex justify-normal flex-wrap gap-4 p-2 sm:justify-between xl:justify-normal">
                    {loadingShort === "pending" ? (
                      loader()
                    ) : (
                      <>{renderShortContent(shortData)}</>
                    )}
                  </ul>
                )}
              </FilteredDataContext.Provider>
              {savedIdea && (
                <div className="absolute top-0 right-0 w-[25%] max-sm:w-[100%] z-10">
                  <SavedIdeas setSavedIdea={setSavedIdea} />
                </div>
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default Suggestions;
