import React from "react";
import Sidebar from "../components/common/Sidebar";

const Overview = () => {
  return (
    <>
      <Sidebar layout={<div>Overview page</div>} />
    </>
  );
};

export default Overview;
