import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyImage from "../../assets/images/copy.png";
import XImage from "../../assets/images/x.png";
import { BsEyeSlash } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { useEffect } from "react";
import PasswordChangeUI from "./PasswordChangeUI";
import OtpScreen from "./OtpScreen";
import User2 from "../../assets/images/user-2.svg";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";
import { collabStatuschangeapi } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { addCollabStatus } from "../../redux/store/slice/user";
import { baseURL } from "../../utils/const";
import axios from "axios";
import DeleteAccount from "./settings/DeleteAccount";
import RightArrow from "../../assets/images/right-arrow.png";
import "./settings-modal.scss";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function SettingModal({ modalData, modelopen, setModalOpen }) {
  const cancelButtonRef = useRef(null);
  const [collabReady, setCollabReady] = useState(true);
  const [showPassword, setPasswordShow] = useState(false);
  const [userData, setUserData] = useState();
  const [youtubData, setUserYoutubeData] = useState();
  const [activeState, setActiveState] = useState("setting");
  const [status1, setStatus1] = useState(false);
  const [status2, setStatus2] = useState();

  const dispatch = useDispatch();
  const { collab_status } = useSelector((state) => state.userSlice);

  const Profile = () => {
    const channel_id = userData?.channel?.channel_id || youtubData?.channel_id;
    const url = `https://www.youtube.com/channel/${channel_id}`;
    window.open(url, "_blank");
  };
  const StudioRedirect = () => {
    const channel_id = userData?.channel?.channel_id || youtubData?.channel_id;
    const url = `https://studio.youtube.com/channel/${channel_id}`;
    window.open(url, "_blank");
  };

  const getCollabApiStatus = async () => {
    try {
      const aUrl = baseURL + `/collaborator/status`;
      const Token = localStorage.getItem("token");
      const config = {
        method: "get",
        url: aUrl,
        headers: {
          Authorization: `token ${Token}`,
        },
      };

      const resData = await axios(config);

      const response = resData.data.data.status;
      dispatch(addCollabStatus(response));
    } catch (error) {
    }
  };

  const CollabStatusChange = async () => {
    await collabStatuschangeapi(!collab_status)
      .then((res) => {
        dispatch(addCollabStatus(!collab_status));
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    getCollabApiStatus();
    setActiveState("setting");
    setUserData(JSON.parse(localStorage.getItem("user-data")));
    setUserYoutubeData(JSON.parse(localStorage.getItem("youtube-data")));
  }, [modelopen]);

  return (
    <Transition.Root show={modelopen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* sm:max-w-lg */}
              <Dialog.Panel
                className={`w-[50%] ${"h-[676px]"}  relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all max-sm:w-full `}
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-[100%] flex">
                  {/* <div className=" flex w-[100%]"> */}
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-[100%]">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      <div className="w-[100%] justify-between items-center gap-6 flex">
                        <div className="text-black text-2xl font-semibold">
                          Settings
                        </div>
                        <div
                          className="w-6 h-6 relative cursor-pointer"
                          onClick={() => setModalOpen(false)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <path
                              d="M18 6.5L6 18.5"
                              stroke="#5C566B"
                              strokeWidth="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6 6.5L18 18.5"
                              stroke="#5C566B"
                              strokeWidth="2"
                              strokeLinecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="w-[100%] h-px border border-black border-opacity-10 mt-5"></div>
                    </Dialog.Title>
                    {/*  */}
                    <div className="w-[100%] h-[100%]  justify-center items-start gap-10 flex mt-5 settings-modal-content">
                      {activeState === "passwordChangeScreen" ? (
                        <PasswordChangeUI setActiveState={setActiveState} />
                      ) : activeState === "otp" ? (
                        <OtpScreen setActiveState={setActiveState} />
                      ) : activeState === "delete_account" ? (
                        <DeleteAccount setActiveState={setActiveState} />
                      ) : (
                        <>
                          <div className="w-[25%] flex-col justify-start items-start gap-4 flex settings-tabs">
                            <div
                              className={` w-[100%] px-4 py-3  ${
                                activeState === "setting" && "bg-violet-600"
                              } rounded-xl justify-start items-start gap-3 flex cursor-pointer`}
                              onClick={() => setActiveState("setting")}
                            >
                              <div className="w-6 h-6 relative">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                                    stroke={`${
                                      activeState === "setting"
                                        ? "#FFFFFF"
                                        : "#5C566B"
                                    }`}
                                    stroke-width="1.6"
                                    strokeLinecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                                    stroke={`${
                                      activeState === "setting"
                                        ? "#FFFFFF"
                                        : "#5C566B"
                                    }`}
                                    stroke-width="1.6"
                                    strokeLinecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                              <div
                                className={` text-base font-semibold leading-normal ${
                                  activeState === "setting"
                                    ? "text-white"
                                    : "text-black"
                                }`}
                              >
                                Accounts
                              </div>
                            </div>
                            <div
                              className={` w-[100%] px-4 py-3 ${
                                activeState === "subscription" &&
                                "bg-violet-600"
                              } rounded-xl justify-start items-start gap-3 flex cursor-pointer`}
                              onClick={() => setActiveState("subscription")}
                            >
                              <div className="w-6 h-6 relative ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M12 2.00098V6.00098"
                                    stroke={`${
                                      activeState === "subscription"
                                        ? "#FFFFFF"
                                        : "#5C566B"
                                    }`}
                                    // stroke="#5C566B"

                                    stroke-width="1.92"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12 18V22"
                                    stroke={`${
                                      activeState === "subscription"
                                        ? "#FFFFFF"
                                        : "#5C566B"
                                    }`}
                                    stroke-width="1.92"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M4.92773 4.93066L7.75773 7.76066"
                                    stroke={`${
                                      activeState === "subscription"
                                        ? "#FFFFFF"
                                        : "#5C566B"
                                    }`}
                                    stroke-width="1.92"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M16.2383 16.2402L19.0683 19.0702"
                                    stroke={`${
                                      activeState === "subscription"
                                        ? "#FFFFFF"
                                        : "#5C566B"
                                    }`}
                                    stroke-width="1.92"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M1.99805 12H5.99805"
                                    stroke={`${
                                      activeState === "subscription"
                                        ? "#FFFFFF"
                                        : "#5C566B"
                                    }`}
                                    stroke-width="1.92"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M18 12H22"
                                    stroke={`${
                                      activeState === "subscription"
                                        ? "#FFFFFF"
                                        : "#5C566B"
                                    }`}
                                    stroke-width="1.92"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M4.92773 19.0702L7.75773 16.2402"
                                    stroke={`${
                                      activeState === "subscription"
                                        ? "#FFFFFF"
                                        : "#5C566B"
                                    }`}
                                    stroke-width="1.92"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M16.2383 7.76066L19.0683 4.93066"
                                    stroke={`${
                                      activeState === "subscription"
                                        ? "#FFFFFF"
                                        : "#5C566B"
                                    }`}
                                    stroke-width="1.92"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                              <div
                                className={` text-base font-semibold leading-normal ${
                                  activeState === "subscription"
                                    ? "text-white"
                                    : "text-gray-600"
                                }`}
                              >
                                Subscription
                              </div>
                            </div>
                          </div>

                          {activeState === "setting" ? (
                            <div className=" w-[75%]  flex-col justify-start items-start gap-6 flex settings-content-width">
                              <div className="  flex-col justify-start items-start gap-3 flex">
                                <div className=" flex-col justify-start items-start gap-2 flex">
                                  <div className="text-zinc-800 text-sm font-semibold leading-normal">
                                    Linked Youtube Channel
                                  </div>
                                  <div className=" text-gray-600 text-xs font-normal leading-tight">
                                    The Youtube channel linked with Upview. All
                                    the Suggestions and Insights are based on
                                    this account.
                                  </div>
                                </div>
                                <div className="w-[100%] justify-between items-center gap-4 flex">
                                  <div
                                    className="pl-2 pr-4 py-2 rounded-full border border-black border-opacity-20 justify-start items-center gap-3 flex cursor-pointer"
                                    onClick={() => Profile()}
                                  >
                                    <img
                                      src={
                                        userData?.channel?.image_url
                                          ? userData?.channel?.image_url
                                          : youtubData?.image_url
                                          ? youtubData?.image_url
                                          : User2
                                      }
                                      className="w-10 h-10 rounded-full  cursor-pointer object-cover"
                                      alt="profile"
                                    />

                                    <div className="text-zinc-800 text-sm font-semibold">
                                      {userData?.channel?.title ||
                                        youtubData?.title}
                                      {/* {userData && userData?.channel?.title} */}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="text-violet-600 text-xs font-medium underline cursor-pointer"
                                  onClick={() => StudioRedirect()}
                                >
                                  Go to YouTube Studio
                                </div>
                              </div>
                              <div className=" h-px border border-black border-opacity-10"></div>
                              <div className="w-[100%] justify-between items-center gap-3 flex">
                                <div className="text-zinc-800 text-sm font-semibold leading-normal">
                                  Collaboration
                                </div>
                                <div
                                  className={`p-1.5  ${
                                    collab_status
                                      ? "bg-lime-600  transition-all ease-linear duration-300"
                                      : "bg-gray-400  transition-all duration-300"
                                  } rounded-3xl border justify-start items-center gap-2 flex transition-all `}
                                >
                                  {!collab_status && (
                                    <div
                                      className="p-1 bg-white rounded-2xl justify-start items-start gap-2.5 flex cursor-pointer"
                                      onClick={() => CollabStatusChange()}
                                    >
                                      <div className="w-3 h-3 relative">
                                        <img
                                          alt="right-arrow"
                                          src={RightArrow}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="pl-1 justify-start items-start gap-2.5 flex">
                                    <div className="text-center text-white text-xs font-semibold">
                                      Ready to Collab
                                    </div>
                                  </div>

                                  {collab_status && (
                                    <div
                                      className="p-1 bg-white rounded-2xl justify-start items-start gap-2.5 flex cursor-pointer"
                                      onClick={() => CollabStatusChange()}
                                    >
                                      <div className="w-3 h-3 relative">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          viewBox="0 0 12 12"
                                          fill="none"
                                        >
                                          <path
                                            d="M10 3L4.5 8.5L2 6"
                                            stroke="#20AD20"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className=" h-px border border-black border-opacity-10"></div>
                              <div className="w-[100%]  flex-col justify-between items-start gap-3 flex">
                                <div className="text-zinc-800 text-sm font-semibold leading-normal">
                                  Password
                                </div>
                                <div className="w-[100%] justify-between items-center gap-3 flex">
                                  {/*  */}
                                  <div className=" px-3 py-2 rounded-lg border border-black border-opacity-10 justify-between items-center gap-0.5 inline-flex">
                                    <input
                                      type={showPassword ? "text" : "password"}
                                      className="justify-start items-center gap-0.5 flex border-none focus:outline-none"
                                      defaultValue={"Test@123"}
                                      onFocus={(e) => {
                                        e.target.style.outline = "none";
                                        e.target.style.boxShadow = "none";
                                      }}
                                    />
                                    <div
                                      className="w-3 h-3 relative cursor-pointer"
                                      onClick={() =>
                                        setPasswordShow(!showPassword)
                                      }
                                    >
                                      {showPassword ? (
                                        <BsEyeSlash />
                                      ) : (
                                        <AiOutlineEye />
                                      )}
                                    </div>
                                  </div>
                                  {/*  */}

                                  <div
                                    className="text-gray-600 text-sm font-medium underline cursor-pointer"
                                    onClick={() => setActiveState("otp")}
                                  >
                                    Change Password
                                  </div>
                                </div>
                              </div>
                              <div className=" h-px border border-black border-opacity-10"></div>
                              <div className="justify-start items-center gap-1.5 flex">
                                <div className="w-4 relative">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                  >
                                    <path
                                      d="M2 4.5H3.33333H14"
                                      stroke="#D66060"
                                      stroke-width="1.6"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M12.6663 4.50004V13.8334C12.6663 14.187 12.5259 14.5261 12.2758 14.7762C12.0258 15.0262 11.6866 15.1667 11.333 15.1667H4.66634C4.31272 15.1667 3.97358 15.0262 3.72353 14.7762C3.47348 14.5261 3.33301 14.187 3.33301 13.8334V4.50004M5.33301 4.50004V3.16671C5.33301 2.81309 5.47348 2.47395 5.72353 2.2239C5.97358 1.97385 6.31272 1.83337 6.66634 1.83337H9.33301C9.68663 1.83337 10.0258 1.97385 10.2758 2.2239C10.5259 2.47395 10.6663 2.81309 10.6663 3.16671V4.50004"
                                      stroke="#D66060"
                                      stroke-width="1.6"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M6.66699 7.83337V11.8334"
                                      stroke="#D66060"
                                      stroke-width="1.6"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9.33301 7.83337V11.8334"
                                      stroke="#D66060"
                                      stroke-width="1.6"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                                <div className="text-red-400 text-sm font-medium underline">
                                  Delete Account
                                </div>
                              </div>
                            </div>
                          ) : activeState === "subscription" ? (
                            <div className="w-[75%]  flex-col justify-start items-start gap-4 flex">
                              <div className="text-zinc-800 text-base font-semibold leading-normal">
                                Current Subscription Plan
                              </div>
                              <div className="w-[100%] flex-col justify-start items-start gap-3 flex">
                                <div className="w-[100%] p-5 bg-white rounded-2xl border border-violet-600 justify-start items-start gap-4 flex">
                                  <div className=" w-[100%] flex-col justify-start items-start gap-3 flex">
                                    <div className="w-[100%] justify-between items-center gap-10 flex">
                                      <div className="text-zinc-800 text-xl font-bold">
                                        Free Version
                                      </div>
                                      {/* <div className="justify-start items-baseline gap-1 flex">
                                        <div className="px-6 py-3 bg-violet-600 rounded-full justify-center items-center gap-2.5 flex cursor-pointer">
                                          <div className="text-white text-xs font-bold leading-tight">
                                            Upgrade to Pro
                                          </div>
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
