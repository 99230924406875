const ShortContentItem = (props) => {
  const { data } = props;

  const getTotalViews = () => {
    if (data.views_total < 1000) {
      return data.views_total;
    } else if (data.views_total >= 1000 && data.views_total < 1000000) {
      return Math.floor(data.views_total / 1000) + "K";
    } else {
      return Math.floor(data.views_total / 1000000) + "M";
    }
  };

  const onRedirectToVideoOnYoutube = () => {
    const youtubeVideoURL = `https://www.youtube.com/shorts/${data.video_id}`;
    window.open(youtubeVideoURL, "_blank");
  };

  return (
    <li
      className=" flex flex-col justify-between w-[100%] p-6 bg-slate-50 rounded-xl border border-black border-opacity-10 mb-3 sm:w-[45%] xl:w-[32%]"
      onClick={onRedirectToVideoOnYoutube}
    >
      <div className="w-[100%] flex cursor-pointer">
        <img
          src={data.thumbnail}
          alt="img"
          className="w-[87px] h-[90px] rounded mr-3 object-cover"
        />
        <div>
          <p className="w-[100%] text-neutral-700 text-[15px] font-semibold mb-2 overflow-hidden">
            {data.title}
          </p>
        </div>
      </div>

      <div className="flex justify-between items-center mt-3">
        <div className="w-[49%] h-[68px] p-4 rounded-lg border border-black border-opacity-10 flex-col justify-center items-start gap-1 inline-flex">
          <p className="text-stone-950 text-sm font-bold">
            {getTotalViews()} Views
          </p>
          <p className=" text-stone-500 text-xs font-normal">In Last 7 days </p>
        </div>
        {data.averageViewPercentage && (
          <div className="w-[49%] h-[68px] p-4 rounded-lg border border-black border-opacity-10 flex-col justify-center items-start gap-1 inline-flex">
            <p className="text-stone-950 text-sm font-bold">
              {data.averageViewPercentage}%
            </p>
            <p className=" text-stone-500 text-xs font-normal">
              Percentage Viewed
            </p>
          </div>
        )}
      </div>
    </li>
  );
};

export default ShortContentItem;
