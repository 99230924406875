import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { BsThreeDotsVertical } from "react-icons/bs";
import { baseURL } from "../../utils/const";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { suggestionSavedIdeasData } from "../../redux/store/slice/suggestionSlice";
import { TailSpin } from "react-loader-spinner";
import Popover from "../suggestions/popover";

import Modal from "./Modal";

const url = baseURL + "/llm/savecontent";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const SavedIdeas = ({ setSavedIdea }) => {
  const [savedIdeasContent, setSavedIdeasContent] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modelopen, setModalOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const dispatch = useDispatch();
  const saved_ideas_data = useSelector(
    (state) => state.suggestion_data.suggestion_saved_ideas_data
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsSticky(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const deleteSavedIdeas = async (id) => {
    const Token = localStorage.getItem("token");

    try {
      const response = await axios.delete(`${url}?content_id=${id}`, {
        headers: {
          Authorization: `token ${Token}`,
        },
      });

      if (response.status === 200) {
        // Item was successfully deleted
        getSavedIdeasData();
      }
    } catch (error) {
    }
  };

  const getSavedIdeasData = async () => {
    const Token = localStorage.getItem("token");
    setLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `token ${Token}`,
        },
      });

      setSavedIdeasContent(response.data.data);
      dispatch(suggestionSavedIdeasData(response.data.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSavedIdeasData();
  }, []);

  const getSavedDate = (createdAt) => {
    const dateObj = new Date(createdAt);
    const day = dateObj.getUTCDate();
    const monthIndex = dateObj.getUTCMonth();
    const year = dateObj.getUTCFullYear();
    const month = months[monthIndex];
    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  const ShowModal = (i) => {
    setModalData(i);
    setModalOpen(true);
  };

  const loading = () => {
    return (
      <div className="w-[100%] flex flex-row justify-center">
        <TailSpin
          height="40"
          width="40"
          color="#3b82f6"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  };

  const onDelete = (id) => {
    deleteSavedIdeas(id);
  };

  return (
    <>
      <div
        className={`${
          isSticky ? "top-0" : "top-[11%]"
        } fixed h-[100vh] w-[100%] px-5 py-7 bg-white border-l border-black border-opacity-10 flex-col justify-start items-start gap-5 inline-flex overflow-scroll sm:w-[25%]`}
      >
        <div
          className=" pl-4 pr-10 py-3 rounded-xl backdrop-blur-2xl justify-start items-center gap-3 inline-flex cursor-pointer mt-4"
          onClick={() => setSavedIdea(false)}
        >
          <div className="pl-1 pr-1.5 py-1.5 bg-gray-200 rounded-3xl justify-start items-start gap-2.5 flex">
            <div className="w-4 h-4 relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6 4L10 8L6 12"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <div className="text-black text-xl font-bold">Saved Ideas</div>
        </div>

        <div className="w-[100%] flex-col justify-center items-start gap-3 flex scroll">
          {isLoading ? (
            loading()
          ) : (
            <>
              {saved_ideas_data?.map((i, idx) => (
                <div
                  key={idx}
                  className="mr-2 w-[100%] p-4 bg-neutral-100 rounded-lg flex-col justify-start items-start gap-3 flex overflow-hidden "
                >
                  <div className="w-[100%]  justify-between items-center flex">
                    <div className="w-[100%] text-neutral-800 text-sm font-bold">
                      {i.title.slice(0, 25).replace(/'/g, "")}...
                    </div>

                    <Popover onDelete={onDelete} id={i.id} />
                  </div>
                  <p className="text-gray-500 text-xs font-semibold leading-[18px]">
                    {getSavedDate(i.created_at)}
                  </p>
                  <div
                    className="flex-col justify-start items-start gap-3 flex cursor-pointer "
                    onClick={() => ShowModal(i)}
                  >
                    <div className=" text-gray-500 text-xs font-normal leading-none mb-2">
                      {i.description.slice(0, 150).split(":")[1]}...
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      <Modal
        modalData={modalData}
        modelopen={modelopen}
        setModalOpen={setModalOpen}
      />
    </>
  );
};

export default SavedIdeas;
