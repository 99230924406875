import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyImage from "../../assets/images/copy.png";
import XImage from "../../assets/images/x.png";
import { TiTick } from "react-icons/ti";
import { useEffect } from "react";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function Modal({ modalData, modelopen, setModalOpen }) {
  const [copytitle, setCopyTitle] = useState(false);
  const [copyDesc, setCopyDesc] = useState(false);
  const [copyTags, setCopyTags] = useState(false);

  const [hashTag, setHasTagData] = useState();

  const cancelButtonRef = useRef(null);

  const dateFormat = (createdAt) => {
    const dateObj = new Date(createdAt);
    const day = dateObj.getUTCDate();
    const monthIndex = dateObj.getUTCMonth();
    const year = dateObj.getUTCFullYear();
    const month = months[monthIndex];
    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };

  useEffect(() => {
    setCopyTitle(false);
    setCopyDesc(false);
    setCopyTags(false);
  }, [modelopen]);

  useEffect(() => {
    if (modalData?.tags) {
      if (modalData.tags.includes("#")) {
        setHasTagData(modalData?.tags.trim().split("#"));
      } else {
        setHasTagData(modalData?.tags.trim().split(","));
      }
    }
  }, [modelopen]);

  return (
    <Transition.Root show={modelopen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={cancelButtonRef}
        onClose={() => setModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className=" relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-2/4 ">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-[100%]">
                  <div className="sm:flex sm:items-start w-[100%]">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-[100%]">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        <div className="w-[100%] flex-col justify-start items-start gap-2 inline-flex">
                          <div className="w-[100%] justify-between items-start gap-2 flex">
                            <div className="justify-start items-start gap-4 flex">
                              <div className="w-[85%] text-neutral-800 text-xl font-bold leading-7 text-justify">
                                {modalData?.title.replace(/'/g, "")}
                              </div>
                              <div className="w-5 h-5 relative cursor-pointer mt-1">
                                <CopyToClipboard
                                  text={modalData?.title.replace(/'/g, "")}
                                >
                                  <button
                                    type="button"
                                    className="border-none bg-transparent outline-none"
                                    onClick={() => {
                                      setCopyDesc(false);
                                      setCopyTitle(true);
                                      setCopyTags(false);
                                    }}
                                  >
                                    {copytitle ? (
                                      <TiTick color="green" size="25px" />
                                    ) : (
                                      <img
                                        src={CopyImage}
                                        alt="copy desc"
                                        onClick={() => setCopyTitle(true)}
                                      />
                                    )}
                                  </button>
                                </CopyToClipboard>
                              </div>
                            </div>
                            <div className="w-6 h-6 relative cursor-pointer">
                              <img
                                src={XImage}
                                alt="x"
                                onClick={() => setModalOpen(!modelopen)}
                              />
                            </div>
                          </div>
                          <div className="text-gray-500 text-xs font-semibold leading-none">
                            {dateFormat(modalData?.created_at)}
                          </div>
                          <div className="w-[100%] mt-4 h-px border border-black border-opacity-20"></div>
                        </div>
                      </Dialog.Title>
                      <div className="mt-5 p-2 main w-[100%]">
                        <div className="w-[100%]  justify-start items-start gap-5 inline-flex">
                          <div className="grow shrink basis-0 text-zinc-800 text-base font-normal leading-normal text-justify">
                            {modalData?.description.split(":")[1]}
                          </div>
                          <div className="w-5 h-5 relative">
                            <CopyToClipboard
                              text={modalData?.description.split(":")[1]}
                            >
                              <button
                                type="button"
                                className="border-none bg-transparent outline-none"
                                onClick={() => {
                                  setCopyDesc(true);
                                  setCopyTitle(false);
                                  setCopyTags(false);
                                }}
                              >
                                {copyDesc ? (
                                  <TiTick color="green" size="25px" />
                                ) : (
                                  <img
                                    src={CopyImage}
                                    alt="copy desc"
                                    onClick={() => setCopyDesc(true)}
                                  />
                                )}
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                        {/*  */}
                        <div className="w-[100%] mt-5 flex-col justify-start items-start gap-6 flex">
                          <div className="w-[100%] justify-between items-center gap-6 flex">
                            <div className="text-neutral-800 text-base font-bold">
                              Use these Keywords
                            </div>
                            <div className="w-5 h-5 relative">
                              <CopyToClipboard text={modalData?.tags}>
                                <button
                                  type="button"
                                  className="border-none bg-transparent outline-none"
                                  onClick={() => {
                                    setCopyDesc(false);
                                    setCopyTitle(false);
                                    setCopyTags(true);
                                  }}
                                >
                                  {copyTags ? (
                                    <TiTick color="green" size="25px" />
                                  ) : (
                                    <img
                                      src={CopyImage}
                                      alt="copy desc"
                                      onClick={() => setCopyTags(true)}
                                    />
                                  )}
                                </button>
                              </CopyToClipboard>
                            </div>
                          </div>
                          <div className="w-[100%] flex-wrap justify-start items-start gap-3 flex">
                            {hashTag
                              ?.filter((item) => item.trim() !== "")
                              .map((hash) => (
                                <div className=" h-9 px-5 py-1.5 bg-gray-200 rounded-3xl justify-center items-center gap-2.5 flex">
                                  <div className="text-zinc-800 text-sm font-medium leading-normal mb-0.5">
                                    {hash}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
