import React, { useEffect } from "react";
import Sidebar from "../components/common/Sidebar";
import HomeMiddle from "../components/Home/Home";
import { useNavigate } from "react-router-dom";
import logger from "../config/logger";

const HomePage = () => {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   const data = JSON.parse(localStorage.getItem("user-data"));
  //   if (data) {
  //     if (data.authorization_status == "false") {
  //       navigate("/account-registered");
  //     }
  //   }
  // }, []);

  useEffect(() => {
    logger.log("User came to /home page");
  }, []);

  return (
    <>
      <Sidebar layout={<HomeMiddle />} />
    </>
  );
};

export default HomePage;
