import React from "react";
import ProfileCard from '../sidebar/profile';
import "./suggestionsTab.css";

const SuggestionsTab = ({
  collaborationData,
  activeChannel,
  updateActiveChannel,
}) => {
  return (
    <div className="flex max-w-full flex-col gap-5 items-center xl:w-[340px] justify-start self-stretch shrink-0  relative custom-max-height max-h-[110vh]">
      {collaborationData.length > 0 ? (
        collaborationData.map((channel) => (
          <ProfileCard
            key={channel.channel_title}
            name={channel.channel_title}
            similarity={channel.similarity_scores}
            imageUrl={channel.image_url}
            isActive={activeChannel?.channel_id === channel?.channel_id}
            updateActiveChannel={updateActiveChannel}
            channel={channel}
          />
        ))
      ) : (
        <div className="flex justify-center items-center w-full">
          <p>No creators found.</p>
        </div>
      )}
    </div>
  );
};

export default SuggestionsTab;
