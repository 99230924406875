import React, { useEffect, useState } from "react";
import SuggestionTitle from "./Title";
import IdeaSection from "./IdeaSection";
import SavedIdeas from "../contents/SavedIdeas";
import { Header } from "../common/header/Header";
import logger from "../../config/logger";

const SuggetionsExplore = () => {
  const [savedIdea, setSavedIdea] = useState(false);
  const [remainingCount, setRemainingCount] = useState(undefined);

  useEffect(() => {
    logger.log("Explore suggestions page opened");
  }, []);

  return (
    <>
      <div className="w-[100%] relative">
        <Header
          title={"Suggestions  > AI Generated Ideas"}
          remainingCount={remainingCount}
        />
        <SuggestionTitle setSavedIdea={setSavedIdea} />
        <IdeaSection
          title={"Top Ideas for your channel"}
          setRemainingCount={setRemainingCount}
          remainingCount={remainingCount}
        />
      </div>
      {savedIdea && (
        <div className="absolute top-0 right-0 w-[25%] max-sm:w-[100%] z-10">
          <SavedIdeas setSavedIdea={setSavedIdea} />
        </div>
      )}
    </>
  );
};

export default SuggetionsExplore;
