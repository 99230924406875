import React from "react";
import ProfileCard from './sidebar/profile';
import { useSelector } from "react-redux";

const SavedCollaboratorsTab = ({ savedCollaborators, updateActiveChannel }) => {
  const activeChannel = useSelector(
    (state) => state?.collaborations.activeChannel
  );

  return (
    <div className="flex max-w-full flex-col gap-5 items-start xl:w-[340px] justify-start self-stretch shrink-0 relative max-h-[100vh]">
      {savedCollaborators.map((result) => (
        <ProfileCard
          key={result.id}
          name={result.title}
          imageUrl={result.image_url}
          isSavedCollab={true}
          channel={result}
          isActive={activeChannel?.channel_id === result?.channel_id}
          similarity={result.similarity_scores}
          updateActiveChannel={updateActiveChannel}
        />
      ))}
    </div>
  );
};

export default SavedCollaboratorsTab;
