// apiCollaborationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../../../utils/const";
import logger from "../../../../config/logger";

// Replace 'your-api-endpoint' with your actual API endpoint
const apiEndpoint = baseURL;
const authToken = localStorage.getItem("token");

export const fetchApiOtherData = createAsyncThunk(
  "/analytics/channel/video/related",
  async (data) => {
    logger.log("Fetching channel related data");

    const config = {
      headers: {
        Authorization: `token ${data[0]}`,
      },
    };

    const start_time = Date.now();
    const response = await axios.get(
      apiEndpoint + `/analytics/channel/video/related?timeframe=${data[1]}`,
      config
    );
    const end_time = Date.now();
    logger.log(`Fetched channel related data in ${end_time - start_time}`, {
      status: response.status,
    });

    return response?.data?.data;
  }
);
// export const fetchApiOtherData28day = createAsyncThunk(
//   "/analytics/channel/video/related",
//   async (data) => {
//     const config = {
//       headers: {
//         Authorization: `token ${data[0]}`,
//       },
//     };
//     const response = await axios.get(
//       apiEndpoint + `/analytics/channel/video/related?${28}`,
//       config
//     );
//     return response?.data?.data;
//   }
// );

const apiSuggestionOtherSlice = createSlice({
  name: "api",
  initialState: {
    otherData: null,
    loadingOther: "idle",
    errorOther: null,
  },
  reducers: {
    // daysApiStatusChange(state, action) {
    //   if (action.payload === 7) {
    //     state.othersevenDaysApiCall = true;
    //   }
    //   if (action.payload === 28) {
    //     state.twentyEightDaysApiCall = true;
    //   }
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiOtherData.pending, (state) => {
        state.loadingOther = "pending";
      })
      .addCase(fetchApiOtherData.fulfilled, (state, action) => {
        state.loadingOther = "succeeded";
        state.otherData = action.payload;
      })
      .addCase(fetchApiOtherData.rejected, (state, action) => {
        state.loadingOther = "failed";
        state.errorOther = action.error.message;
      });
    // .addCase(fetchApiOtherData28day.pending, (state) => {
    //   state.loadingOther28day = "pending";
    // })
    // .addCase(fetchApiOtherData28day.fulfilled, (state, action) => {
    //   state.loadingOther28day = "succeeded";
    //   state.otherData28day = action.payload;
    // })
    // .addCase(fetchApiOtherData28day.rejected, (state, action) => {
    //   state.loadingOther28day = "failed";
    //   state.errorOther28day = action.error.message;
    // });
  },
});

export default apiSuggestionOtherSlice.reducer;
