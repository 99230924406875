import React from "react";
import Sidebar from "../components/common/Sidebar";
import SuggetionsExplore from "../components/suggetions/Suggetions";

const SuggetionExplore = () => {
  return (
    <>
      <Sidebar layout={<SuggetionsExplore />} />
    </>
  );
};

export default SuggetionExplore;
