import axios from "axios";

const sendLog = async (inputData, type, additionalData) => {
  const url = "https://log-api.newrelic.com/log/v1";
  const data = {
    message: inputData,
    env: process.env.REACT_APP_ENV,
    type: type ?? "log",
    user:
      window !== undefined &&
      JSON.parse(localStorage.getItem("user-data"))?.email,
    ...additionalData,
  };
  const headers = {
    "Content-Type": "application/json",
    "Api-Key": process.env.REACT_APP_NEW_RELIC_KEY,
  };

  await axios.post(url, data, { headers: headers });
};

class Logger {
  success(string, data) {
    sendLog(string, "success", data);
  }

  error(string, data) {
    sendLog(string, "failure", data);
  }

  log(string, data) {
    sendLog(string, "", data);
  }
}

const logger = new Logger();
export default logger;
