import { useState, useEffect, useRef } from "react";
import { TailSpin } from "react-loader-spinner";
import nocollaborators from "../../assets/images/nocollaborators.png";
import axios from "axios";
import { getToken } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../utils/const";
import {
  addActiveChannel,
  setChannelLoading,
  setSavedCollaborators,
  setShowCollabSidebar,
  setShowSaveCollabButton,
  addSavedCollabsData,
  setApiSavedCollaborators,
  addApiSavedCollaborators,
  setPreviousActiveChannel,
} from "../../redux/store/slice/collaborationSlice";
import SuggestionsTab from "./suggestions/SuggestionsTab";
import Tooltip from "../common/Tooltip";
import { usePostHog } from "posthog-js/react";
import SavedCollaboratorsTab from "./SavedCollaboratorsTab";
import { formatBigNumbers } from "../../utils/utility-functions";
import postHogEvents from "../../constants/events";
import logger from "../../config/logger";

const apiKey = process.env.REACT_APP_API_KEY;

const calculateAccountAge = (accountCreatedAt) => {
  const currentDate = new Date();
  const createdAtDate = new Date(accountCreatedAt);
  const yearDiff = currentDate.getFullYear() - createdAtDate.getFullYear();
  const monthDiff = currentDate.getMonth() - createdAtDate.getMonth();
  const dayDiff = currentDate.getDate() - createdAtDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    return yearDiff - 1;
  } else {
    return yearDiff;
  }
};

const formatAccountAge = (accountAge) => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - accountAge;
  return `Started in ${startYear}`;
};

const CollabSidebar = ({
  collaborationData,
  activeChannel,
  updateActiveChannel,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSavedCollaborators, setShowSavedCollaborators] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("suggestions");
  const [savingCollaborator, setSavingCollaborator] = useState(false);
  const [isSavedCollabsApiLoaded, setIsSavedCollabsApiLoaded] = useState(false);
  const userData = JSON.parse(localStorage.getItem("user-data"));
  const posthog = usePostHog();

  const apiSavedCollaborators = useSelector(
    (state) => state?.collaborations?.apiSavedCollaborators
  );

  const savedCollabsData = useSelector(
    (state) => state?.collaborations?.savedCollabsData
  );

  const showCollabSidebar = useSelector(
    (state) => state?.collaborations?.showCollabSidebar
  );

  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const handleSelectSearchResult = async (result) => {
    logger.log("Getting select search result");
    try {
      dispatch(setShowSaveCollabButton(true));
      dispatch(addActiveChannel(""));
      dispatch(setChannelLoading(true));
      const channelId = result.id.channelId;
      const sessionToken = getToken();
      if (!sessionToken) {
        console.error("Access token not found.");
        return null;
      }

      const headers = {
        Authorization: `token ${sessionToken}`,
      };

      const aUrl = `${baseURL}/collaborators/manualcollab?channel_id=${channelId}`;
      // TODO /collaborators/manualcollab?channel_id=${channelId}
      //analytics/manual/test
      const start = Date.now();
      const response = await axios.get(aUrl, {
        headers: headers,
      });
      const end = Date.now();
      const time = end - start;
      logger.success(`Fetched channel data in ${time}`);

      if (response?.data?.data.length > 0) {
        updateActiveChannel(response?.data?.data[0]);
        dispatch(setChannelLoading(false));
      } else {
        updateActiveChannel({ channel_id: channelId, error: "No data found" });
        dispatch(setChannelLoading(false));
      }
      return response?.data?.data[0];
    } catch (error) {
      logger.error("Error fetching active channel data", error);
      console.error("Error fetching active channel:", error);
      dispatch(setChannelLoading(false));
      return null;
    }
  };

  const handleSaveCollaborator = async (result) => {
    logger.log("Saving collaborator");
    try {
      setSavingCollaborator(result?.id?.channelId);
      const channelId = result?.id?.channelId;
      const title = result?.snippet?.title;
      const imageUrl = result?.snippet?.thumbnails?.default?.url;

      if (
        apiSavedCollaborators.some(
          (collaborator) => collaborator.channel_id === channelId
        )
      ) {
        setSavingCollaborator("");
        return;
      }

      const activeChannel = await handleSelectSearchResult(result);
      const similarityScore = activeChannel?.similarity_scores;

      const collaboratorData = {
        channel_id: channelId,
        title,
        image_url: imageUrl,
        similarity_scores: similarityScore,
      };

      dispatch(addApiSavedCollaborators(collaboratorData));

      const sessionToken = getToken();
      if (!sessionToken) {
        console.error("Access token not found.");
        return;
      }

      const headers = {
        Authorization: `token ${sessionToken}`,
      };

      const data = {
        channel_id: channelId,
        title: title,
        image_url: imageUrl,
        similarity_scores: similarityScore,
      };

      const startTime = Date.now();
      const response = await axios.post(
        `${baseURL}/collaborators/manualcollab/save`,
        data,
        { headers: headers }
      );
      const timeElapsed = Date.now() - startTime;
      logger.success(`Collaborator save success in ${timeElapsed}ms`);
      posthog.identify(userData?.email);
      posthog.capture(postHogEvents.saveCollaborators);
      posthog?.capture(postHogEvents.saveCollaboratorsResponseTime, {
        responseTime: timeElapsed,
      });
      if (response.status === 200) {
        posthog.capture(`${postHogEvents.saveCollaborators}_SUCCESS`);
      }
      dispatch(setShowSaveCollabButton(false));
      setSavingCollaborator("");
      addActiveChannel(activeChannel);
      dispatch(addSavedCollabsData(activeChannel));
    } catch (error) {
      logger.error("Failed to save collaborator", error);
      console.error("Error saving collaborator:", error);
    }
  };

  const handleShowSavedCollaborators = async () => {
    logger.log("Fetching saved collaborators");
    setShowSavedCollaborators(true);
    setActiveTab("savedCollaborators");
    let start = Date.now();
    try {
      const sessionToken = getToken();
      if (!sessionToken) {
        console.error("Access token not found.");
        return;
      }

      const headers = {
        Authorization: `token ${sessionToken}`,
      };
      const response = await axios.get(
        `${baseURL}/collaborators/manualcollab/save`,
        {
          headers: headers,
        }
      );
      let end = Date.now();
      let time = end - start;
      logger.success(`Saved collaborators data fetched in ${time}ms`);

      const savedCollaboratorsData = response.data.data;

      if (savedCollaboratorsData.length === 0) {
        return;
      }

      dispatch(setApiSavedCollaborators(savedCollaboratorsData));
      dispatch(setSavedCollaborators(savedCollaboratorsData));

      if (savedCollabsData.length === 0) {
        dispatch(setChannelLoading(true));
        const channelId = savedCollaboratorsData[0]?.channel_id;
        const sessionToken = getToken();
        if (!sessionToken) {
          console.error("Access token not found.");
          return null;
        }

        const headers = {
          Authorization: `token ${sessionToken}`,
        };

        logger.log("Fetching active channel data");
        start = Date.now();
        const aUrl = `${baseURL}/collaborators/manualcollab?channel_id=${channelId}`;
        // TODO /collaborators/manualcollab?channel_id=${channelId}
        //analytics/manual/test
        const response = await axios.get(aUrl, {
          headers: headers,
        });
        end = Date.now();
        time = end - start;
        logger.success(`Fetched active channel data in ${time}ms`);

        if (response?.data?.data.length > 0) {
          updateActiveChannel(response?.data?.data[0]);
        } else {
          updateActiveChannel({
            channel_id: channelId,
            error: "No data found",
          });
        }
        dispatch(addSavedCollabsData(response?.data?.data[0]));
        dispatch(setChannelLoading(false));
      } else {
        updateActiveChannel(savedCollabsData[0]);
      }
    } catch (error) {
      logger.success(`Failed while loading saved collaborators data`, error);
      dispatch(setChannelLoading(false));
      console.error("Error fetching saved collaborators:", error);
    }
  };

  const handleShowSuggestions = () => {
    logger.log("Suggestions tab clicked in collaboration");
    setShowSavedCollaborators(false);
    setActiveTab("suggestions");
    if (collaborationData.length > 0) {
      updateActiveChannel(collaborationData[0]);
    }
  };

  const maxResults = 2;

  const handleSearch = async () => {
    logger.log(`Searching creators ${searchInput}`);
    try {
      setLoading(true);
      setIsDropdownVisible(true);
      dispatch(setPreviousActiveChannel(activeChannel));

      if (searchInput.trim().length < 3) {
        setLoading(false);
        setSearchResults([]);
        setIsDropdownVisible(false);
        return;
      }

      const maxResults = 10;
      const start = Date.now();
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${encodeURIComponent(
          searchInput
        )}&type=channel&maxResults=${maxResults}&key=${apiKey}`
      );
      const end = Date.now();
      const timeElapsed = end - start;
      logger.log(`Search creators done in ${timeElapsed}ms`, {
        status: response.status,
      });

      const data = await response.json();

      logger.log("Fetching channel stats");
      const channelIds = data.items.map((item) => item.id.channelId).join(",");
      const channelsUrl = `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${channelIds}&key=${apiKey}`;
      const _start = Date.now();
      const channelsResponse = await axios.get(channelsUrl);
      const _end = Date.now();
      const _time = _end - _start;
      logger.log(`Fetching channel stats done in ${_time}`, {
        status: response.status,
      });
      const channelsData = channelsResponse.data.items;

      const searchResultsWithInfo = [];
      for (let i = 0; i < data.items.length; i++) {
        const result = data.items[i];
        const channelId = result.id.channelId;
        const additionalInfo = {
          accountAge: calculateAccountAge(
            channelsData[i]?.snippet?.publishedAt
          ),
          subscriberCount: formatBigNumbers(
            channelsData[i]?.statistics?.subscriberCount
          ),
        };

        searchResultsWithInfo.push({
          ...result,
          additionalInfo,
        });
      }

      setLoading(false);
      setSearchResults(searchResultsWithInfo);
    } catch (error) {
      logger.error("Failure while exectuing creator search", error);
      console.error("Error fetching search results:", error);
      setLoading(false);
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (searchInput.trim() !== "") {
      handleSearch();
      posthog.identify(userData?.email);
      posthog.capture(postHogEvents.collabSearch);
    } else {
      setSearchResults([]);
    }
  }, [searchInput]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {showCollabSidebar && (
        <div className="bg-white border-solid border-[rgba(0,0,0,0.10)] border-r pt-6 px-3  flex flex-col gap-6 items-start h-auto justify-start w-full xl:w-[340px] relative mb-0">
          <h1 className="text-black text-left font-bold text-2xl leading-6">
            Find the best creators and collaborate with them!!!
          </h1>
          <div className="bg-[#f4f5fa] rounded-lg w-full flex flex-col gap-3 items-center justify-start relative">
            <div className="flex items-center w-full gap-5 px-5">
              <img
                src="https://api.iconify.design/material-symbols:search.svg"
                className="h-6 w-6"
                alt="search"
              />
              <input
                type="text"
                id="search"
                className="w-full bg-transparent border-none focus:outline-none h-14"
                placeholder="Search Creators"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onFocus={() => {
                  setIsDropdownVisible(true);
                  dispatch(setPreviousActiveChannel(activeChannel));
                }}
                onClick={(event) => event.stopPropagation()}
              />
            </div>
            {isDropdownVisible && searchResults.length > 0 && !loading && (
              <div
                className="absolute top-20 h-[400px] overflow-y-scroll flex flex-col w-full bg-slate-50 z-40 shadow-lg border border-black border-opacity-10 rounded-lg m-0"
                style={{
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  overflowY: "scroll",
                  scrollbarColor: "transparent transparent",
                  "&::WebkitScrollbar": {
                    width: "0.5em",
                  },
                  "&::WebkitScrollbarThumb": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <div ref={dropdownRef}>
                  {searchResults.map((result) => (
                    <div
                      key={result.id.channelId}
                      className="flex items-center justify-between  gap-3  hover:bg-slate-200 w-full px-4 py-2 cursor-pointer "
                      onClick={() => {
                        handleSelectSearchResult(result);
                        dispatch(setShowCollabSidebar(false));
                      }}
                    >
                      <img
                        src={result?.snippet?.thumbnails?.default.url}
                        alt=""
                        className="h-10 w-10 rounded-full"
                      />
                      <div className="w-[70%]">
                        <h3 className="text-sm font-bold">
                          {result?.snippet?.title}
                        </h3>

                        <p className="text-xs text-gray-600">
                          {result.additionalInfo?.subscriberCount} Subscribers
                        </p>
                        <p className="text-xs text-gray-600">
                          {result.additionalInfo?.accountAge &&
                            formatAccountAge(result.additionalInfo?.accountAge)}
                        </p>
                      </div>

                      <button
                        className={`h-7 border flex justify-center items-center ${
                          apiSavedCollaborators?.some(
                            (collaborator) =>
                              collaborator?.channel_id === result.id.channelId
                          ) |
                          (savingCollaborator === result?.id?.channelId)
                            ? "bg-blue-500 text-white cursor-not-allowed"
                            : "border-violet border-opacity-30"
                        } w-20 text-violet-600 text-xs font-semibold rounded-2xl`}
                        disabled={apiSavedCollaborators?.some(
                          (collaborator) =>
                            collaborator?.channel_id === result.id.channelId
                        )}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSaveCollaborator(result);
                        }}
                      >
                        {savingCollaborator === result?.id?.channelId ? (
                          <TailSpin
                            height="20"
                            width="20"
                            color="#FFFFFF"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        ) : apiSavedCollaborators?.some(
                            (collaborator) =>
                              collaborator?.channel_id === result.id.channelId
                          ) ? (
                          "Saved"
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          {loading && (
            <div className="flex items-center justify-center w-full">
              <TailSpin
                height="40"
                width="40"
                color="#3b82f6"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}

          <div
            className="flex max-w-full flex-col gap-5 items-start justify-start self-stretch shrink-0 relative h-auto overflow-y-scroll overflow-x-hidden"
            style={{
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              overflowY: "scroll",
              scrollbarColor: "transparent transparent",
              "&::WebkitScrollbar": {
                width: "0.5em",
              },
              "&::WebkitScrollbarThumb": {
                backgroundColor: "transparent",
              },
              maxHeight: "847px",
            }}
          >
            <div className="border-solid border-[rgba(0,0,0,0.20)] border-b flex flex-row gap-4 items-center lg:pr-6 justify-around w-full sticky top-0 bg-white z-10 xl:w-[340px]">
              <button
                className={`border-solid  pt-[19px] pb-[19px] flex flex-row gap-2.5 items-start justify-start shrink-0 relative  text-left font-semibold  ${
                  !showSavedCollaborators
                    ? "text-[#7948F6] border-[#7948F6] border-b-2"
                    : "text-[#5C566B]"
                }`}
                onClick={handleShowSuggestions}
              >
                Suggestions
              </button>

              <button
                className={`pt-[19px] pb-[19px] flex flex-row gap-2.5 items-start justify-start shrink-0 relative font-semibold  ${
                  showSavedCollaborators
                    ? "text-[#7948F6] border-[#7948F6] border-b-2"
                    : "text-[#5C566B]"
                }`}
                onClick={handleShowSavedCollaborators}
              >
                Saved Collaborators
              </button>
              <div className="mr-[20px]">
                <Tooltip
                  id="idea"
                  content="These channels target a similar audience, post alike content, and generate comparable engagement."
                />
              </div>
            </div>
            {activeTab === "suggestions" ? (
              <SuggestionsTab
                collaborationData={collaborationData}
                activeChannel={activeChannel}
                updateActiveChannel={updateActiveChannel}
              />
            ) : activeTab === "savedCollaborators" &&
              apiSavedCollaborators.length > 0 ? (
              <SavedCollaboratorsTab
                savedCollaborators={apiSavedCollaborators}
                updateActiveChannel={updateActiveChannel}
              />
            ) : (
              <div className="flex h-auto gap-7 py-10 flex-col justify-center items-center w-full">
                <img src={nocollaborators} alt="" className="" />
                <p className="text-sm text-gray-500 font-medium">
                  Your Saved Collaborators will be listed here
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CollabSidebar;
