import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { baseURL } from "../../utils/const";
import {
  suggestionKeywordIdeaSection,
  exploreKeyWordsLoading,
} from "../../redux/store/slice/suggestionSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePostHog } from "posthog-js/react";
import postHogEvents from "../../constants/events";
import logger from "../../config/logger";

const CreateContent = () => {
  const [keywordValue, setKeywordvalue] = useState("");
  const [generatedData, setGeneratedData] = useState([]);
  const suggestion_keyword_idea_data = useSelector(
    (state) => state.suggestion_data.suggestion_keyword_idea_section
  );
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("user-data"));
  const onChangeKewordValue = (event) => {
    setKeywordvalue(event.target.value);
  };

  const setRemainingCount = (val) => {
    const event = new CustomEvent("set-remaining-count", { detail: { val } });
    document.dispatchEvent(event);
  };

  const getKeyword = async () => {
    logger.log(`Fetching keyword data, keyword:${keywordValue}`);
    const Token = localStorage.getItem("token");
    const url = `${baseURL}/analytics/channel/llm?keyword=${keywordValue}`;
    dispatch(exploreKeyWordsLoading(true));

    const startTime = Date.now();
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `token ${Token}`,
        },
      });
      const timeElapsed = Date.now() - startTime;

      logger.success(
        `Fetched keyword data (${keywordValue}) successfully in ${timeElapsed}ms`
      );
      setRemainingCount(response?.data?.remaining_count || 0);
      setGeneratedData(response?.data?.finaloutput);
      dispatch(suggestionKeywordIdeaSection(response?.data?.finaloutput));
      dispatch(exploreKeyWordsLoading(false));

      posthog.identify(userData?.email);
      posthog.capture(postHogEvents.aiIdeasResponseTime, {
        responseTime: timeElapsed,
        page: "keyword-generation",
      });
      posthog.capture(`${postHogEvents.aiIdeasDisplay}_SUCCESS`);
    } catch (error) {
      const timeElapsed = Date.now() - startTime;
      logger.error(
        `Failed to Fetch keyword data (${keywordValue}) after ${timeElapsed}ms`,
        error
      );
      posthog.identify(userData?.email);
      posthog.capture(`${postHogEvents.aiIdeasDisplay}_FAILURE`);
      setRemainingCount(error?.response?.data?.remaining_count || 0);
      dispatch(exploreKeyWordsLoading(false));
      toast.error(
        error?.response?.data?.message ||
          "Server Error, Please Try Again After Some Time",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const logEvent = (value) => {
    posthog.identify(userData?.email);
    posthog.capture(postHogEvents.keywordContentGenerationClicked, {
      keyword: value,
    });
  };

  const handleSubmit = () => {
    if (keywordValue !== "") {
      logEvent(keywordValue);
      getKeyword();
      const data = {
        keyword: keywordValue,
        genData: generatedData,
        loading: true,
      };
      logger.log("Navigating to /suggestions/generate page");
      navigate("/suggestions/generate", { state: data });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const opacity = keywordValue === "" ? "opacity-75" : "opacity-100";
  return (
    <>
      <div className=" bg-blue-800 sm:w-[48%] h-[260px] p-5 pt-8 rounded-2xl min-w-[300px] flex flex-col justify-around xl:h-[200px] 2xl:h-[220px] ">
        <div className="w-[100%] h-[60px]">
          <p className=" text-white text-[22px] font-bold leading-normal mb-2 ">
            Create Content ideas from Keywords!!
          </p>
        </div>
        <p className="w-[90%] text-white text-opacity-70 text-xs font-normal leading-[18px] mt-2">
          Enter relevant keywords that you want to do a video on and we will use
          AI to create interesting content ideas for your channel
        </p>
        <div className="mt-2">
          <input
            type="text"
            value={keywordValue}
            onChange={onChangeKewordValue}
            onKeyDown={handleKeyDown}
            placeholder="Enter Keyword"
            className=" w-[60%] h-10 pl-5 pr-2 py-3 placeholder-white text-white text-xs font-medium bg-white bg-opacity-10 rounded-[27px] backdrop-blur-[50px] justify-start items-center gap-3 inline-flex outline-none border-none mr-2"
          />
          <div
            onClick={() => handleSubmit()}
            className={`text-black ${opacity} text-blue-800   w-[35%] max-w-[115px] h-10 px-4 py-2 bg-white rounded-[20px] justify-center items-center gap-2 inline-flex sm:w-[35%] cursor-pointer`}
          >
            <p className="text-[13px] font-semibold leading-none">Generate</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M3.33398 8H12.6673"
                stroke="#22359E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 3.33301L12.6667 7.99967L8 12.6663"
                stroke="#22359E"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default CreateContent;
