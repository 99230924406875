import { createSlice } from "@reduxjs/toolkit";

const collaborationData = createSlice({
  name: "collaboration",
  initialState: {
    collabData: [],
    savedCollaborators: [],
    savedCollabsData: [],
    showSaveCollabButton: false,
    channelLoading: false,
    error: null,
    activeChannel: "",
    showCollabSidebar: false,
    similarityScores: [],
    apiSavedCollaborators: [],
    firstTimeLoad: true,
    requestedCollaborators: [],
    previousActiveChannel: "",
  },

  reducers: {
    addCollaboratorData(state, action) {
      state.collabData = action.payload;
      state.activeChannel = action.payload[0];
      //   state.push(action.payload);
    },
    addActiveChannel(state, action) {
      state.activeChannel = action.payload;
    },
    setSavedCollaborators: (state, action) => {
      state.savedCollaborators = action.payload;
    },
    addSavedCollaborator: (state, action) => {
      state.savedCollaborators.push(action.payload);
    },
    removeSavedCollaborator: (state, action) => {
      state.savedCollaborators = state.savedCollaborators.filter(
        (collaborator) => collaborator.id.channelId !== action.payload
      );
    },
    setChannelLoading: (state, action) => {
      state.channelLoading = action.payload;
    },
    setShowSaveCollabButton: (state, action) => {
      state.showSaveCollabButton = action.payload;
    },
    addSimilarityScores: (state, action) => {
      state.similarityScores.push(action.payload);
    },
    setShowCollabSidebar: (state, action) => {
      state.showCollabSidebar = action.payload;
    },
    addSavedCollabsData: (state, action) => {
      state.savedCollabsData.push(action.payload);
    },
    setApiSavedCollaborators: (state, action) => {
      state.apiSavedCollaborators = action.payload;
    },
    addApiSavedCollaborators: (state, action) => {
      state.apiSavedCollaborators.push(action.payload);
    },
    setFirstTimeLoad: (state, action) => {
      state.firstTimeLoad = action.payload;
    },
    updateCollaborators: (state, action) => {
      state.requestedCollaborators = action.payload;
    },
    setPreviousActiveChannel: (state, action) => {
      state.previousActiveChannel = action.payload;
    },
    setRequestedCollaborators: (state, action) => {
      state.requestedCollaborators = action.payload;
    },
  },
});

export const {
  addCollaboratorData,
  addActiveChannel,
  addSavedCollaborator,
  setSavedCollaborators,
  removeSavedCollaborator,
  setChannelLoading,
  setShowSaveCollabButton,
  addSimilarityScores,
  addSavedCollabsData,
  setShowCollabSidebar,
  setApiSavedCollaborators,
  setFirstTimeLoad,
  addApiSavedCollaborators,
  updateCollaborators,
  setPreviousActiveChannel,
  setRequestedCollaborators,
} = collaborationData.actions;

export default collaborationData.reducer;
