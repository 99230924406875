// apiCollaborationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../../../utils/const";

// Replace 'your-api-endpoint' with your actual API endpoint
const apiEndpoint = baseURL;
const authToken = localStorage.getItem("token");

export const fetchApiKeywordData = createAsyncThunk(
  "/analytics/channel/keywords",
  async (token) => {
    const config = {
      headers: {
        Authorization: `token ${token}`,
      },
    };
    const response = await axios.get(
      apiEndpoint + "/analytics/channel/keywords",
      config
    );
    return response?.data?.data;
  }
);

const apiSuggestionKeyWordSlice = createSlice({
  name: "api",
  initialState: {
    keywordData: null,
    loadingKeyword: "idle",
    errorKeyword: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiKeywordData.pending, (state) => {
        state.loadingKeyword = "pending";
      })
      .addCase(fetchApiKeywordData.fulfilled, (state, action) => {
        state.loadingKeyword = "succeeded";
        state.keywordData = action.payload;
      })
      .addCase(fetchApiKeywordData.rejected, (state, action) => {
        state.loadingKeyword = "failed";
        state.errorKeyword = action.error.message;
      });
  },
});

export default apiSuggestionKeyWordSlice.reducer;
