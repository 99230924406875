import { createSlice } from "@reduxjs/toolkit";

const Onboarding = createSlice({
  name: "onboarding",
  initialState: {
    onboard_state: "hear_about",
    onboard_active: 0,
    onboard_data: [],
    // onboard_hear_about_us_referral: "",
    onboard_hear_about_us: 0,
    onboard_interested_others_data: "",
    onboard_referral_data: "",
    onboard_hear_about_data: "",
    onboard_out_source_data: [],
    onboard_collab_status: "False",
    onboard_interested_data: [],
    loading: false,
    error: null,
  },

  reducers: {
    onBoardState(state, action) {
      state.onboard_state = action.payload;
      state.onboard_data.push(action.payload);
    },
    hearAboutUs(state, action) {
      if (state.onboard_referral_data) {
        state.onboard_referral_data = "";
      }
      state.onboard_hear_about_us = action.payload;
    },

    addOnBoardInterestedData(state, action) {
      if (state.onboard_interested_data.includes(action.payload)) {
        state.onboard_interested_data = state.onboard_interested_data.filter(
          (item) => item !== action.payload
        );
      } else {
        state.onboard_interested_data.push(action.payload);
      }
    },

    addInterestedOtherData(state, action) {
      if (action.payload === "") {
        state.onboard_interested_data = state.onboard_interested_data.filter(
          (item) => item !== 5
        );
      }
      // state.onboard_interested_data = [];
      state.onboard_interested_others_data = action.payload;
    },
    addReferralData(state, action) {
      state.onboard_referral_data = action.payload;
    },
    addOutSourceData(state, action) {
      if (state.onboard_out_source_data.includes(action.payload)) {
        state.onboard_out_source_data = state.onboard_out_source_data.filter(
          (item) => item !== action.payload
        );
      } else {
        state.onboard_out_source_data.push(action.payload);
      }
    },
    addCollabStatusData(state, action) {
      state.onboard_collab_status = action.payload;
    },
  },
});
export const {
  onBoardState,
  addOnBoardInterestedData,
  addInterestedOtherData,
  addReferralData,
  hearAboutUs,
  addOutSourceData,
  addCollabStatusData,
} = Onboarding.actions;

export default Onboarding.reducer;
