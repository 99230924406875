import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { SearchedDataContext } from "./index";
import axios from "axios";
import {
  suggestionKeywordIdeaSection,
  exploreKeyWordsLoading,
} from "../../../redux/store/slice/suggestionSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import { jwtToken } from "../../../utils/const";
import { baseURL } from "../../../utils/const";
import { AiOutlineArrowRight } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiChevronLeft } from "react-icons/fi";
import { usePostHog } from "posthog-js/react";
import postHogEvents from "../../../constants/events";
import logger from "../../../config/logger";

const SearchKeyword = ({
  setSavedIdea,
  keyword,
  setRemainingCount,
  setOnClickGenerateFn,
}) => {
  const { searchKeyword, setSearchKeyword, setGeneratedData } =
    useContext(SearchedDataContext);
  const suggestion_keyword_idea_data = useSelector(
    (state) => state.suggestion_data.suggestion_keyword_idea_section
  );
  const userData = JSON.parse(localStorage.getItem("user-data"));
  const dispatch = useDispatch();
  const posthog = usePostHog();

  const { keywordData } = useSelector((state) => state.apiSuggestionKeyWord);

  const onChangeInputValue = (event) => {
    setSearchKeyword(event.target.value);
  };

  const onClickGenerateData = async () => {
    logger.log(`Generating content based on keyword: ${searchKeyword}`);
    const url = `${baseURL}/analytics/channel/llm?keyword=${searchKeyword}`;
    const Token = localStorage.getItem("token");
    dispatch(exploreKeyWordsLoading(true));

    const startTime = Date.now();
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `token ${Token}`,
        },
      });
      const timeElapsed = Date.now() - startTime;
      logger.success(
        `Successfully generated content based on keyword in ${timeElapsed}ms`
      );
      posthog.identify(userData?.email);
      posthog.capture(postHogEvents.aiIdeasResponseTime, {
        responseTime: timeElapsed,
        page: "keyword-generation",
      });
      posthog.capture(`${postHogEvents.aiIdeasDisplay}_SUCCESS`);
      setGeneratedData(response?.data?.finaloutput);
      dispatch(suggestionKeywordIdeaSection(response?.data?.finaloutput));
      dispatch(exploreKeyWordsLoading(false));
      setRemainingCount(response?.data?.remaining_count || 0);
    } catch (error) {
      const timeElapsed = Date.now() - startTime;
      logger.error(
        `Failed to generate content based on keyword after ${timeElapsed}ms`,
        error
      );
      posthog.identify(userData?.email);
      posthog.capture(`${postHogEvents.aiIdeasDisplay}_FAILURE`);
      setRemainingCount(error?.response?.data?.remaining_count || 0);
      dispatch(exploreKeyWordsLoading(false));
      toast.error(
        error?.response?.data?.message ||
          "Server Error, Please Try Again After Some Time",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  useEffect(() => {
    document.addEventListener("call-onClickGenerateData", onClickGenerateData);

    return () =>
      document.removeEventListener(
        "call-onClickGenerateData",
        onClickGenerateData
      );
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onClickGenerateData();
    }
  };

  return (
    <>
      <div className="max-sm:px-3 max-sm:gap-1 px-8 pt-6 pb-10 bg-gradient-to-br from-indigo-900 to-blue-950  items-start gap-2 flex flex-col">
        <div className="w-[100%] flex items-center justify-between">
          <Link to={"/suggestions"}>
            <div className="w-6 h-6 relative mr-3 mt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 12H5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 19L5 12L12 5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </Link>
          <div className="flex flex-row justify-between w-[100%] items-center mt-3 max-sm:mt-0">
            <div className=" text-white text-2xl font-bold leading-loose max-sm:text-sm max-sm:w-80">
              Create Content ideas from Keywords!!
            </div>

            <div className="mr-5 mt-3 h-7 justify-start items-center gap-3 inline-flex">
              <div
                className="pl-[5px] pr-[7px] py-1.5 bg-white bg-opacity-20 rounded-[38px] justify-start items-start gap-2.5 flex"
                onClick={() => setSavedIdea(true)}
              >
                <FiChevronLeft className="text-white" />
              </div>
              <div className="text-white text-sm font-medium">Saved Ideas</div>
            </div>
          </div>
        </div>

        <div className="w-[100%] max-sm:w-[100%] flex-col justify-start  gap-3 flex">
          <div className="justify-start items-start gap-4 flex max-sm:flex-col ">
            <input
              value={searchKeyword}
              onChange={onChangeInputValue}
              onKeyDown={handleKeyDown}
              placeholder="Enter Keyword"
              className="w-[50%] min-w-[280px] pl-8 pr-5 py-5 text-white text-sm font-medium leading-none bg-white bg-opacity-10 rounded-[28px] backdrop-blur-[50px] justify-start items-center gap-3 inline-flex outline-none border-none"
            />
            <div
              className=" min-w-[150px] px-8 py-5 bg-white rounded-[28px] justify-center items-center gap-2 flex cursor-pointer w-[13%]"
              onClick={onClickGenerateData}
            >
              <div className="text-blue-800 text-[13px] font-semibold leading-none">
                Generate
              </div>
              <AiOutlineArrowRight className="text-blue-800" />
            </div>
          </div>
          <ul className="justify-start items-start gap-2 flex flex-wrap mt-3">
            {keywordData?.map((each, idx) => (
              <div
                key={idx}
                className="px-4 py-0.5 bg-white bg-opacity-10 rounded-3xl justify-center items-center gap-2.5 flex cursor-pointer"
                onClick={() => setSearchKeyword(each.keyword)}
              >
                <div className="text-white text-xs font-medium leading-snug p-1">
                  {each.keyword}
                </div>
              </div>
            ))}
          </ul>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SearchKeyword;
