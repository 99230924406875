import { formatDateString } from "./trendingVideo";
import { formatBigNumbers } from "../../utils/utility-functions";
import "./trending.scss";

const TrendingShorts = ({ short }) => {
  if (short.type === "short") {
    return (
      <a id="shorts" href={short.url} target="_blank" rel="noreferrer">
        <img
          alt="shorts-img"
          src={short.thumbnail_url}
          className="shorts__image"
        />
        <div className="shorts-main">
          <div className="shorts__title">{short.title}</div>
          <div className="shorts__details">
            {short.total_views ? (
              <div>{formatBigNumbers(short.total_views)} views</div>
            ) : (
              ""
            )}
            {short.published_at ? (
              <div>{formatDateString(short.published_at)}</div>
            ) : (
              ""
            )}
          </div>
        </div>
      </a>
    );
  } else {
    return null;
  }
};

export default TrendingShorts;
