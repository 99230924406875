import UpviewMissLogo from "../../../assets/images/upviewmiss.png.png";
import MissyouImage from "../../../assets/images/24.png";

const WillMissYou = () => {
  return (
    <div className="flex flex-col items-center gap-5 p-10">
      <div className="self-start mb-10">
        <img src={UpviewMissLogo} alt="upviewmisslogo" />
      </div>
      <img src={MissyouImage} alt="MissyouImage" />
      <h1 className="text-zinc-800 text-[40px] text-center font-bold">
        We will miss you :(
      </h1>
      <p className="text-center text-gray-600 text-base font-normal">
        You can come back to us anytime though{" "}
      </p>
      <a
        href="/signup"
        className="text-violet-600 text-base font-semibold leading-snug"
      >
        Go to Home
      </a>
    </div>
  );
};

export default WillMissYou;
