// apiCollaborationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL } from "../../../../utils/const";

// Replace 'your-api-endpoint' with your actual API endpoint
const apiEndpoint = baseURL;
const authToken = localStorage.getItem("token");

export const fetchApiOtherData28day = createAsyncThunk(
  "/analytics/channel/video/related?timeframe=7",
  async (data) => {
    const config = {
      headers: {
        Authorization: `token ${data[0]}`,
      },
    };
    const response = await axios.get(
      apiEndpoint + `/analytics/channel/video/related?timeframe=${28}`,
      config
    );
    return response?.data?.data;
  }
);

const apiSuggestionOtherSlice28day = createSlice({
  name: "api",
  initialState: {
    loadingOther28day: "idle",
    errorOther28day: null,
    otherData28day: null,
  },
  reducers: {
    // daysApiStatusChange(state, action) {
    //   if (action.payload === 7) {
    //     state.othersevenDaysApiCall = true;
    //   }
    //   if (action.payload === 28) {
    //     state.twentyEightDaysApiCall = true;
    //   }
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiOtherData28day.pending, (state) => {
        state.loadingOther28day = "pending";
      })
      .addCase(fetchApiOtherData28day.fulfilled, (state, action) => {
        state.loadingOther28day = "succeeded";
        state.otherData28day = action.payload;
      })
      .addCase(fetchApiOtherData28day.rejected, (state, action) => {
        state.loadingOther28day = "failed";
        state.errorOther28day = action.error.message;
      });
  },
});

export default apiSuggestionOtherSlice28day.reducer;
