import { BsArrowLeftShort } from "react-icons/bs";
import AlertCircle from "../../../assets/images/alert-circle.png";
import { RxCross2 } from "react-icons/rx";

const DeleteAccount = () => {
  return (
    <div className="max-w-[770px] h-[80%] p-10 rounded-2xl flex-col justify-start items-center">
      {/* <div className="flex justify-between items-center mb-[77px]">
            <h1 className="text-black text-2xl font-semibold">Settings</h1>
            <RxCross2/>
            </div> */}
      <div className="flex flex-col items-center gap-9">
        <div className="flex items-center gap-2 self-start mb-[40px]">
          <BsArrowLeftShort size="25px" />
          <p className="text-zinc-800 text-sm font-semibold leading-normal">
            Delete Account
          </p>
        </div>
        <h1 className="text-black text-2xl font-semibold">Are you sure?</h1>
        <div className=" pl-2 pr-4 py-2 bg-red-700 bg-opacity-10 rounded-[999px] justify-start items-center gap-2 inline-flex">
          <img alt="alert-circle" src={AlertCircle} />
          <div className="text-red-700 text-sm font-normal">
            You will not be able to reverse this action.
          </div>
        </div>
        <p className="max-w-[400px] text-center text-black text-sm font-normal leading-snug">
          If you delete your account, you'll lose all the badges and
          achievements you earned on Upview.
        </p>
        <div className="flex items-center gap-6 sm:gap-10">
          <div className="w-[130px] h-11 px-6 py-3 bg-violet-600 rounded-[999px] justify-center items-center gap-2.5 flex sm:w-[210px]">
            <button className="text-white text-[13px] font-bold leading-tight">
              No
            </button>
          </div>
          <div className="w-[130px] h-11 px-6 py-3 mr-3 rounded-[999px] border border-red-700 justify-center items-center gap-2.5 flex sm:w-[210px]">
            <button className="text-red-700 text-[13px] font-bold leading-tight">
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
